import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { LineSeperator } from '../style';

const tenants = [
    { name: "Miss. SHOBHITAS ASDSD", id: "PAMU6BWUT", email: "robby1234@yopmail.com", postcode: "PL23 1AB", contact: "09759175743", isLead: false },
    { name: "Mr. HITESH GUNWANT", id: "BXYMEM8TM", email: "gunwant.hitesh100@gmail.com", postcode: "263139", contact: "233434343434", isLead: true }
];

const TenantCard = ({ name, id, email, postcode, contact, isLead }) => {
    return (
        <View style={styles.card}>
            <View style={styles.circle}><Text style={styles.initials}>{name[0]}</Text></View>
            <View style={styles.cardContent}>
                <View style={{ flexDirection: 'row', justifyContent: "space-between",marginBottom:20 }}>
                    <View style={{ flex: 0.4 }}>
                        <Text style={styles.name}>{name}</Text>
                    </View>
                    <View style={{ flex: 0.6,flexDirection:'row',justifyContent:'flex-end',alignItems:"center" }}>
                        {isLead && <View style={styles.leadBadge}><Text style={styles.leadText}>MAKE LEAD</Text></View>}
                        <MaterialIcons name="delete" size={24} color="red" />
                        <MaterialIcons name="edit" size={24} color="black" />
                    </View>
                </View>
                <Text style={styles.info}>TLYFE ID: {id}</Text>
                <Text style={styles.info}>Email: {email}</Text>
                <Text style={styles.info}>Postcode: {postcode}</Text>
                <Text style={styles.info}>Contact: {contact}</Text>
            </View>

        </View>
    );
};

const TenantListScreen = () => {
    return (
        <ScrollView style={styles.container}>
            <Text style={styles.heading}>Your applicant tenants</Text>
            <Text style={styles.subHeading}>Begin the tenant onboarding process by "pairing" your Applicant Tenant(s) to your property.</Text>
            <Text style={styles.subHeading}>
            tlyfePro with tlyfe and it is recommended that you request that the Applicant Tenant(s) registers with tlyfe before you start this process in order to maximise process efficiency.
            </Text>
            <View style={styles.infoBox}><Text>Ask the applicants to check if all the details are entered correctly via the tlyfe app. They are automatically sent a registration email if you include their email address in the tenant section.</Text></View>
            <View style={styles.warningBox}><Text>PLEASE NOTE: If you incorrectly enter the tenants email, it is not possible to edit this due to security reasons. You must delete the tenant and enter the correct email. If you wish to change a tenants email, please contact support@openbrix.co.uk.</Text></View>
            <TouchableOpacity style={styles.addButton}><Text style={styles.addButtonText}>+ ADD TENANT</Text></TouchableOpacity>

            <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
                {tenants.map((tenant, index) => (
                    <TenantCard key={index} {...tenant} />
                ))}

            </View>
            <TouchableOpacity style={styles.sendButton}><Text style={styles.sendButtonText}>SEND</Text></TouchableOpacity>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: { padding: 16, backgroundColor: '#f8f9fc' },
    heading: { fontSize: 20, fontWeight: 'bold', color: '#6a4bb3', marginBottom: 10 },
    subHeading: { fontSize: 14, color: '#666', marginBottom: 10 },
    infoBox: { padding: 10, backgroundColor: '#e0f4ff', borderRadius: 8, marginBottom: 10 },
    warningBox: { padding: 10, backgroundColor: '#ffe6e6', borderRadius: 8, marginBottom: 20 },
    card: {
        backgroundColor: 'white', padding: 16, borderRadius: 10, flexDirection: 'row', alignItems: 'center', marginBottom: 10, shadowColor: '#000', shadowOpacity: 0.1, shadowRadius: 5, elevation: 3,
        maxWidth: 340,
        marginRight: 10,
        marginBottom: 10
    },
    circle: { width: 40, height: 40, borderRadius: 20, backgroundColor: '#d3c4f4', alignItems: 'center', justifyContent: 'center', marginRight: 10 },
    initials: { fontSize: 18, fontWeight: 'bold', color: '#6a4bb3' },
    cardContent: { flex: 1 },
    name: { fontSize: 16, fontWeight: 'bold' },
    info: { fontSize: 12, color: '#555' },
    leadBadge: { backgroundColor: '#6a4bb3', paddingHorizontal: 8, paddingVertical: 4, borderRadius: 5 },
    leadText: { color: 'white', fontSize: 10, fontWeight: 'bold' },
    editButton: { right: 40 },
    editText: { fontSize: 18, color: '#6a4bb3' },
    deleteButton: { right: 10 },
    deleteText: { fontSize: 18, color: 'red' },
    addButton: { backgroundColor: '#e91e63', padding: 10, borderRadius: 8, alignItems: 'center', marginVertical: 20,alignSelf:'flex-end' },
    addButtonText: { color: 'white', fontSize: 14, fontWeight: 'bold' },
    sendButton: { backgroundColor: '#009688', padding: 10, borderRadius: 8, alignItems: 'center',width:150,alignSelf:'flex-end'},
    sendButtonText: { color: 'white', fontSize: 14, fontWeight: 'bold' }
});

export default TenantListScreen;
