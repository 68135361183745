import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { useIsFocused, useRoute } from '@react-navigation/native';

import Box from '../../components/box';
import Urls from '../../services/Urls';
import Knob from '../../components/knob';
import Text from '../../components/text';
import ajax from '../../helpers/ajaxHelper';
import Colors from '../../constants/Colors';
import ListCard from '../../components/listCard';
import Button from '../../components/button/Button';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import {
  Row,
  Right,
  Left,
  Center,
  BoxContainer,
  ImageContainer,
  CircleIcon,
  InfoSection,
  RemainderSection,
  ArchiveWrapper,
  TenanaceyWrapper,
  TenancyDetails,
  ContractDetails,
} from './index.style';
import PlaceHolder from '../../components/placeholder';
import { FlatList, TouchableOpacity, View } from 'react-native';
import CalenderComponent from '../../components/calendar';
import { navigate } from '../../navigation/RootNavigation';
import moment from 'moment';
const _ = require('lodash');
import getPlatformType from '../../helpers/helpers';
import CalendarList from '../../components/calendarList';
import { LineSaperator } from '../../components/calendarList/CalendarList.style';
import { SectionLine } from '../../components/pageHeader/pageHeader.style';
import PageHeader from '../../components/pageHeader';
var layoutType = getPlatformType();

const TenantInfo = ({ navigation }) => {
  const route = useRoute();
  const focused = useIsFocused();
  const [fEventsData, setFEventsData] = useState({});
  const [eventsData, setEventsData] = useState([]);

  useEffect(() => {
    if (route?.params?.id) {
      _getDetails(route?.params?.id);
      _getCalEvents(new Date().getMonth() + 1, new Date().getFullYear());
    }
  }, [focused]);
  const [state, setState] = useState({
    postcode: '',
    houseName: '',
    address1: '',
    city: '',
    country: '',
    property_name: '',
    property_worth: '',
    mortgage_provider: '',
    id: '',
    hubita_id: '',
  });
  const _getDetails = (id) => {
    ajax
      .get(Urls.Properties + '/' + id)
      .then(({ data }) => {
        if (data.status) {
          setState({
            ...state,
            ...{
              address1: data?.payload?.address_line1,
              postcode: data?.payload?.postcode,
              property_name: data?.payload?.name,
              city: data?.payload?.city,
              houseName: data?.payload?.house_name_num,
              property_worth: data?.payload?.price_worth,
              mortgage_provider: data?.payload?.mortgage_provider,
              hubita_id: data?.payload?.hubita_id,
              id: data?.payload?.id,
              country: data?.payload?.country,
              location_details_status: data?.payload?.location_details_status,
              room_details_status: data?.payload?.room_details_status,
              certificate_details_status: data?.payload?.certificate_details_status,
              property_requirements_status: data?.payload?.property_requirements_status,
              license_details_status: data?.payload?.license_details_status,
              percentage: data?.payload?.percentage,
              certificates: data?.payload?.certificates,
              licenses: data?.payload?.licenses,
              payments: data?.payload?.payments,
            },
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const arrToObj = (arr, key) => {
    return arr.reduce((obj, item) => {
      obj[moment(item[key]).format('YYYY-MM-DD')] = {
        customStyles: {
          container: {
            backgroundColor: '#21B1C4',
            elevation: 4,
          },
          text: {
            color: 'white',
          },
        },
      };
      return obj;
    }, {});
  };
  const _getCalEvents = (month, year) => {
    ajax
      .get(Urls.GET_REMAINDERS_MONTHLY + month + '/' + year + '?propertyId=' + route?.params?.id)
      .then(({ data }) => {
        if (data.status) {
          console.log('data', data);
          if (data?.payload && data.payload.length) {
            setFEventsData(arrToObj(data?.payload, 'date'));
            setEventsData(data?.payload);
          } else {
            setFEventsData([]);
            setEventsData([]);
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const GetBeuatyName = (name) => {
    if (name && name.length > 0) {
      let splitname = name.split('_');
      console.log('splitname', splitname[0]);
      return splitname[0];
    }
  };
  const _listItem = (type, data, labels) => {
    return (
      <>
        {layoutType != 'phone' ? (
          <Row style={{ marginBottom: 10, width: '82%', marginTop: 10 }}>
            <Text fontSize={16} fontWeight={'600'}>
              {type}
            </Text>
            <Text fontSize={16} fontWeight={'600'}>
              Expiry Date
            </Text>
          </Row>
        ) : null}

        {labels.map((e) => (
          <FlatList
            data={data[e]}
            renderItem={({ item }) => (
              <Row
                style={{
                  paddingHorizontal: 5,
                  paddingVertical: 15,
                  borderColor: '#cdcdcd',
                  borderBottomWidth: 1,
                  // alignItems: 'center',
                  // flexWrap: 'wrap',
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    item?.document_url ? window.open(item?.document_url) : null;
                  }}
                  style={{ width: '50%' }}
                >
                  <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                    <MaterialCommunityIcons name={'file-certificate'} color={Colors.light.lightPurple} size={16} />
                    <Text style={{ textTransform: 'capitalize' }} fontSize={16} marginLeft={10}>
                      {GetBeuatyName(item?.name)}
                    </Text>
                  </Row>
                </TouchableOpacity>
                {layoutType == 'phone' ? (
                  <View style={{ width: '100%', flexDirection: 'row', marginTop: 10, justifyContent: 'space-between' }}>
                    <Text fontSize={16}>{moment(item?.expiry_date).format('DD/MM/YYYY')}</Text>
                    <MaterialCommunityIcons
                      onPress={() => window.open(item?.document_url)}
                      name={'download'}
                      color={Colors.light.lightPurple}
                      size={20}
                    />
                  </View>
                ) : (
                  <>
                    <Text fontSize={16}>{moment(item?.expiry_date).format('DD/MM/YYYY')}</Text>
                    {item?.document_url ? (
                      <MaterialCommunityIcons
                        onPress={() => window.open(item?.document_url)}
                        name={'download'}
                        color={Colors.light.lightPurple}
                        size={20}
                      />
                    ) : (
                      <View />
                    )}
                  </>
                )}
              </Row>
            )}
          />
        ))}
      </>
    );
  };
  const tenancyView = (data) => {
    return (
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <View style={{}}>
          <Text fontSize={16} bold={'800'}>
            Rental Amount{' '}
          </Text>
          <Text fontSize={14} marginTop={8}>
            20,000.00
          </Text>
          <Text fontSize={16} marginTop={20} bold={'800'}>
            Payments
          </Text>
          <Text fontSize={14} marginTop={8}>
            20,000.00
          </Text>
        </View>
        <View style={{ width: 1, height: '90%', backgroundColor: '#cdcdcd' }} />
        <View>
          <Text fontSize={16} bold={'800'}>
            Start of tenancy
          </Text>
          <Text fontSize={14} marginTop={8}>
            01/05/2020
          </Text>

          <Text fontSize={16} marginTop={20} bold={'800'}>
            End of tenancy
          </Text>
          <Text fontSize={14} marginTop={8}>
            01/05/2020
          </Text>
        </View>
      </View>
      // <TouchableOpacity style={{ flexDirection: 'row' }}>
      //   <View style={{ flex: 0.5 }}>
      //     <Text fontSize={18} bold={'800'}>
      //       Rental Amount{' '}
      //     </Text>
      //     <Text fontSize={14} marginTop={8}>
      //       20,000.00
      //     </Text>

      // <Text fontSize={18} marginTop={20} bold={'800'}>
      //   Payments
      // </Text>
      // <Text fontSize={14} marginTop={8}>
      //   20,000.00
      // </Text>
      //   </View>
      //   <SectionLine />
      //   <View style={{ flex: 0.5, paddingLeft: 30 }}>
      // <Text fontSize={18} bold={'800'}>
      //   Start of tenancy
      // </Text>
      // <Text fontSize={14} marginTop={8}>
      //   01/05/2020
      // </Text>

      // <Text fontSize={18} marginTop={20} bold={'800'}>
      //   End of tenancy
      // </Text>
      // <Text fontSize={14} marginTop={8}>
      //   01/05/2020
      // </Text>
      //   </View>
      // </TouchableOpacity>
    );
  };
  return (
    <View style={{ flex: 1 }}>
      <PageHeader
        property_name={state?.property_name}
        property_worth={state?.property_worth}
        id={state?.id}
        percentage={state?.percentage}
        address1={state?.houseName}
        city={state?.city}
        postcode={state?.postcode}
        isTenant
        contact_no={39343889494}
        email={'root@yopmail.com'}
        houseName={'Acorn House'}
        onContinuePress={() => navigate('TeanantOnboarding',{id:"1828"})}
      />
      <View
        style={{
          flexDirection: layoutType == 'phone' ? 'column-reverse' : 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <InfoSection>
          <TenanaceyWrapper>
            <TenancyDetails>
              <PlaceHolder
                header={'Tenancy Details'}
                style={{ marginRight: 10 }}
                renderChild={tenancyView()}
                onPress={() => {}}
              />
            </TenancyDetails>
            <ContractDetails>
              <PlaceHolder
                isrow
                onRightTextPress={() => {
                  navigation.navigate('Payments');
                }}
                title={'Mandatory Requirements'}
                icon={''}
                buttonText={'Complete'}
                onPress={() => navigation.navigate('PaymentOutgoingDetails')}
              />
            </ContractDetails>
          </TenanaceyWrapper>

          <PlaceHolder
            onRightTextPress={() => {
              navigation.navigate('Certification', {
                status: state?.certificate_details_status,
                id: route?.params?.id,
                name: state?.property_name,
                key: 'certificate_details_status',
              });
            }}
            header={'Tenant Documents'}
            title={'Keep a record of the tenants documents?'}
            renderChild={null}
            onPress={() =>
              navigation.navigate('Certification', {
                status: state?.certificate_details_status,
                id: route?.params?.id,
                name: state?.property_name,
                key: 'certificate_details_status',
              })
            }
            description={`Upload any tenant documentation e.g. Right to Rent or references to keep a helpful digital record you can access at anytime`}
            buttonText={'Add Documnets'}
          />
          <PlaceHolder
            header={'Certificate Check list'}
            title={'Mandatory Certificates'}
            onRightTextPress={() => {
              navigation.navigate('Licensing', {
                status: state?.license_details_status,
                id: route?.params?.id,
                name: state?.property_name,
                key: 'license_details_status',
              });
            }}
            renderChild={null}
            onPress={() =>
              navigation.navigate('Licensing', {
                status: state?.license_details_status,
                id: route?.params?.id,
                name: state?.property_name,
                key: 'license_details_status',
              })
            }
            description={`Have you uploaded all the mandatory certificates for this tenancy?`}
            buttonText={'Complete'}
          />
          <PlaceHolder
            header={'Requirement checklist'}
            onRightTextPress={() => {
              navigation.navigate('Payments');
            }}
            title={'Mandatory Requirements'}
            icon={''}
            description={`Have you uploaded all the mandatory certificates for this tenancy?`}
            buttonText={'Complete'}
            onPress={() => navigation.navigate('PaymentOutgoingDetails')}
          />
          <ArchiveWrapper>
            <Text style={{ marginRight: 10 }}>No longer work with this tenant?</Text>
            <Button
              onPress={() => navigate('PropertySection', { id: id })}
              childText={'Archive tenant'}
              type="stripe"
              width={100}
            />
          </ArchiveWrapper>
        </InfoSection>
        <RemainderSection>
          <Text fontWeight={600} marginTop={20} fontSize={18}>
            Remainder
          </Text>
          <Box style={{ width: '100%', marginTop: 20 }}>
            <CalenderComponent markers={[]} onMonthChange={(data) => _getCalEvents(data.month, data.year)} />
            <CalendarList eventList={[]} />
          </Box>
        </RemainderSection>
      </View>
    </View>
  );
};

export default drawerHoc({
  Component: TenantInfo,
  showBackButton: true,
});
