import { Modal } from '@material-ui/core';
import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';

const { width } = Dimensions.get('window');

const DeletePopUp = (props) => {
    const {
        onClose,
        showModal,
        data,
        onSubmit
      } = props;
      console.log("datadata",data)
  return (
    <Modal
      open={showModal ? showModal : false}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
    <View style={styles.container}>
      <View style={styles.popup}>
        <Text style={styles.title}>Are you sure?</Text>
        <Text style={styles.subtitle}>
        Are you sure, You want to remove this certificate.
        </Text>
        <View style={{width:300,flexDirection:'row',justifyContent:"space-between"}}>
        
        

        <TouchableOpacity onPress={()=>{
           
            onClose()
        }} style={styles.cancelButton}>
          <Text style={styles.buttonText}>CANCEL</Text>
        </TouchableOpacity>
       <TouchableOpacity onPress={onSubmit} style={styles.okButton}>
          <Text style={styles.okButtonText}>OK</Text>
        </TouchableOpacity>
      </View>
      </View>
    </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    width:400
  },
  cancelButton: {
    backgroundColor: '#f8d7da',
    paddingVertical: 12,
    borderRadius: 8,
    alignItems: 'center',
    marginTop: 20,
    flex:0.4
  },
  popup: {
    width:"40%",
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    elevation: 5,
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 16,
    color: '#666',
    marginBottom: 20,
  },
  infoBox: {
    backgroundColor: '#E0F7FA',
    padding: 15,
    borderRadius: 8,
    marginBottom: 20,
  },
  infoText: {
    fontSize: 14,
    color: '#00796B',
  },
  detail: {
    fontSize: 16,
    marginBottom: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  okButton: {
    backgroundColor: '#9C27B0',
    paddingVertical: 12,
    borderRadius: 8,
    alignItems: 'center',
    marginTop: 20,
    flex:0.4
  },
  okButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export default DeletePopUp;
