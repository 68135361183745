export const tenantConstants = {

SEND_DIGITAL_CONTRACT_REQUEST: "SEND_DIGITAL_CONTRACT_REQUEST",
SEND_DIGITAL_CONTRACT_SUCCESS: "SEND_DIGITAL_CONTRACT_SUCCESS",
SEND_DIGITAL_CONTRACT_FALIURE:"SEND_DIGITAL_CONTRACT_FALIURE",
USER_PROFILE_REQUEST: 'USER_PROFILE_REQUEST',
USER_PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
USER_PROFILE_FAILURE: 'USER_PROFILE_FAILURE',
  GET_RENTALCOLL_REQUEST: "GET_RENTALCOLL_REQUEST",
  GET_RENTALCOLL_SUCCESS: "GET_RENTALCOLL_SUCCESS",
  GET_RENTALCOLL_FAILURE: "GET_RENTALCOLL_FAILURE",
  SAVE_RENTALCOLL_REQUEST: "SAVE_RENTALCOLL_REQUEST",
  SAVE_RENTALCOLL_SUCCESS: "SAVE_RENTALCOLL_SUCCESS",
  SAVE_RENTALCOLL_FAILURE: "SAVE_RENTALCOLL_FAILURE",
  HIDE_LOADER:"HIDE_LOADER",
  SHOW_LOADER:"SHOW_LOADER",
  //Rental offers
  GET_RENTAL_OFFERS_REQUEST: "GET_RENTAL_OFFERS_REQUEST",
  GET_RENTAL_OFFERS_SUCCESS: "GET_RENTAL_OFFERS_SUCCESS",
  GET_RENTAL_OFFERS_FAILURE: "GET_RENTAL_OFFERS_FAILURE",
  ADD_RENTAL_OFFERS_REQUEST: "ADD_RENTAL_OFFERS_REQUEST",
  ADD_RENTAL_OFFERS_SUCCESS: "ADD_RENTAL_OFFERS_SUCCESS",
  ADD_RENTAL_OFFERS_FAILURE: "ADD_RENTAL_OFFERS_FAILURE",

  UPDATE_FCC_BRANCH_CODE_REQUEST: 'UPDATE_FCC_BRANCH_CODE_REQUEST',
  UPDATE_FCC_BRANCH_CODE_SUCCESS: 'UPDATE_FCC_BRANCH_CODE_SUCCESS',
  UPDATE_FCC_BRANCH_CODE_FAILURE: 'UPDATE_FCC_BRANCH_CODE_FAILURE',

  GET_FCC_BRANCH_CODE_REQUEST: 'GET_FCC_BRANCH_CODE_REQUEST',
  GET_FCC_BRANCH_CODE_SUCCESS: 'GET_FCC_BRANCH_CODE_SUCCESS',
  GET_FCC_BRANCH_CODE_FAILURE: 'GET_FCC_BRANCH_CODE_FAILURE',

  UPLOAD_AST_REQUEST:"UPLOAD_AST_REQUEST",
  UPLOAD_AST_SUCCESS:"UPLOAD_AST_SUCCESS",
  UPLOAD_AST_FAILURE:"UPLOAD_AST_FAILURE",
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",

  REMOVE_AST_REQUEST:"REMOVE_AST_REQUEST",
  REMOVE_AST_SUCCESS:"REMOVE_AST_SUCCESS",
  REMOVE_AST_FAILURE:"REMOVE_AST_FAILURE",
  //Onboardad Teanants
  GET_TEANANTS_REQUEST: "GET_TEANANTS_REQUEST",
  GET_TEANANTS_SUCCESS: "GET_TEANANTS_SUCCESS",
  GET_TEANANTS_FAILURE: "GET_TEANANTS_FAILURE",

  GET_ARCHIVED_FLOWS_REQUEST: "GET_ARCHIVED_FLOWS_REQUEST",
  GET_ARCHIVED_FLOWS_SUCCESS: "GET_ARCHIVED_FLOWS_SUCCESS",
  GET_ARCHIVED_FLOWS_FAILURE: "GET_ARCHIVED_FLOWS_FAILURE",

  ADD_FLOW_TO_PROPERTY_REQUEST: "ADD_FLOW_TO_PROPERTY_REQUEST",
  ADD_FLOW_TO_PROPERTY_SUCCESS: "ADD_FLOW_TO_PROPERTY_SUCCESS",
  ADD_FLOW_TO_PROPERTY_FAILURE: "ADD_FLOW_TO_PROPERTY_FAILURE",

  //hOLDING DEPOITE
  GET_HOLDING_REQUEST: "GET_HOLDING_REQUEST",
  GET_HOLDING_SUCCESS: "GET_HOLDING_SUCCESS",
  GET_HOLDING_FAILURE: "GET_HOLDING_FAILURE",

  // Get branch bank accounts
  GET_BRANCH_BANK_ACCOUNTS_REQUEST: "GET_BRANCH_BANK_ACCOUNTS_REQUEST",
  GET_BRANCH_BANK_ACCOUNTS_SUCCESS: "GET_BRANCH_BANK_ACCOUNTS_SUCCESS",
  GET_BRANCH_BANK_ACCOUNTS_FAILURE: "GET_BRANCH_BANK_ACCOUNTS_FAILURE",

  // Get company branches
  GET_COMPANY_BRANCHES_REQUEST: "GET_COMPANY_BRANCHES_REQUEST",
  GET_COMPANY_BRANCHES_SUCCESS: "GET_COMPANY_BRANCHES_SUCCESS",
  GET_COMPANY_BRANCHES_FAILURE: "GET_COMPANY_BRANCHES_FAILURE",

  // Save company branches
  SAVE_BRANCH_BANK_ACCOUNT_REQUEST: "SAVE_BRANCH_BANK_ACCOUNT_REQUEST",
  SAVE_BRANCH_BANK_ACCOUNT_SUCCESS: "SAVE_BRANCH_BANK_ACCOUNT_SUCCESS",
  SAVE_BRANCH_BANK_ACCOUNT_FAILURE: "SAVE_BRANCH_BANK_ACCOUNT_FAILURE",

  // Revoke confirmation of holding deposit
  REVOKE_CONFIRMATION_HOLDING_DEPOSIT_REQUEST: "REVOKE_CONFIRMATION_HOLDING_DEPOSIT_REQUEST",
  REVOKE_CONFIRMATION_HOLDING_DEPOSIT_SUCCESS: "REVOKE_CONFIRMATION_HOLDING_DEPOSIT_SUCCESS",
  REVOKE_CONFIRMATION_HOLDING_DEPOSIT_FAILURE: "REVOKE_CONFIRMATION_HOLDING_DEPOSIT_FAILURE",

  // Upload invertory documents
  UPLOAD_INVENTORY_DOCUMENTS_REQUEST: "UPLOAD_INVENTORY_DOCUMENTS_REQUEST",
  UPLOAD_INVENTORY_DOCUMENTS_SUCCESS: "UPLOAD_INVENTORY_DOCUMENTS_SUCCESS",
  UPLOAD_INVENTORY_DOCUMENTS_FAILURE: "UPLOAD_INVENTORY_DOCUMENTS_FAILURE",

  // request move in
  REQUEST_MOVIN_MONIES_REQUEST: "REQUEST_MOVIN_MONIES_REQUEST",
  REQUEST_MOVIN_MONIES_SUCCESS: "REQUEST_MOVIN_MONIES_SUCCESS",
  REQUEST_MOVIN_MONIES_FAILURE: "REQUEST_MOVIN_MONIES_FAILURE",

  DELETE_FLOW_REQUEST: "DELETE_FLOW_REQUEST",
  DELETE_FLOW_SUCCESS: "DELETE_FLOW_SUCCESS",
  DELETE_FLOW_FAILURE: "DELETE_FLOW_FAILURE",

  ARCHIEVE_FLOW_REQUEST: "ARCHIEVE_FLOW_REQUEST",
  ARCHIEVE_FLOW_SUCCESS: "ARCHIEVE_FLOW_SUCCESS",
  ARCHIEVE_FLOW_FAILURE: "ARCHIEVE_FLOW_FAILURE",

  UNARCHIEVE_FLOW_REQUEST: "UNARCHIEVE_FLOW_REQUEST",
  UNARCHIEVE_FLOW_SUCCESS: "UNARCHIEVE_FLOW_SUCCESS",
  UNARCHIEVE_FLOW_FAILURE: "UNARCHIEVE_FLOW_FAILURE",

  //Onboardad Teanants
  GET_TOGGLE_REQUEST: "GET_TOGGLE_REQUEST",
  GET_TOGGLE_SUCCESS: "GET_TOGGLE_SUCCESS",
  GET_TOGGLE_FAILURE: "GET_TOGGLE_FAILURE",

  //request teanant right to rent
  REQUEST_RIGHT_TO_RENT_REQUEST: "REQUEST_RIGHT_TO_RENT_REQUEST",
  REQUEST_RIGHT_TO_RENT_SUCCESS: "REQUEST_RIGHT_TO_RENT_SUCCESS",
  REQUEST_RIGHT_TO_RENT_FAIL: "REQUEST_RIGHT_TO_RENT_FAIL",

  //Get Common clauses
  COMMON_CLAUSES:"COMMON_CLAUSES",
  COMMON_CLAUSES_SUCCESS:"COMMON_CLAUSES_SUCCESS",
  COMMON_CLAUSES_FAILURE:"COMMON_CLAUSES_FAILURE",

  //Get Teanants Details
  GET_TEANANTS_DETAILS_REQUEST: "GET_TEANANTS_DETAILS_REQUEST",
  GET_TEANANTS_DETAILS_SUCCESS: "GET_TEANANTS_DETAILS_SUCCESS",
  GET_TEANANTS_DETAILS_FAILURE: "GET_TEANANTS_DETAILS_FAILURE",
  // Get Documents
  GET_TEANANTS_DOC_REQUEST: "GET_TEANANTS_DOC_REQUEST",
  GET_TEANANTS_DOC_SUCCESS: "GET_TEANANTS_DOC_SUCCESS",
  GET_TEANANTS_DOC_FAIL: "GET_TEANANTS_DOC_FAIL",
  //Get FLOWINFO
  ADD_TEANANTS_DETAILS_REQUEST: "ADD_TEANANTS_DETAILS_REQUEST",
  ADD_TEANANTS_DETAILS_SUCCESS: "ADD_TEANANTS_DETAILS_SUCCESS",
  ADD_TEANANTS_DETAILS_FAIL: "ADD_TEANANTS_DETAILS_FAIL",
  //Onboardad Teanants
  GET_FLOWINFO_REQUEST: "GET_FLOWINFO_REQUEST",
  GET_FLOWINFO_SUCCESS: "GET_FLOWINFO_SUCCESS",
  GET_FLOWINFO_FAILURE: "GET_FLOWINFO_FAILURE",
  GET_FLOWINFO_RESET: "GET_FLOWINFO_RESET",

  //Move in
  GET_MOVEIN_REQUEST: "GET_MOVEIN_REQUEST",
  GET_MOVEIN_SUCCESS: "GET_MOVEIN_SUCCESS",
  GET_MOVEIN_FAILURE: "GET_MOVEIN_FAILURE",
  ADD_MOVEIN_REQUEST: "ADD_MOVEIN_REQUEST",
  ADD_MOVEIN_SUCCESS: "ADD_MOVEIN_SUCCESS",
  ADD_MOVEIN_FAILURE: "ADD_MOVEIN_FAILURE",
  ARRANGE_CHECKIN_REQUEST: "ARRANGE_CHECKIN_REQUEST",
  ARRANGE_CHECKIN_SUCCESS: "ARRANGE_CHECKIN_SUCCESS",
  ARRANGE_CHECKIN_FAILURE: "ARRANGE_CHECKIN_FAILURE",
  //Tenancy Information
  GET_TENANCY_INFO_REQUEST: "GET_TENANCY_INFO_REQUEST",
  GET_TENANCY_INFO_SUCCESS: "GET_TENANCY_INFO_SUCCESS",
  GET_TENANCY_INFO_FAILURE: "GET_TENANCY_INFO_FAILURE",
  GET_NHA_REQUEST: "GET_NHA_REQUEST",
  GET_NHA_SUCCESS: "GET_NHA_SUCCESS",
  GET_NHA_FAILURE: "GET_NHA_FAILURE",
  GET_AST_REQUEST: "GET_AST_REQUEST",
  GET_AST_SUCCESS: "GET_AST_SUCCESS",
  GET_AST_FAILURE: "GET_AST_FAILURE",
  ADD_TENANCY_INFO_REQUEST: "ADD_TENANCY_INFO_REQUEST",
  ADD_TENANCY_INFO_SUCCESS: "ADD_TENANCY_INFO_SUCCESS",
  ADD_TENANCY_INFO_FAILURE: "ADD_TENANCY_INFO_FAILURE",

  DOWNLOAD_TENANCY_DOCUMENT_REQUEST: "DOWNLOAD_TENANCY_DOCUMENT_REQUEST",
  DOWNLOAD_TENANCY_DOCUMENT_SUCCESS: "DOWNLOAD_TENANCY_DOCUMENT_SUCCESS",
  DOWNLOAD_TENANCY_DOCUMENT_FAILURE: "DOWNLOAD_TENANCY_DOCUMENT_FAILURE",

  DOWNLOAD_SIGNED_CONTRACT_FAILURE: "DOWNLOAD_SIGNED_CONTRACT_FAILURE",
  DOWNLOAD_SIGNED_CONTRACT_REQUEST: "DOWNLOAD_SIGNED_CONTRACT_REQUEST",
  DOWNLOAD_SIGNED_CONTRACT_SUCCESS: "DOWNLOAD_SIGNED_CONTRACT_SUCCESS",
  //Save slelectedProperty

  GET_FLOW_LOGS_FAILURE: "GET_FLOW_LOGS_FAILURE",
  GET_FLOW_LOGS_SUCCESS: "GET_FLOW_LOGS_SUCCESS",
  GET_FLOW_LOGS_REQUEST: "GET_FLOW_LOGS_REQUEST",

  GET_FLOW_PACKAGES_FAILURE: "GET_FLOW_PACKAGES_FAILURE",
  GET_FLOW_PACKAGES_SUCCESS: "GET_FLOW_PACKAGES_SUCCESS",
  GET_FLOW_PACKAGES_REQUEST: "GET_FLOW_PACKAGES_REQUEST",

  BUY_RENTAL_WARRANTY_FAILURE: "BUY_RENTAL_WARRANTY_FAILURE",
  BUY_RENTAL_WARRANTY_SUCCESS: "BUY_RENTAL_WARRANTY_SUCCESS",
  BUY_RENTAL_WARRANTY_REQUEST: "BUY_RENTAL_WARRANTY_REQUEST",

  CHECK_RENTAL_WARRANTY_REQUEST: "CHECK_RENTAL_WARRANTY_REQUEST",
  CHECK_RENTAL_WARRANTY_SUCCESS: "CHECK_RENTAL_WARRANTY_SUCCESS",
  CHECK_RENTAL_WARRANTY_FAILURE: "CHECK_RENTAL_WARRANTY_FAILURE",

  BUY_OPENFLOW_PACKAGE_FAILURE: "BUY_OPENFLOW_PACKAGE_FAILURE",
  BUY_OPENFLOW_PACKAGE_SUCCESS: "BUY_OPENFLOW_PACKAGE_SUCCESS",
  BUY_OPENFLOW_PACKAGE_REQUEST: "BUY_OPENFLOW_PACKAGE_REQUEST",

  GET_OPENFLOW_SUMMARY_FAILURE: "GET_OPENFLOW_SUMMARY_FAILURE",
  GET_OPENFLOW_SUMMARY_SUCCESS: "GET_OPENFLOW_SUMMARY_SUCCESS",
  GET_OPENFLOW_SUMMARY_REQUEST: "GET_OPENFLOW_SUMMARY_REQUEST",

  SIGN_INTO_OPENFLOW_FAILURE: "SIGN_INTO_OPENFLOW_FAILURE",
  SIGN_INTO_OPENFLOW_SUCCESS: "SIGN_INTO_OPENFLOW_SUCCESS",
  SIGN_INTO_OPENFLOW_REQUEST: "SIGN_INTO_OPENFLOW_REQUEST",

  SIGN_INTO_FCC_REQUEST: "SIGN_INTO_FCC_REQUEST",
  SIGN_INTO_FCC_SUCCESS: "SIGN_INTO_FCC_SUCCESS",
  SIGN_INTO_FCC_FAILURE: "SIGN_INTO_FCC_FAILURE",

  SIGN_INTO_DD_REQUEST: "SIGN_INTO_DD_REQUEST",
  SIGN_INTO_DD_SUCCESS: "SIGN_INTO_DD_SUCCESS",
  SIGN_INTO_DD_FAILURE: "SIGN_INTO_DD_FAILURE",

  SAVE_TEANANTS_PROPERTY_REQUEST: "SAVE_TEANANTS_PROPERTY_REQUEST",
  SAVE_TEANANTS_PROPERTY_SUCCESS: "SAVE_TEANANTS_PROPERTY_SUCCESS",
  SAVE_TEANANTS_PROPERTY_FAILURE: "SAVE_TEANANTS_PROPERTY_FAILURE",

  //Save SAVE_HOLDING_DEPOSIT

  SAVE_HOLDING_DEPOSIT_REQUEST: "SAVE_HOLDING_DEPOSIT_REQUEST",
  SAVE_HOLDING_DEPOSIT_SUCCESS: "SAVE_HOLDING_DEPOSIT_SUCCESS",
  SAVE_HOLDING_DEPOSIT_FAILURE: "SAVE_HOLDING_DEPOSIT_FAILURE",

  //rEQYESTR SAVE_HOLDING_DEPOSIT

  REQUEST_HOLDING_DEPOSIT_REQUEST: "REQUEST_HOLDING_DEPOSIT_REQUEST",
  REQUEST_HOLDING_DEPOSIT_SUCCESS: "REQUEST_HOLDING_DEPOSIT_SUCCESS",
  REQUEST_HOLDING_DEPOSIT_FAILURE: "REQUEST_HOLDING_DEPOSIT_FAILURE",

  //rEQYESTR SAVE_HOLDING_DEPOSIT

  CONFIRM_HOLDING_DEPOSIT_REQUEST: "CONFIRM_HOLDING_DEPOSIT_REQUEST",
  CONFIRM_HOLDING_DEPOSIT_SUCCESS: "CONFIRM_HOLDING_DEPOSIT_SUCCESS",
  CONFIRM_HOLDING_DEPOSIT_FAILURE: "CONFIRM_HOLDING_DEPOSIT_FAILURE",

  //rEQYESTR SAVE_movein_DEPOSIT

  CONFIRM_MOVIN_DEPOSIT_REQUEST: "CONFIRM_MOVIN_DEPOSIT_REQUEST",
  CONFIRM_MOVIN_DEPOSIT_SUCCESS: "CONFIRM_MOVIN_DEPOSIT_SUCCESS",
  CONFIRM_MOVIN_DEPOSIT_FAILURE: "CONFIRM_MOVIN_DEPOSIT_FAILURE",

  //BUY_CER_FAILURE

  EMAIL_CONTRACT_REQUEST: "EMAIL_CONTRACT_REQUEST",
  EMAIL_CONTRACT_SUCCESS: "EMAIL_CONTRACT_SUCCESS",
  EMAIL_CONTRACT_FAILURE: "EMAIL_CONTRACT_FAILURE",

  GET_OPENFLOW_PURCHASES_REQUEST: "GET_OPENFLOW_PURCHASES_REQUEST",
  GET_OPENFLOW_PURCHASES_SUCCESS: "GET_OPENFLOW_PURCHASES_SUCCESS",
  GET_OPENFLOW_PURCHASES_FAILURE: "GET_OPENFLOW_PURCHASES_FAILURE",

  GET_OPENFLOW_INVOICES_REQUEST: "GET_OPENFLOW_INVOICES_REQUEST",
  GET_OPENFLOW_INVOICES_SUCCESS: "GET_OPENFLOW_INVOICES_SUCCESS",
  GET_OPENFLOW_INVOICES_FAILURE: "GET_OPENFLOW_INVOICES_FAILURE",

  BUY_CER_REQUEST: "BUY_CER_REQUEST",
  BUY_CER_SUCCESS: "BUY_CER_SUCCESS",
  BUY_CER_FAILURE: "BUY_CER_FAILURE",

  //BUY_CER_FAILURE

  DEL_CER_REQUEST: "DEL_CER_REQUEST",
  DEL_CER_SUCCESS: "DEL_CER_SUCCESS",
  DEL_CER_FAILURE: "DEL_CER_FAILURE",

  //BUY_CER_FAILURE

  SAVE_CER_REQUEST: "SAVE_CER_REQUEST",
  SAVE_CER_SUCCESS: "SAVE_CER_SUCCESS",
  SAVE_CER_FAILURE: "SAVE_CER_FAILURE",

  //get iden

  GET_IDEN_REQUEST: "GET_IDEN_REQUEST",
  GET_IDEN_SUCCESS: "GET_IDEN_SUCCESS",
  GET_IDEN_FAILURE: "GET_IDEN_FAILURE",

  //BUY_CER_FAILURE

  SAVE_IDEN_REQUEST: "SAVE_IDEN_REQUEST",
  SAVE_IDEN_SUCCESS: "SAVE_IDEN_SUCCESS",
  SAVE_IDEN_FAILURE: "SAVE_IDEN_FAILURE",

  //Get Tds
  GET_TDS_REQUEST: "GET_TDS_REQUEST",
  GET_TDS_SUCCESS: "GET_TDS_SUCCESS",
  GET_TDS_FAILURE: "GET_TDS_FAILURE",

  //BUY_CER_FAILURE

  SAVE_MOVEIN_REQUEST: "SAVE_MOVEIN_REQUEST",
  SAVE_MOVEIN_SUCCESS: "SAVE_MOVEIN_SUCCESS",
  SAVE_MOVEIN_FAILURE: "SAVE_MOVEIN_FAILURE",
  //BUY_CER_FAILURE

  SAVE_CHECKIN_REQUEST: "SAVE_CHECKIN_REQUEST",
  SAVE_CHECKIN_SUCCESS: "SAVE_CHECKIN_SUCCESS",
  SAVE_CHECKIN_FAILURE: "SAVE_CHECKIN_FAILURE",
  //Get
  SAVE_PROTECT_REQUEST: "SAVE_PROTECT_REQUEST",
  SAVE_PROTECT_SUCCESS: "SAVE_CHECKIN_SUCCESS",
  SAVE_PROTECT_FAILURE: "SAVE_CHECKIN_FAILURE",

  //save
  GET_PROTECT_REQUEST: "GET_PROTECT_REQUEST",
  GET_PROTECT_SUCCESS: "GET_PROTECT_SUCCESS",
  GET_PROTECT_FAILURE: "GET_PROTECT_FAILURE",

  //Check Lisence

  //save
  CHECK_LISENCE_REQUEST: "CHECK_LISENCE_REQUEST",
  CHECK_LISENCE_SUCCESS: "CHECK_LISENCE_SUCCESS",
  CHECK_LISENCE_FAILURE: "CHECK_LISENCE_FAILURE",
  CHECK_LISENCE_RESET: "CHECK_LISENCE_RESET",

  //Digital verification

  REQEST_DIGITAL_VARIFICATION_REQUEST: "REQEST_DIGITAL_VARIFICATION_REQUEST",
  REQEST_DIGITAL_VARIFICATION_SUCCESS: "REQEST_DIGITAL_VARIFICATION_SUCCESS",
  REQEST_DIGITAL_VARIFICATION_FAILURE: "REQEST_DIGITAL_VARIFICATION_FAILURE",

  GET_ADD_OR_REMOVE_LANDLORD_REQUEST: "GET_ADD_OR_REMOVE_LANDLORD_REQUEST",
  GET_ADD_OR_REMOVE_LANDLORD_SUCCESS: "GET_ADD_OR_REMOVE_LANDLORD_SUCCESS",
  GET_ADD_OR_REMOVE_LANDLORD_FAILURE: "GET_ADD_OR_REMOVE_LANDLORD_FAILURE",

   //save
   GET_PROPERTYDATA_REQUEST: "GET_PROPERTYDATA_REQUEST",
   GET_PROPERTYDATA_SUCCESS: "GET_PROPERTYDATA_SUCCESS",
   GET_PROPERTYDATA_FAILURE: "GET_PROPERTYDATA_FAILURE",

   SAVE_SHOWPOPUP:"SAVE_SHOWPOPUP",
   SAVE_PROPERTY_SHOWPOPUP:"SAVE_PROPERTY_SHOWPOPUP",


     //getpurchae
     GET_PURCHASE_REQUEST: "GET_PURCHASE_REQUEST",
     GET_PURCHASE_SUCCESS: "GET_PURCHASE_SUCCESS",
     GET_PURCHASE_FAILURE: "GET_PURCHASE_FAILURE",
  //getbranch
  GET_OBRANCH_REQUEST: "GET_OBRANCH_REQUEST",
  GET_OBRANCH_SUCCESS: "GET_OBRANCH_SUCCESS",
  GET_OBRANCH_FAILURE: "GET_OBRANCH_FAILURE",

    //sendBespokeContracyt
    SEND_BESPOKE_REQUEST: "SEND_BESPOKE_REQUEST",
    SEND_BESPOKE_SUCCESS: "SEND_BESPOKE_SUCCESS",
    SEND_BESPOKE_FALIURE: "SEND_BESPOKE_FALIURE",
 
};
