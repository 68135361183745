import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet } from 'react-native';
import { Modal } from '@material-ui/core';

const GuarantorForm = (props) => {
    const {
        onClose,
        showModal,
        data,
        onSave
      } = props;
      console.log("datadata",data)
  const [form, setForm] = useState({
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    postcode: '',
    address: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (name, value) => {
    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validateForm = () => {
    let newErrors = {};
    const mandatoryFields = ['title', 'firstName', 'lastName', 'email', 'address'];

    mandatoryFields.forEach((key) => {
      if (!form[key]) {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').toLowerCase()} is mandatory`;
      }
    });

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (form.email && !emailRegex.test(form.email)) {
      newErrors.email = 'Invalid email format';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log('Form data:', form);
      onSave(form)
    }
  };

  const fields = [
    { label: 'Title', name: 'title' },
    { label: 'First Name', name: 'firstName' },
    { label: 'Last Name', name: 'lastName' },
    { label: 'Email', name: 'email' },
    { label: 'Contact Number', name: 'contactNumber' },
    { label: 'Postcode', name: 'postcode' },
    { label: 'Address', name: 'address' },
  ];

  return (
    <Modal
    open={showModal ? showModal : false}
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 10,
      paddingRight: 10,
   
    }}
  >
    <View style={styles.container}>
      <Text style={styles.title}>Guarantor for HITESH GUNWANT</Text>

      {fields.map(({ label, name }) => (
        <View key={name} style={styles.inputContainer}>
            <Text  style={{marginBottom:8}}>{label}</Text>
          <TextInput
            placeholder={label}
            placeholderTextColor='#999'
            style={[styles.input, errors[name] && styles.inputError]}
            onChangeText={(value) => handleChange(name, value)}
          />
          {errors[name] && <Text style={styles.errorText}>{errors[name]}</Text>}
        </View>
      ))}

      <View style={styles.buttonContainer}>
        <TouchableOpacity onPress={()=>{
            setForm({
                title: '',
                firstName: '',
                lastName: '',
                email: '',
                contactNumber: '',
                postcode: '',
                address: '',
              })
            onClose()
        }} style={styles.cancelButton}>
          <Text style={styles.buttonText}>CANCEL</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.saveButton} onPress={handleSubmit}>
          <Text style={styles.buttonText}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 40,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
    margin: 20,

  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  inputContainer: {
    marginBottom: 10,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 10,
  },
  inputError: {
    borderColor: 'red',
  },
  errorText: {
    color: 'red',
    fontSize: 12,
    marginTop: 5,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelButton: {
    backgroundColor: '#f8d7da',
    padding: 10,
    borderRadius: 5,
    flex:0.4,
     justifyContent:'center',
    alignItems:"center"
  },
  saveButton: {
    backgroundColor: '#6c63ff',
    padding: 10,
    borderRadius: 5,
    flex:0.4,
    justifyContent:'center',
    alignItems:"center"
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
});

export default GuarantorForm;
