const Constant = {
    APP_NAME: "OpenBrix",
    MOCKUP_BASEURL:"https://358d2028-9f24-49b2-86fb-bd132694a264.mock.pstmn.io/v2.0",
    API_URL:  "https://dev.api.hubita.co.uk/v1.0/openbrix",
    IMAGE_URL: "https://assets.openbrix.co.uk/",
    API_URL_POSTCODE:   "https://test.api.openbrix.co.uk/v2.0/aw/find_oprid?postCode=",
    TWITTER_URL: "https://twitter.com/OpenBrix",
    RSS_FEED_URL: "https://paper.li/~api/papers/4610b744-3adf-4e98-9125-f34c38024972/rss",
    RSS_FEED_POST_LIMIT: 5,
    DEBIT_FORM_URL: "https://secure.emandates.co.uk/Pages/Registration.aspx?.Client=1d28abab-45e4-4d91-9962-49eef691cd51&.PaymentPlans=af8b3ac5-d35e-49f0-b120-c1556026ed71",
    GOOGLE_API_KEY: "zwAOl4F04Ei7pBPic60Xwg22814",
    PC_URL: "propertymanagement-uat.productcare.co.uk",
    EIC_URL: 'https://find-energy-certificate.digital.communities.gov.uk/find-a-certificate/search-by-postcode',
    RTR_URL:"https://www.gov.uk/view-right-to-rent"
};


export default Constant;