import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { Modal } from '@material-ui/core';

const ReferencePopUp = (props) => {
    const {
        onClose,
        showModal,
        data,
        onSave
    } = props;
    console.log("datadata", data)
    const [selectedTime, setSelectedTime] = useState('1 Hour');
    const [cost, setCost] = useState(45);

    const handleTimeSelection = (time) => {
        setSelectedTime(time);
        switch (time) {
            case '1 Hour':
                setCost(45);
                break;
            case '24 Hours':
                setCost(25);
                break;
            case '48 Hours':
                setCost(20);
                break;
            default:
                setCost(45);
        }
    };

    const handleStartReference = () => {
        console.log('Selected Time:', selectedTime);
        console.log('Cost:', `£${cost}`);
        onSave({
            selectedTime, cost
        })
        // Add any additional logic you want when starting reference
    };

    return (
        <Modal
            open={showModal ? showModal : false}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 10,
                paddingRight: 10,

            }}
        >
            <View style={styles.container}>
                <Text style={styles.title}>Reference For HITESH GUNWANT</Text>
                <Text style={styles.label}>Reference Type: <Text style={styles.bold}>Enhanced (Full)</Text></Text>

                <Text style={styles.label}>Response Time:</Text>
                <View style={styles.radioGroup}>
                    {['1 Hour', '24 Hours', '48 Hours'].map((time) => (
                        <TouchableOpacity
                            key={time}
                            style={[styles.radioButton, selectedTime === time && styles.radioButtonSelected]}
                            onPress={() => handleTimeSelection(time)}
                        >
                            <Text style={selectedTime === time ? styles.radioTextSelected : styles.radioText}>{time}</Text>
                        </TouchableOpacity>
                    ))}
                </View>

                <Text style={styles.cost}>Cost: £{cost}</Text>
                <TouchableOpacity onPress={() => {
                    onClose()
                }} style={styles.cancelButton}>
                    <Text style={styles.buttonText}>CANCEL</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.startButton} onPress={handleStartReference}>
                    <Text style={styles.startButtonText}>START REFERENCE</Text>
                </TouchableOpacity>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 10,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 4,
        elevation: 3,
        width:"50%",
        height:500
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    label: {
        fontSize: 16,
        marginBottom: 10,
    },
    bold: {
        fontWeight: 'bold',
    },
    radioGroup: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: 20,
    },
    radioButton: {
        padding: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#aaa',
    },
    radioButtonSelected: {
        backgroundColor: '#8A4FFF',
        borderColor: '#8A4FFF',
    },
    radioText: {
        color: '#333',
    },
    radioTextSelected: {
        color: '#fff',
    },
    cost: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    startButton: {
        backgroundColor: '#8A4FFF',
        padding: 15,
        borderRadius: 5,
        alignItems: 'center',
    },
    startButtonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
    },
    cancelButton: {
        backgroundColor: '#f8d7da',
        padding: 10,
        borderRadius: 5,
        flex: 0.4,
        justifyContent: 'center',
        alignItems: "center"
    },
});

export default ReferencePopUp;
