import React, { useEffect, useState } from 'react';
import { ListItem } from '../atoms/listitem';
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import DownloadIcon from '@material-ui/icons/GetApp';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import variables from '../atoms/variables.json';
import { TextInput } from './../atoms/textinput';
import { CardBox } from '../atoms/card';
import { Button } from '../atoms/button';
import ContactCard from '../atoms/contactCard';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PersonIcon from '@material-ui/icons/Person';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import { GetFilename } from 'components/tenantOnboarding/helpers';
import TextField from '@material-ui/core/TextField';
import ToolTip from '../atoms/ToolTip';
// import ID_ICON from './../../../assets/svgIcons/OpenFlo/ID&Reference.svg';
// import { saveAs } from 'file-saver';

import {
  CardWrapper,
  TlyfeSelector,
  TlyfeButtonWrapper,
  LineSeperator,
  Heading,
  SubHeading,
  RowContainer,
  CardFooter,
  ProfilesList,
} from './style';
// import { tenantActions, alertActions } from '_actions';
// import { generalService, TenantService } from '_services';
import BackComponent from '../atoms/backComponent';
import BottomButton from '../atoms/bottomButton';
import OpenFlowModal from '../atoms/modal';
import { SpecialCondition } from '../molecules/specialCondition';
import moment from 'moment';
import { DocumentWithStatusCard } from '../atoms/documentWithStatusCard';
import Container from '../atoms/Container/Container';
import { GetFilename, showErrorToast } from '../helpers';
import drawerHoc from '../../../../components/drawerHOC/DrawerHOC';
import getPlatformType from '../../../../helpers/helpers';
import { tenantActions } from '../../redux/tenant.actions';
import UserCard from './userCard';
import { TouchableOpacity } from 'react-native';
import AgreementComponent from './AgreementComponent';
import AgreementComponentUploaded from './AgreementComponentUploaded';
import SendAgg from './modals/sendAgg';
const layoutType = getPlatformType();
const TenancyInfoAndAgreement = (props) => {
  let landlordModal = {
    name: '',
    correspondece_address: '',
    post_code: '',
    email: '',
    number: '',
    is_england_address: true,
    number_england: '',
    correspondece_address_england: '',
    post_code_england: '',
    email_england: '',
    showForm: false,
  };
  const dispatch = useDispatch();
  const tenancyInfo = useSelector((state) => state.tenant?.flowIdInfo);
  const [signAstStatus, setSignAstStatus] = useState([]);
  const [addedUsers, setAddedUsers] = React.useState(tenancyInfo?.tenants);
  const [signstatus, setSignStatus] = React.useState([]);
  const [landlords, setLandlords] = React.useState([]);
  const [guarantors, setGuarantors] = React.useState([]);
  const [showCCModal, setShowCCModal] = React.useState(false);

  const [fieldsValue, setFieldValues] = useState(landlordModal);
  const [generate, setGenerate] = useState('');
  const [sendContract, setSendContract] = useState('');
  const [signModal, setSignModal] = useState(false);

  const [number_of_occupants, setNumber_of_occupants] = useState(0);
  const [numberOfSubTenantsWithContractEmail, setNumberOfSubTenantsWithContractEmail] = useState(0);
  const [break_clause, setBreakClause] = useState('null');
  const [flowId, setFlowId] = React.useState(null);
  const [closePopup, setClosePopup] = React.useState(false);
  const idendityData = useSelector((state) => state?.tenant?.flowIdInfo);
  const [tenants, setTenants] = React.useState([]);
  const [agreementDoc, setDoc] = React.useState('auto');
  const [bespokeContract, setBespoke] = React.useState([]);
  const [stakeData, setStakeData] = React.useState([false]);
  const modalGlobalObject = useSelector((state) => state?.tenant?.idendityData);
  const [modalState, setModalState] = React.useState({
    send_to_tenant: true,
  });
  useEffect(() => {
    if (modalGlobalObject) {
      setModalState({
        send_to_tenant: modalGlobalObject.send_to_tenant,
      });
    }
  }, [modalGlobalObject]);


  useEffect(() => {
    console.log(props)
    if (props?.route?.params?.id) {
      setFlowId(props?.route?.params?.id);
      fetchFlows()
      dispatch(tenantActions.getSignedObj(props?.route?.params?.id,(data22)=>{
        console.log("getSignedObj",data22)
         setSignAstStatus(data22?.data?.details?.recipients);            
  
      }));
    }
  }, [props?.route?.params?.id]);
  const fetchFlows = () => {
    dispatch(tenantActions.getFlowInfo(props?.route?.params?.id));
    

    
  }
  const getStakeHolderData = (ten = [], land = [], gua = []) => {
    if (!Array.isArray(ten) || !Array.isArray(land) || !Array.isArray(gua)) {
      return {
        tenants: [],
        landlords: [],
        guarantors: [],
      };
    }

    const tempT = ten.map((tenant) => ({
      id: tenant?.app_user?.opbrix_id?.toString() || '',
      name: `Mr. ${tenant?.app_user?.user?.first_name || ''} ${tenant?.app_user?.user?.last_name || ''}`.trim(),
      tlyfeId: tenant?.app_user?.opbrix_id?.toString() || '',
      email: tenant?.app_user?.user?.email || '',
      postcode: tenant?.app_user?.user?.post_code || '',
      contact: tenant?.app_user?.user?.contact_number || '',
      isLead: tenant?.is_lead || false,
      envtoken:tenant?.envtoken,
      contract_sent_at: tenant?.contract_sent_at,
      uploaded_ast_contract_url: tenant?.uploaded_ast_contract_url
    }));

    const tempL = land.map((tenant) => ({
      id: '',
      name: `Mr. ${tenant?.first_name || ''} ${tenant?.last_name || ''}`.trim(),
      tlyfeId: '',
      email: tenant?.email || '',
      isLead: tenant?.is_lead || false,
      contact: tenant?.number || '',
      postcode: tenant?.post_code || '',
      envtoken:tenant?.envtoken,
      contract_sent_at: tenant?.contract_sent_at,
    }));

    const tempG = gua.map((tenant) => ({
      id: '',
      name: `Mr. ${tenant?.first_name || ''} ${tenant?.last_name || ''}`.trim(),
      tlyfeId: '',
      email: tenant?.email || '',
      isLead: tenant?.is_lead || false,
      contact: tenant?.number || '',
      postcode: tenant?.post_code || '',
      envtoken:tenant?.envtoken,
      contract_sent_at: tenant?.contract_sent_at,
    }));
    console.log("tempG", tempG)
    console.log("tempL", tempL)
    console.log("tempT", tempT)

    return {
      tenants: tempT,
      landlords: tempL,
      guarantors: tempG,
    };
  };

  useEffect(() => {
    if (idendityData?.tenants && idendityData?.tenants?.length) {
      setTenants(idendityData?.tenants);
    } else {
      setTenants([]);
    }
  }, [idendityData?.tenants]);


  useEffect(() => {
    if (numberOfSubTenantsWithContractEmail) {
      setSendContract('emailContract');
    }
  }, [numberOfSubTenantsWithContractEmail]);
  useEffect(() => {
    if (tenancyInfo) {
      if (tenancyInfo?.is_ast_generated) {
        setGenerate('ast');
      } else if (tenancyInfo?.is_nha_generated) {
        setGenerate('nha');
      } else if (tenancyInfo?.bespoke_contract_url) {
        setGenerate('bespoke');
      } else {
        setGenerate('ast');
      }
    }
  }, [tenancyInfo])
  console.log('signstatus', signstatus);
  useEffect(() => {
    if (tenancyInfo?.tenants && tenancyInfo?.tenants.length) {
      setAddedUsers(tenancyInfo?.tenants);
      setNumberOfSubTenantsWithContractEmail(
        tenancyInfo?.tenants.filter((tenant) => tenant.is_contract_emailed).length
      );
    }

    setStakeData(getStakeHolderData(tenancyInfo?.tenants, tenancyInfo?.open_flow_landlords, tenancyInfo?.open_flow_guarantors))
    if (tenancyInfo && tenancyInfo?.number_of_occupants) {
      setNumber_of_occupants(tenancyInfo?.number_of_occupants);
    }
    if (tenancyInfo?.flow && tenancyInfo?.break_clause) {
      setBreakClause(tenancyInfo?.break_clause);
    }
    if (tenancyInfo?.open_flow_landlords && tenancyInfo?.open_flow_landlords?.length) {
      setLandlords(tenancyInfo?.open_flow_landlords);
    } else {
      setLandlords([]);
    }

    if (tenancyInfo?.open_flow_guarantors && tenancyInfo?.open_flow_guarantors?.length) {
      setGuarantors(tenancyInfo?.open_flow_guarantors);
    } else {
      setGuarantors([]);
    }


    if (tenancyInfo?.bespoke_contract_url) {
      setDoc('bespoke');
      setBespoke(JSON.parse(tenancyInfo?.bespoke_contract_url));
    }else{
      setBespoke([])
    }
    if (tenancyInfo?.envid) {
      setSendContract('digitalContract');
      dispatch(
        tenantActions.getSignedObj(flowId, (data) => {
          console.log('datatta', data);
          setSignStatus(data);
        })
      );
    } else {
      setSendContract('');
    }
  }, [
    tenancyInfo?.flow,
    tenancyInfo?.tenants,
    tenancyInfo?.flow?.common_conditions,
    tenancyInfo?.landlords,
    tenancyInfo?.guarantors,
    tenancyInfo?.agrementType,
    tenancyInfo?.flow?.bespoke_contract_url,
    tenancyInfo?.flow?.envid,
  ]);

  function downloadSignedContractW(id) {
    // generalService
    //   .downloadSignedContract(id)
    //   .then((response) => {
    //     if (!response.ok) {
    //       // alertActions.error('This user does not have verified Documents to download');
    //       var err = new Error('HTTP status code: ' + response.status);
    //       err.response = response;
    //       err.status = response.status;
    //       throw err;
    //     } else {
    //       return response.blob();
    //     }
    //   })
    //   .then((blob) => {
    //     // alertActions.success('Your document are being downloaded, check your download folder');
    //     // saveAs(blob, 'signedAST' + id + '.zip');
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  const handleChange = (e, name) => {
    setFieldValues({ ...fieldsValue, [e.target.name]: e.target.value });
  };

  const _emailContract = (data) => {
    if (tenancyInfo?.flow?.nha_contract_url || tenancyInfo?.flow?.ast_contract_url) {
      // dispatch(tenantActions.emailContract(flowId, data));
    } else {
      // dispatch(alertActions.error('Please generate the contract first'));
    }
  };

  const _createContract = () => {
    let data

    if (generate == 'nha') {
      data = { "flow_id": flowId, "contract_type": "nha" }
      dispatch(tenantActions.getAST(data, flowId));
    } else if (generate == 'ast') {
      data = { "flow_id": flowId, "contract_type": "ast" }
      dispatch(tenantActions.getAST(data, flowId));
    } else {
      showErrorToast('Please select the contract to generate');
    }
  };

  const _onSave = () => {
    let data = {
      landlords: landlords,
      tenants: tenants,
      agrementType: agreementDoc,
    };
    dispatch(
      tenantActions.sendContractDigitally(data, flowId, (data) => {
        setSignModal(false);
      })
    );
  };




  const DocumentBox = (title, desc, date, view, download, docList) => (
    <CardBox hasShadow hasBorderRadius style={{ marginTop: 20 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <SubHeading>{title}</SubHeading>
        <SubHeading style={{ color: '#9F78FF', fontSize: 12 }}>{date}</SubHeading>
        <Button
          textColor={'black'}
          type="custom"
          color={variables.red}
          title="Delete contract"
          style={{ marginLeft: 'auto', marginTop: 10 }}
          onClick={() => {
            if (generate == 'ast') {
              dispatch(tenantActions.removeAST(flowId))
            }
            if (generate == 'nha') {
              dispatch(tenantActions.deleteNHA(flowId))
            }
          }}
        />
      </div>
      <SubHeading>{desc}</SubHeading>
      {docList &&
        docList.map((item, index) => (
          <div style={{ width: '70%', marginTop: '15px' }} key={'docList' + index}>
            <CardBox
              style={{
                backgroundColor: variables.peachgreen,
                border: '1px solid ' + variables.puregreen,
              }}
              hasShadow={true}
              hasBorderRadius={true}
            >
              <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                <p style={{ marginTop: '5px', fontSize: '14px' }}>{item.name || 'File'}</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <a
                    target="_blank"
                    href={
                      item.path ||
                      'https://scholarshipfellow.com/wp-content/uploads/2021/02/illustration-document-icon.jpg'
                    }
                  >
                    <SubHeading
                      style={{
                        marginTop: '5px',
                        fontSize: '14px',
                        fontWeight: 500,
                        color: variables.purple1,
                      }}
                    >
                      View
                    </SubHeading>
                  </a>
                </div>
              </div>
            </CardBox>
          </div>
        ))}
    </CardBox>
  );

  const _onAddInfo = () => {
    let data = {
      flow: {
        ...tenancyInfo?.flow,
        number_of_occupants: number_of_occupants,
        break_clause: break_clause,
        // additional_conditions: additionalCondition.join(','),
        // common_conditions: commonCondition,
      },
      landlords: landlords,
      agrementType: agreementDoc,
      bespokeContractDocuments: bespokeContract,
    };
    // dispatch(tenantActions.addTenancyInfo(data, flowId));
  };

  const renderModalBody = (data) => {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <TextInput
          title={
            <>
              <strong>Title: </strong>
            </>
          }
          disabled={true}
          type={'row'}
          wrapperstyle={{
            width: '10%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data?.title || ''}
        />
        <TextInput
          disabled={true}
          title={
            <>
              <strong>First Name: </strong>
            </>
          }
          type={'row'}
          wrapperstyle={{
            width: '38%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data?.first_name || ''}
        />
        <TextInput
          title={
            <>
              <strong>Last Name: </strong>
            </>
          }
          type={'row'}
          disabled={true}
          wrapperstyle={{
            width: '49%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data?.last_name || ''}
        />
        <TextInput
          title={
            <>
              <strong>Email: </strong>
            </>
          }
          type={'row'}
          disabled={true}
          wrapperstyle={{
            width: '49%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data?.email || ''}
        />
        <TextInput
          title={
            <>
              <strong>Contact Number: </strong>
            </>
          }
          type={'row'}
          disabled={true}
          wrapperstyle={{
            width: '49%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data?.contact_number || ''}
        />
      </div>
    );
  };

  const getDocumentColor = () => {
    if (tenancyInfo?.rentalOffer?.is_seen_howtorent) {
      return 'green';
    }
    return '#FC4850';
  };

  const renderRemoveCardBody = (item, index) => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <RowContainer>
        <SubHeading>Do you wish to remove this ?</SubHeading>
      </RowContainer>
      <br />
      <br />
      <TlyfeButtonWrapper>
        <Button
          onClick={() => {
            let tempLandLords = [...landlords];
            tempLandLords.splice(index, 1);
            setLandlords(tempLandLords);
            setClosePopup(false);
          }}
          title={'Yes'}
          type="stripe"
        />
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            setClosePopup(false);
          }}
          title={'No'}
          type="stripe"
        />
      </TlyfeButtonWrapper>
      <br /> <br />
      <RowContainer>
        <SubHeading>Please Note</SubHeading>
        <SubHeading> Once you remove this you cannot undo this change</SubHeading>
      </RowContainer>
    </div>
  );

  const renderCommonClauses = (item, index) => (
    <div
      style={{
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <span>
        You may select these additional clauses and edit them as appropriate. {'\n'} please select a clause in the empty
        section
      </span>

      <Select
        style={{
          width: '60%',
          height: '40px',
          borderWidth: 2,
          borderRadius: 30,
          borderColor: 'pink',
          marginLeft: '20%',
          marginTop: 30,
        }}
        onChange={handleChange}
        variant="outlined"
        name="rental_frequency"
        placeholder="duration"
      >
        <MenuItem value={'week'}>Weekly</MenuItem>
        <MenuItem value={'monthly'}>Per Calender Month</MenuItem>
        <MenuItem value={'quarterly'}>Quarterly</MenuItem>
        <MenuItem value={'6 monthly'}>6 Monthly</MenuItem>
        <MenuItem value={'annually'}>Annually</MenuItem>
      </Select>
      <TextField
        InputLabelProps={{ shrink: true }}
        multiline
        variant="outlined"
        rows={4}
        placeholder="Enter text here"
        style={{ marginTop: 20, marginBottom: 20 }}
      />
      <TlyfeButtonWrapper>
        <Button onClick={() => { }} title={'Save'} type="stripe" />
      </TlyfeButtonWrapper>
    </div>
  );

  const renderModalBodyLandloard = (data) => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <TextInput
        title={
          <>
            <strong>Title: </strong>
          </>
        }
        disabled={true}
        type={'row'}
        wrapperstyle={{
          width: '10%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data?.title || ''}
      />
      <TextInput
        title={
          <>
            <strong>First Name: </strong>
          </>
        }
        type={'row'}
        disabled={true}
        wrapperstyle={{
          width: '19%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.first_name || ''}
      />
      <TextInput
        title={
          <>
            <strong>Last Name: </strong>
          </>
        }
        type={'row'}
        disabled={true}
        wrapperstyle={{
          width: '19%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.last_name || ''}
      />
      <TextInput
        title={
          <>
            <strong>Email: </strong>
          </>
        }
        type={'row'}
        disabled={true}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.email || ''}
      />
      <TextInput
        title={
          <>
            <strong>Contact Number: </strong>
          </>
        }
        type={'row'}
        disabled={true}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.number || ''}
      />
      <TextInput
        title={
          <>
            <strong>Post Code: </strong>
          </>
        }
        type={'row'}
        disabled={true}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.post_code || ''}
      />
      <TextInput
        title={
          <>
            <strong>Correspondence Address: </strong>
          </>
        }
        type={'row'}
        disabled={true}
        wrapperstyle={{
          width: '100%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.correspondece_address || ''}
      />
    </div>
  );

  const renderModalBodyGuarantor = (data) => {
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <TextInput
          title={
            <>
              <strong>Title: </strong>
            </>
          }
          disabled={true}
          type={'row'}
          wrapperstyle={{
            width: '48%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data.title || ''}
        />
        <TextInput
          title={
            <>
              <strong>First Name: </strong>
            </>
          }
          disabled={true}
          type={'row'}
          wrapperstyle={{
            width: '24%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data.first_name || ''}
        />
        <TextInput
          title={
            <>
              <strong>Last Name: </strong>
            </>
          }
          type={'row'}
          disabled={true}
          wrapperstyle={{
            width: '24%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data.last_name || ''}
        />
        <TextInput
          title={
            <>
              <strong>Correspondence Address: </strong>
            </>
          }
          type={'row'}
          disabled={true}
          wrapperstyle={{
            width: '48%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data.correspondece_address || ''}
        />
        <TextInput
          title={
            <>
              <strong>Post Code : </strong>
            </>
          }
          type={'row'}
          disabled={true}
          wrapperstyle={{
            width: '48%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data.post_code || ''}
        />
        <TextInput
          title={
            <>
              <strong>Email Address : </strong>
            </>
          }
          type={'row'}
          disabled={true}
          wrapperstyle={{
            width: '48%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data.email || ''}
        />
        <TextInput
          title={
            <>
              <strong>Contact Number : </strong>
            </>
          }
          type={'row'}
          disabled={true}
          wrapperstyle={{
            width: '48%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data.number || ''}
        />
      </div>
    );
  };

  const handleReferencingSelection = (send_digital_contract, index, isGarunter, isLandLords) => {
    let teampArr = [...tenants];
    if (isLandLords) {
      teampArr = [...landlords];
    }
    if (isGarunter) {
      if (teampArr[index].gaurantor) {
        teampArr[index].gaurantor = {
          ...teampArr[index].gaurantor,
          send_digital_contract,
        };
      } else {
        teampArr[index].gaurantor = {
          send_digital_contract,
        };
      }
    } else {
      teampArr[index].send_digital_contract = send_digital_contract;
    }

    if (isLandLords) {
      setLandlords(teampArr);
    } else {
      setTenants(teampArr);
    }
  };
  console.log("guarantors", guarantors)
  const renderReferencingModal = () => (
    <div>
      <SubHeading>Below are a list of people who are stakeholders in this agreement</SubHeading>
      <SubHeading>Please select the people who are required to sign this agreement</SubHeading>
      <Heading>Tenants</Heading>
      <ProfilesList style={{ marginTop: 0 }}>
        {tenants &&
          tenants.map((item, index) => {
            console.log('itemitemitem,itemitemitem', item);
            return (
              <ContactCard
                style={{ marginTop: 0 }}
                key={item.email + new Date()}
                nonRemove
                key={item.opbrix_id}
                row={item.email}
                is_lead={item.is_lead ? true : false}
                name={item.first_name + ' ' + item.last_name}
                renderModal={renderModalBody(item)}
                selection={true}
                isSelected={item?.send_digital_contract}
                onSelect={(data) => handleReferencingSelection(data, index, false)}
              />
            );
          })}
      </ProfilesList>
      <Heading style={{ marginTop: 15 }}>Guarantors</Heading>
      <ProfilesList style={{ marginTop: 0 }}>
        {guarantors &&
          guarantors.map((item, index) => {
            if (item?.first_name) {
              return (
                <ContactCard
                  key={item.email + new Date()}
                  nonRemove
                  style={{ marginTop: 0 }}
                  key={item.open_flow_guarantor_id}
                  heading={'Guarantor Details'}
                  row={item.email}
                  is_lead={false}
                  name={item?.first_name + ' ' + item?.last_name}
                  renderModal={renderModalBodyGuarantor(item)}
                  selection={true}
                  isSelected={item?.is_contract_emailed}
                  onSelect={(data) => handleReferencingSelection(data, index, true)}
                />
              );
            } else {
              return null;
            }
          })}
      </ProfilesList>
      <Heading style={{ marginTop: 15 }}>Landlords</Heading>
      <ProfilesList style={{ marginTop: 0 }}>
        {landlords &&
          landlords.map((item, index) => (
            <ContactCard
              style={{ marginTop: 0 }}
              key={item.email + new Date()}
              nonRemove
              heading={'Landlord Details'}
              key={item.opbrix_id}
              row={item.email}
              is_lead={item.is_lead ? true : false}
              name={item.first_name + ' ' + item.last_name}
              renderModal={renderModalBodyLandloard(item)}
              selection={true}
              isSelected={item?.send_digital_contract}
              onSelect={(data) => handleReferencingSelection(data, index, false, true)}
            />
          ))}
      </ProfilesList>
      <SubHeading style={{ marginTop: 20 }}>Are you happy to proceed</SubHeading>
      <RowContainer>
        <Button type="stripe" onClick={() => _onSave()} title="Yes" />
        <Button type="stripe" onClick={() => setSignModal(false)} style={{ marginLeft: 15 }} title="No" />
      </RowContainer>
      <SubHeading style={{ marginTop: 20 }}>
        <b>PLEASE NOTE:</b>
        <br />
        If you if you choose digital signitories all selected people must sign to complete the agreement.
      </SubHeading>
    </div>
  );
  const addDoc = (data, index, type) => {
    let teampArr = [...addedUsers];
    if (teampArr[index]) {
      teampArr[index]['email_tanancey_agreement'] = [
        {
          document_url: data[0],
        },
      ];
    }
    setAddedUsers(teampArr);
  };

  console.log('stakeData', stakeData);
  const removeDoc = (index, docIndex, type) => {
    let teampArr = [...addedUsers];

    let tempDocArry = teampArr[index]['email_tanancey_agreement'];

    tempDocArry.splice(docIndex, 1);

    teampArr[index]['email_tanancey_agreement'] = [...tempDocArry];

    setAddedUsers(teampArr);
  };

  return (
    <Container>
      <CardWrapper>
        <ListItem
          type={'complete'}
          title={'Tenancy Information & Agreements'}
          listIcon={<ListAltTwoToneIcon style={{ fontSize: '25px', color: variables.purple1 }} />}
          // icon={ID_ICON}
          showPropertyCard
          description={`Digitally create, check or amend your tenancy Agreement, and send it to all parties.`}
        />

        <BackComponent
          id={flowId}
          isComplete={tenancyInfo?.flow?.landlord_status === 1 ? true : false}
          flowtype={'landlord_status'}
        />
        <UserCard
          stakeData={stakeData}
        />
        <Heading style={{ marginTop: 20 }}>Tenancy Agreement Document</Heading>
        <SubHeading>
          Do you wish to auto generate a tenancy agreement form the information you have provided, or manually email
          your own bespoke tenancy agreement?
        </SubHeading>

        <RadioGroup
          aria-label="generate"
          name="generate"
          value={agreementDoc}
          style={{ flexDirection: 'row', alignSelf: 'center' }}
          onChange={(e) => {


            let contractType = e.target.value
            if (contractType == 'auto') {
              if (idendityData?.bespoke_contract_url) {
                showErrorToast("Please delete Bespoke contract first")
              } else {
                setDoc(e.target.value)
              }
            }
            if (contractType == 'bespoke') {
              if (idendityData?.is_ast_generated) {
                showErrorToast("Please delete AST contract first")
              } else if (idendityData?.is_nha_generated) {
                showErrorToast("Please delete NHA contract first")
              } else {
                setDoc(e.target.value)
              }
            }
          }}
        >
          <FormControlLabel
            value="auto"
            control={
              <Radio
                style={{
                  color: variables.purple1,
                  fontSize: 10,
                  justifyContent: 'flex-end',
                }}
              />
            }
            label="Auto Generate"
          />
          <FormControlLabel
            value="bespoke"
            control={
              <Radio
                style={{
                  color: variables.purple1,
                  fontSize: 10,
                  justifyContent: 'flex-end',
                }}
              />
            }
            label="Bespoke Email Contract"
          />
        </RadioGroup>
        {agreementDoc == 'bespoke' ? (
          <>
            {bespokeContract.length > 0
              ? bespokeContract.map((e, index) => (
                <CardBox
                  hasShadow={true}
                  hasBorderRadius={true}
                  style={{
                    backgroundColor: variables.peachgreen,
                    border: '1px solid ' + variables.puregreen,
                    marginTop: 40,
                    width: '50%',
                    alignSelf: 'center',
                  }}
                >
                  <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                    <p style={{ marginTop: '5px', fontSize: '14px' }}>Agreement {index + 1}</p>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <TouchableOpacity
                        onPress={() => {
                          dispatch(tenantActions.deleteBespokeContract(flowId,e));

                          // let tempArray = [...bespokeContract];
                          // tempArray.splice(index, 1);

                          // setBespoke(tempArray);
                        }}
                      >
                        <SubHeading
                          style={{
                            marginTop: '5px',
                            fontSize: '14px',
                            fontWeight: 500,
                            color: 'red',
                            marginRight: 20,
                          }}
                        >
                          Remove
                        </SubHeading>
                      </TouchableOpacity>
                      <a target="_blank" href={e}>
                        <SubHeading
                          style={{
                            marginTop: '5px',
                            fontSize: '14px',
                            fontWeight: 500,
                            color: variables.purple1,
                          }}
                        >
                          View
                        </SubHeading>
                      </a>
                    </div>
                  </div>
                </CardBox>
              ))
              : null}
            <Button
              onClick={(e) => {
                let fff = {
                  bespoke_contract_url: JSON.stringify([...bespokeContract, e?.data]),
                  flow_id: flowId
                }
                dispatch(tenantActions.uploadManualAST(
                  fff
                  , flowId))
              }}
              type={'Upload'}
              style={{ color: variables.purple1, width: 200, alignSelf: 'center', marginTop: 40 }}
              title={'Upload Bespoke Contract'}
            />
            {/* <>
              <CardBox hasShadow={true} hasBorderRadius={true} style={{ marginTop: 40, padding: 20 }}>
                <Heading style={{ marginTop: 40 }}>Email Bespoke Contracts</Heading>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <SubHeading> Please click send to individually email all the parties.</SubHeading>

                  <Button
                    onClick={() => {
                      if (bespokeContract.length) {
                        dispatch();
                        // tenantActions.sendBespokeContract({ bespokeContractDocuments: bespokeContract }, flowId)
                      } else {
                        // dispatch(alertActions.error('Please upload contract to proceed!'));
                      }
                    }}
                    style={{
                      borderColor: variables.puregreen,
                      color: '#000',
                    }}
                    title={'Send Bespoke Contract'}
                    type="stripe"
                  />
                </div>
                <SubHeading style={{ marginTop: 20 }}>
                  All parties will recived a email PDF that will allow them to download, sign and send back.
                </SubHeading>
                <SubHeading style={{ marginTop: 30 }}>PLEASE NOTE: No verification is required for this.</SubHeading>

                {addedUsers ? (
                  <>
                    {addedUsers.map((data, index) => {
                      return (
                        <CardBox hasShadow={true} hasBorderRadius={true} style={{ marginTop: 40, padding: 20 }}>
                          <DocumentWithStatusCard
                            flowID={flowId}
                            isPrimary={data.is_lead}
                            name={data?.first_name + ' ' + data?.last_name}
                            customButton={true}
                            isCustomTextSent={data?.is_contract_emailed}
                            docValue={data?.email_tanancey_agreement ? data?.email_tanancey_agreement : []}
                            onClick={(data) => addDoc(data, index, 'agent_uploaded_ast')}
                            onRemove={(docIndex) => removeDoc(index, docIndex, 'agent_uploaded_ast')}
                          />
                        </CardBox>
                      );
                    })}
                  </>
                ) : null}

                {addedUsers ? (
                  <>
                    {addedUsers.map((data, index) => {
                      return (
                        <>
                          {data?.uploaded_ast_contract_url ? (
                            <div>
                              <div style={{ flex: 0.95 }}>
                                <SubHeading
                                  style={{
                                    fontSize: '14px',
                                    marginTop: 10,
                                    fontWeight: 'bold',
                                    color: props.type == 'complete' ? variables.purple1 : 'black',
                                  }}
                                >
                                  {data?.first_name + data?.last_name + ' Uploaded Signed Tenancy Agreement'}
                                </SubHeading>
                                <SubHeading
                                  style={{ marginTop: '5px', fontSize: '13px' }}
                                  dangerouslySetInnerHTML={{ __html: props.description }}
                                />
                              </div>

                              <div style={{ width: '70%', marginTop: '15px' }}>
                                <CardBox
                                  hasShadow={true}
                                  hasBorderRadius={true}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    backgroundColor: variables.peachgreen,
                                    border: '1px solid ' + variables.puregreen,
                                  }}
                                >
                                  <SubHeading
                                    style={{
                                      marginTop: '5px',
                                      fontSize: '14px',
                                      width: 230,
                                      height: '100%',
                                    }}
                                  >
                                    {GetFilename(data?.uploaded_ast_contract_url)}
                                  </SubHeading>
                                  <a target="_blank" href={data?.uploaded_ast_contract_url}>
                                    <SubHeading
                                      style={{
                                        marginTop: '5px',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        color: variables.purple1,
                                      }}
                                    >
                                      View
                                    </SubHeading>
                                  </a>
                                </CardBox>
                              </div>
                            </div>
                          ) : null}
                        </>
                      );
                    })}
                  </>
                ) : null}
              </CardBox>
            </> */}
          </>
        ) : (
          <>
            <Heading style={{ marginTop: 20 }}>Auto Tenancy Agreement Creation</Heading>
            <CardBox
              hasShadow={true}
              hasBorderRadius={true}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 10,
                position: 'relative',
                width: '100%',
              }}
            >
              <ToolTip
                style={{ top: '0%', right: '-40%' }}
                heading={'Tool tip title'}
                ContentData={[
                  'Use an AST if the following apply:',
                  'The tenants are individuals (not a company);',
                  'The property will be the tenant’s main residence; the annual rent does not exceed £100k p/a;',
                  'The Landlord will not be a Resident Landlord.',
                ]}
              />
              <ToolTip
                style={{ bottom: '-280%', right: '-40%' }}
                heading={'Tool tip title'}
                ContentData={[
                  'Custom tenancy agreement?',
                  'If you with to autogenerate a bespoke AST, please contact support@openbrix.co.uk and we will implement a bespoke AST for your branch at a one-off fixed cost.',
                ]}
              />

              <RadioGroup
                aria-label="generate"
                name="generate"
                value={generate}
                onChange={(e) => {
                  let contractType = e.target.value
                  if (contractType == 'ast') {
                    if (idendityData?.is_nha_generated) {
                      showErrorToast("Please delete NHA contract first")
                    } else if (idendityData?.bespoke_contract_url) {
                      showErrorToast("Please delete Bespoke contract first")
                    } else {
                      setGenerate(e.target.value)
                    }
                  }
                  if (contractType == 'nha') {
                    if (idendityData?.is_ast_generated) {
                      toast.error("Please delete AST contract first")
                    } else if (idendityData?.bespoke_contract_url) {
                      toast.error("Please delete Bespoke contract first")
                    } else {
                      setGenerate(e.target.value)
                    }
                  }
                  if (contractType == 'bespoke') {
                    if (idendityData?.is_ast_generated) {
                      toast.error("Please delete AST contract first")
                    } else if (idendityData?.is_nha_generated) {
                      toast.error("Please delete NHA contract first")
                    } else {
                      setGenerate(e.target.value)
                    }
                  }
                }}
              >
                <FormControlLabel
                  value="ast"
                  control={
                    <Radio
                      style={{
                        color: variables.purple1,
                        fontSize: 10,
                        justifyContent: 'flex-end',
                      }}
                    />
                  }
                  label="Assured Shorthold Tenancy (AST)"
                />
                <FormControlLabel
                  value="nha"
                  control={
                    <Radio
                      style={{
                        color: variables.purple1,
                        fontSize: 10,
                        justifyContent: 'flex-end',
                      }}
                    />
                  }
                  label="Non Housing Act (NHA)"
                />
              </RadioGroup>

             
              <Button
                textColor={'black'}
                type="custom"
                color={variables.lead_green}
                title="Create contract"
                style={{ marginLeft: 'auto', marginTop: 10 }}
                onClick={() => _createContract()}
              />
            </CardBox>
            {tenancyInfo?.is_ast_generated
              ? DocumentBox(
                'Assured Shorthold Tenancy (AST)',
                'An Assured Shorthold Tenancy Agreement is automatically created using the information you have already populated. ',
                'Last updated ' + moment(tenancyInfo?.flow?.ast_generate_at).format('DD-MM-YYYY HH:mm:ss'),
                'https://scholarshipfellow.com/wp-content/uploads/2021/02/illustration-document-icon.jpg',
                'https://scholarshipfellow.com/wp-content/uploads/2021/02/illustration-document-icon.jpg',
                [
                  {
                    name: 'AST.pdf',
                    path: tenancyInfo?.ast_contract_url,
                  },
                  {
                    name: 'Prescribed.pdf',
                    path: tenancyInfo?.prescribed_contract_url,
                  },
                  {
                    name: 'SUPPORTING DOCUMENTS',
                    path: tenancyInfo?.supporting_documents_url,
                  },
                ],

              )
              : null}
            {tenancyInfo?.is_nha_generated
              ? DocumentBox(
                'Non Housing Act (NHA)',
                'An Non Housing Acts Agreement is automatically created using the information you have already populated.',
                'Last updated ' + tenancyInfo?.nha_generate_at,
                'https://scholarshipfellow.com/wp-content/uploads/2021/02/illustration-document-icon.jpg',
                'https://scholarshipfellow.com/wp-content/uploads/2021/02/illustration-document-icon.jpg',
                [
                  {
                    name: GetFilename(tenancyInfo?.nha_contract_url),
                    path: tenancyInfo?.nha_contract_url,
                  },
                  {
                    name: 'SUPPORTING DOCUMENTS',
                    path: tenancyInfo?.supporting_documents_url,
                  },
                ]
                // TenantService.deleteNHA
              )
              : null}


            <LineSeperator />

          </>
        )}
       {  idendityData?.is_ast_generated || idendityData?.is_nha_generated || idendityData?.bespoke_contract_url?
        <>
        <LineSeperator />
        <AgreementComponent
        signAstStatus={signAstStatus}
envid={idendityData.envid}
stakeData={stakeData}
        />
  <LineSeperator />
  <AgreementComponentUploaded
        signAstStatus={signAstStatus}
envid={idendityData.envid}
stakeData={stakeData}
        />
          <LineSeperator />
        </>:null}
        


        <Heading style={{ marginTop: 0 }}>How To Rent Document</Heading>
        <CardBox hasShadow hasBorderRadius style={{ marginTop: 20, width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <SubHeading>How to Rent</SubHeading>
            <InsertDriveFileIcon style={{ color: getDocumentColor(), fontSize: '35px' }} />
          </div>
          <SubHeading>
            Who is this guide for? This guide is for people who are renting a home privately under an assured shorthold
            tenancy or direct from a landlord or through a letting agency. Most of it will equally apply if they are in
            a shared property but in certain cases, their rights and responsibilities will vary.
            <strong>Please note.</strong> This guide will be automatically sent with your tenancy agreement
          </SubHeading>
          <div style={{ width: '70%', marginTop: '15px' }}>
            <CardBox
              hasShadow={true}
              hasBorderRadius={true}
              style={{
                backgroundColor: variables.peachgreen,
                border: '1px solid ' + variables.puregreen,
              }}
            >
              <div style={{ justifyContent: 'space-between', display: 'flex', height: 25 }}>
                <SubHeading style={{ marginTop: '5px', fontSize: '14px' }}>HowtoRent.pdf</SubHeading>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <a target="_blank" href={'https://assets.openbrix.co.uk/HowToRent.pdf'}>
                    <SubHeading
                      style={{
                        marginTop: '5px',
                        fontSize: '14px',
                        fontWeight: 500,
                        color: variables.purple1,
                      }}
                    >
                      View
                    </SubHeading>
                  </a>
                </div>
              </div>
            </CardBox>
          </div>
        </CardBox>
        <CardFooter></CardFooter>

        <LineSeperator />
        <BottomButton
          showSendbutton={false}
          showContinueModal={false}
          onContinue={() => {
            _onAddInfo();
          }}
        />
      </CardWrapper>

{

}
      <OpenFlowModal
        showModal={showCCModal}
        setShowModal={setShowCCModal}
        modalBody={renderCommonClauses()}
        showFooter={false}
        modalHeading="Condition Clauses"
      />
      {signModal && (
        <OpenFlowModal
          showModal={signModal}
          setShowModal={setSignModal}
          modalBody={renderReferencingModal()}
          showFooter={false}
          modalHeading="Agreement Signitories"
          modalWidth={600}
        />
      )}
    </Container>
  );
};
export default drawerHoc({
  Component: TenancyInfoAndAgreement,
  showBackButton: true,
});
