import { TenantService } from "./tenant.service";
import { tenantConstants } from "./tenant.constants";
import { alertActions } from "./alert.actions";
import { showErrorToast, showSuccesToast } from "../tenantOnboarding/helpers";

export const tenantActions = {
  showPropertyPopup,
  getNHA,
  getAST,
  buyCer,
  delCer,
  saveCer,
  getFlowLogs,
  getMoveIn,
  addMoveIn,
  removeAST,
  deleteFlow,
  getTdsData,
  getTeanants,
  buyRentalWarranty,
  buyFlowPackage,
  getFlowInfo,
  getDocuments,
  archieveFlow,
  confirmMovin,
  requestMoveInMonies,
  emailContract,
  getFlowPackages,
  signInToFcc,
  signInToDD,
  signUpOpenflow,
  requestdigital,
  confirmDeposit,
  revokeConfirmationDeposit,
  saveMoveinData,
  uploadInventoryDocuments,
  getProtectData,
  arrangeCheckIn,
  unArchieveFlow,
  toggleComplete,
  addTenancyInfo,
  getTenancyInfo,
  addRentalOffer,
  saveProtectData,
  checkWarrantyAvailable,
  fullReferencing,
  CheckForLisence,
  getIdentityData,
  uploadManualAST,
  getRentalOffers,
  getCommonClauses,
  saveIdentityData,
  getArchivedFlows,
  cleanGetFlowInfo,
  requestrighttorent,
  getFccBranchCode,
  getHoldingDeposit,
  getBranchBankAccounts,
  getCompanyBranches,
  saveBankAccount,
  getCompletedFlows,
  addFlowToProperty,
  saveHoldingDeposit,
  getPropertyDetails,
  saveTeanantDetails,
  saveSlectedProperty,
  addNRemovedLandlord,
  updateFccBranchCode,
  getOpenflowPurchases,
  getOpenflowInvoices,
  getOnboardedTeanants,
  requestHoldingDeposit,
  sendContractDigitally,
  getRentalCollectionData,
  saveRentalCollectionData,
  getVerificationReferencing,
  downloadTenancyDocumentPack,
  getOpenFlowDashboardData,
  downloadSignedContract,
  showPopup,
  downloadPurchageTable,
  openflowBranchNotification,
  sendBespokeContract,
  getSignedObj,
  postRtrCode,
  getTenantByTlyfeId,
  uploadFiles,
  getUserProfile,
  validateEmail,
  deleteRtrDoc,
  deleteGau,
  rtrReminder,
  saveGurantorInfo,
  deleteNHA,
  deleteBespokeContract,
};
function requestMoveInMonies(payload, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.requestMoveInMonies(payload, id).then((data) => {
      if (data.status) {
        // dispatch(getMoveIn(id));
        dispatch(getFlowInfo(id));
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.REQUEST_MOVIN_MONIES_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.REQUEST_MOVIN_MONIES_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.REQUEST_MOVIN_MONIES_FAILURE, error };
  }
}
 
function uploadFiles(files, type, cb) {
  return (dispatch) => {
    dispatch(request());
    return TenantService.uploadFiles(files, type).then((response) => {
      if (response.failed) {
        let _error = "";
        response.failed.forEach((item, index) => {
          _error = _error.concat(`${item.name} could not be uploaded as ${item.message} \n`);
        });
        dispatch(alertActions.error(_error));
      }
      if (cb) {
        cb(response);
      }
      dispatch(success(response, type));
      return response;
    });
  };

  function request() {
    return { type: tenantConstants.UPLOAD_IMAGE };
  }
  function success(response, data) {
    if (data === "property-video") {
      return {
        type: tenantConstants.UPLOAD_VIDEO_SUCCESS,
        video: response.data,
      };
    } else {
      return {
        type: tenantConstants.UPLOAD_IMAGE_SUCCESS,
        images: response.data,
      };
    }
  }
}

function getTenantByTlyfeId(text,cb) {
  return (dispatch) => {

    TenantService.getTenantByTlyfeId(text).then(( data) => {
      if (data.status) {
       if(cb){
        cb(data)
       }
      } else {
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };

}
function getUserProfile() {
  return (dispatch) => {
    dispatch(request());

    TenantService.getUserProfile().then((data) => {
      if (data.status) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };

  function request() {
    return { type: tenantConstants.USER_PROFILE_REQUEST };
  }

  function success(user) {
    return { type: tenantConstants.USER_PROFILE_SUCCESS, user };
  }

  function failure(error) {
    return { type: tenantConstants.USER_PROFILE_FAILURE, error };
  }
}
function postRtrCode(obj,cb) {
  return (dispatch) => {
    TenantService.postRtrCode(obj).then((data) => {
      if (data?.status) {
        if(cb){
          cb(data)
          dispatch(alertActions.success("Approved successfully!"));
        }
      } else {
        if(cb){
          cb(data)
        }
        if(data?.message){
          dispatch(alertActions.error(data?.message?.toString()));
        }else{
          dispatch(alertActions.error("Something went worng!"));
        }
      }
    });
  };
}
function sendBespokeContract(data,flowid) {
  return (dispatch) => {
    dispatch(request());
    TenantService.sendBespokeContract(data,flowid).then((data) => {
      if (data.status) {
        dispatch(getFlowInfo(flowid));
        dispatch(success(data.data));
        dispatch(alertActions.success("Bespoke Contract sent successfully!"))
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SEND_BESPOKE_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.SEND_BESPOKE_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.SEND_BESPOKE_FALIURE, error };
  }
}
function openflowBranchNotification(branchid,payload) {
  return (dispatch) => {
    dispatch(request());
    TenantService.openflowBranchNotification(branchid, payload).then((data) => {
      if (data.status) {
        dispatch(alertActions.success("Email saved"));
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_OBRANCH_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_OBRANCH_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_OBRANCH_FAILURE, error };
  }
}

function getSignedObj(flowid,cb) {
  return (dispatch) => {
    TenantService.getSignedObj(flowid).then((data) => {
      if (data.status) {
        cb(data)
      } else {
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
}

function sendContractDigitally(data,flowid,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.sendContractDigitally(data,flowid).then((data) => {
      if (data.status) {
        dispatch(success(data.data));
        dispatch(alertActions.success("Contract sent successfully!"))
        cb(data)
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };

  
  function request() {
    return { type: tenantConstants.SEND_DIGITAL_CONTRACT_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.SEND_DIGITAL_CONTRACT_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.SEND_DIGITAL_CONTRACT_FALIURE, error };
  }
}

function getRentalCollectionData(flowid) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getRentalCollectionData(flowid).then((data) => {
      if (data.status) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_RENTALCOLL_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_RENTALCOLL_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_RENTALCOLL_FAILURE, error };
  }
}
function saveRentalCollectionData(payload, flowid) {

  return (dispatch) => {
    dispatch(request());
    TenantService.saveRentalCollectionData(payload, flowid).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
        if(data?.data?.flow_id){
          // history.push(`/dashboard/deposit-protect-home-setup/${data?.data?.flow_id}`);
        }
        dispatch(alertActions.success("Rent collection requested successfully!"));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SAVE_RENTALCOLL_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.SAVE_RENTALCOLL_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.SAVE_RENTALCOLL_FAILURE, error };
  }
}

function requestdigital(payload, flowid) {
  return (dispatch) => {
    dispatch(request());
    TenantService.requestdigital(payload, flowid).then((data) => {
      if (data.status) {
        dispatch(getIdentityData(flowid));
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.REQEST_DIGITAL_VARIFICATION_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.REQEST_DIGITAL_VARIFICATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.REQEST_DIGITAL_VARIFICATION_FAILURE, error };
  }
}

function getPropertyDetails(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getPropertyDetails(id).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_PROPERTYDATA_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_PROPERTYDATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_PROPERTYDATA_FAILURE, error };
  }
}


function CheckForLisence(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.CheckForLisence(id).then((data) => {
      if (data.status) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.CHECK_LISENCE_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.CHECK_LISENCE_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.CHECK_LISENCE_FAILURE, error };
  }
}
function saveProtectData(data, id,bloCKnAVIGATION,showAlert) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveProtectData(data, id).then((data) => {
      if (data.status) {
        dispatch(success(data));
        if(bloCKnAVIGATION){
          showAlert && dispatch(alertActions.success("Certificate Sent Successfully"))
        }else{
          // history.push(`/dashboard/tenant-onboarding/${id}`);
        }
       
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SAVE_PROTECT_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.SAVE_PROTECT_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.SAVE_PROTECT_FAILURE, error };
  }
}
function getProtectData(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getProtectData(id).then((data) => {
      if (data.status) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_PROTECT_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_PROTECT_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_PROTECT_FAILURE, error };
  }
}
function getCommonClauses() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getCommonClauses().then((data) => {
      if (data.status) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.COMMON_CLAUSES };
  }
  function success(data) {
    return { type: tenantConstants.COMMON_CLAUSES_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.COMMON_CLAUSES_FAILURE, error };
  }
}

function saveIdentityData(OBJ, id,noNavigate) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveIdentityData(OBJ, id).then((data) => {
      if (data) {
        dispatch(success(data));
        if(!noNavigate){
          // history.push(`/dashboard/tenant-onboarding/${id}`);
        }
       
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SAVE_IDEN_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.SAVE_IDEN_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.SAVE_IDEN_FAILURE, error };
  }
}
function uploadManualAST(payload, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.uploadManualAST(payload,id).then((data) => {
      if (data) {
        dispatch(success(data));
        dispatch(getFlowInfo(id));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.UPLOAD_AST_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.UPLOAD_AST_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.UPLOAD_AST_FAILURE, error };
  }
}

function getOpenflowPurchases(isCommission) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getOpenflowPurchases(isCommission).then((data) => {
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_OPENFLOW_PURCHASES_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_OPENFLOW_PURCHASES_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_OPENFLOW_PURCHASES_FAILURE, error };
  }
}

function getOpenflowInvoices() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getOpenflowInvoices().then((data) => {
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_OPENFLOW_INVOICES_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_OPENFLOW_INVOICES_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_OPENFLOW_INVOICES_FAILURE, error };
  }
}

function removeAST(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.deleteAST(id).then((data) => {
      if (data) {
        dispatch(success(data));
        dispatch(getFlowInfo(id));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.REMOVE_AST_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.REMOVE_AST_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.REMOVE_AST_FAILURE, error };
  }
}
function deleteBespokeContract(id,URL) {
  return (dispatch) => {
    dispatch(request());
    TenantService.deleteBespokeContract(id,URL).then((data) => {
      if (data) {
        dispatch(success(data));
        dispatch(getFlowInfo(id));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.REMOVE_AST_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.REMOVE_AST_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.REMOVE_AST_FAILURE, error };
  }
}

function deleteNHA(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.deleteNHA(id).then((data) => {
      if (data) {
        dispatch(success(data));
        dispatch(getFlowInfo(id));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.REMOVE_AST_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.REMOVE_AST_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.REMOVE_AST_FAILURE, error };
  }
}

function getIdentityData(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getIdentityData(id).then((data) => {
      if (data.status) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_IDEN_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_IDEN_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_IDEN_FAILURE, error };
  }
}

function saveCer(cerOBJ, id,cb,isDelete) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveCer(cerOBJ, id).then((data) => {
      if (data) {
        if(cb){
          cb(data)
        }
        dispatch(success(data));
        if(isDelete){

        }else{
          if(cerOBJ?.send_to_tenant){
            showSuccesToast("Documents have been sent to all tenants")
            
          } else {
            showSuccesToast("document not sent, as per your request")
          }
        }
       
      } else {
        dispatch(failure(data));
        if (data.message) {
          showErrorToast(data.message?.toString());
        } else {
         showErrorToast("Something went wrong");
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SAVE_CER_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.SAVE_CER_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.SAVE_CER_FAILURE, error };
  }
}

function delCer(cerName, id, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.delCer(cerName, id).then((data) => {
      if (data.status) {
        if (cb) {
          cb(data.status);
        }
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.DEL_CER_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.DEL_CER_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.DEL_CER_FAILURE, error };
  }
}

function buyCer(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.buyCer( id).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.BUY_CER_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.BUY_CER_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.BUY_CER_FAILURE, error };
  }
}

function getHoldingDeposit(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getHoldingDeposit(id).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_HOLDING_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_HOLDING_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_HOLDING_FAILURE, error };
  }
}

function getBranchBankAccounts() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getBranchBankAccounts().then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_BRANCH_BANK_ACCOUNTS_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_BRANCH_BANK_ACCOUNTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_BRANCH_BANK_ACCOUNTS_FAILURE, error };
  }
}

function getCompanyBranches() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getCompanyBranches().then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_COMPANY_BRANCHES_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_COMPANY_BRANCHES_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_COMPANY_BRANCHES_FAILURE, error };
  }
}

function saveBankAccount(payload,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveBankAccount(payload).then((data) => {
      if (data) {
        if(cb){
          cb(data)
        }
        dispatch(success(data));
        showSuccesToast("Bank account added successfully")
      } else {
        dispatch(failure(data));
        if (data.message) {
          showErrorToast(data.message?.toString());
        } else {
         showErrorToast("Something went wrong");
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SAVE_CER_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.SAVE_CER_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.SAVE_CER_FAILURE, error };
  }
}

function getOnboardedTeanants(pageNo) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getOnboardedTeanants(pageNo).then((data) => {
      if (data?.data) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_TEANANTS_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_TEANANTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_TEANANTS_FAILURE, error };
  }
}

function getArchivedFlows(PAGENO) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getArchivedFlows(PAGENO).then((data) => {
      if (data?.data) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_ARCHIVED_FLOWS_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_ARCHIVED_FLOWS_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_ARCHIVED_FLOWS_FAILURE, error };
  }
}
function getCompletedFlows(pageNo) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getCompletedFlows(pageNo).then((data) => {
      if (data?.data) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_ARCHIVED_FLOWS_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_ARCHIVED_FLOWS_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_ARCHIVED_FLOWS_FAILURE, error };
  }
}

function toggleComplete(url) {
  return (dispatch) => {
    dispatch(request());
    TenantService.toggleComplete(url).then((data) => {
      if (data?.data) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_TOGGLE_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_TOGGLE_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_TOGGLE_FAILURE, error };
  }
}

function getDocuments(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getDocuments(id).then((data) => {
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_TEANANTS_DOC_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_TEANANTS_DOC_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_TEANANTS_DOC_FAIL, error };
  }
}

function cleanGetFlowInfo() {
  return (dispatch) => {
    dispatch({ type: tenantConstants.GET_FLOWINFO_RESET });
  };
}
function validateEmail(EMAIL,CB) {
  return (dispatch) => {
    dispatch(request());
    TenantService.validateEmail(EMAIL).then((data) => {
      if (data) {
        dispatch(success());
        if(data.status){
          CB(data)
        }else{
          showErrorToast(data.message);
        }
   
      } else {
        dispatch(failure());
        if (data.message) {
          showErrorToast(data.message?.toString());
        } else {
          showErrorToast("Something went wrong");
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.ADD_TEANANTS_DETAILS_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.ADD_TEANANTS_DETAILS_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.ADD_TEANANTS_DETAILS_FAIL, error };
  }
}
function saveTeanantDetails(payload, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveTeanantDetails(payload).then((data) => {
      dispatch(success(data));
      if (data.status) {
       if(cb){
        cb(data)
       }
        // history.push(`/dashboard/tenant-onboarding/${flowId}`);
      } else {
        dispatch(failure(data));
        if (data.message) {
          showErrorToast(data.message?.toString())
        } else {
          showErrorToast("Something went wrong");
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.ADD_TEANANTS_DETAILS_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.ADD_TEANANTS_DETAILS_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.ADD_TEANANTS_DETAILS_FAIL, error };
  }
}

function requestrighttorent(payload) {
  return (dispatch) => {
    dispatch(request());
    TenantService.requestrighttorent(payload).then((data) => {
      if (data) {
        dispatch(alertActions.success("Request sent successfully"));
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    }).catch(e=>{

    });
  };
  function request() {
    return { type: tenantConstants.REQUEST_RIGHT_TO_RENT_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.REQUEST_RIGHT_TO_RENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.REQUEST_RIGHT_TO_RENT_FAIL, error };
  }
}

function saveSlectedProperty(data) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveSlectedProperty(data.property_id).then((data) => {
      if (data?.status) {
        // history.push(
        //   `/dashboard/tenant-onboarding/${data?.data?.openflow_id}`
        // );
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SAVE_TEANANTS_PROPERTY_REQUEST };
  }
  function success(propertyData) {
    return {
      type: tenantConstants.SAVE_TEANANTS_PROPERTY_SUCCESS,
      data: propertyData,
    };
  }
  function failure(error) {
    return { type: tenantConstants.SAVE_TEANANTS_PROPERTY_FAILURE, error };
  }
}

function unArchieveFlow(flowId, pageNo) {
  return (dispatch) => {
    dispatch(request());
    TenantService.unArchieveFlow(flowId).then((data) => {
      if (data?.data) {
        dispatch(success(data?.data));
        dispatch(getArchivedFlows(pageNo));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.UNARCHIEVE_FLOW_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.UNARCHIEVE_FLOW_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.UNARCHIEVE_FLOW_FAILURE, error };
  }
}

function archieveFlow(flowId, pageNo) {
  return (dispatch) => {
    dispatch(request());
    TenantService.archieveFlow(flowId).then((data) => {
      if (data?.data) {
        dispatch(success(data?.data));
        dispatch(getOnboardedTeanants(pageNo));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.ARCHIEVE_FLOW_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.ARCHIEVE_FLOW_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.ARCHIEVE_FLOW_FAILURE, error };
  }
}

function addFlowToProperty(flowId) {
  return (dispatch) => {
    dispatch(request());
    TenantService.addFlowToProperty(flowId).then((data) => {
      if (data?.data) {
        dispatch(success(data?.data));
        // history.push(`/dashboard/active-tenants`);
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.ADD_FLOW_TO_PROPERTY_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.ADD_FLOW_TO_PROPERTY_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.ADD_FLOW_TO_PROPERTY_FAILURE, error };
  }
}

function buyRentalWarranty(flowId, isCommission,data) {
  return (dispatch) => {
    dispatch(request());
    TenantService.buyRentalWarranty(flowId, isCommission,data).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
        dispatch(getIdentityData(flowId));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.BUY_RENTAL_WARRANTY_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.BUY_RENTAL_WARRANTY_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.BUY_RENTAL_WARRANTY_FAILURE, error };
  }

}

function checkWarrantyAvailable(flowId) {
  return (dispatch) => {
    dispatch(request());
    TenantService.checkWarrantyAvailable(flowId).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
        dispatch(getIdentityData(flowId));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.CHECK_RENTAL_WARRANTY_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.CHECK_RENTAL_WARRANTY_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.CHECK_RENTAL_WARRANTY_FAILURE, error };
  }
}

function buyFlowPackage(payload) {

  return (dispatch) => {
    dispatch(request());
    TenantService.buyFlowPackage(payload).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.BUY_OPENFLOW_PACKAGE_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.BUY_OPENFLOW_PACKAGE_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.BUY_OPENFLOW_PACKAGE_FAILURE, error };
  }
}

function getOpenFlowDashboardData() {

  return (dispatch) => {
    dispatch(request());
    TenantService.getOpenFlowDashboardData().then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_OPENFLOW_SUMMARY_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_OPENFLOW_SUMMARY_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_OPENFLOW_SUMMARY_FAILURE, error };
  }
}

function signUpOpenflow(payload) {
  return (dispatch) => {
    dispatch(request());
    TenantService.signUpOpenflow(payload).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
        dispatch(getUserProfile());
        // history.push(`/dashboard/OpenFlo/dashboard`);
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SIGN_INTO_OPENFLOW_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.SIGN_INTO_OPENFLOW_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.SIGN_INTO_OPENFLOW_FAILURE, error };
  }
}

function signInToFcc(payload) {
  return (dispatch) => {
    dispatch(request());
    TenantService.signInToFcc(payload).then((data) => {
      dispatch(success(data));
    });
  };
  function request() {
    return { type: tenantConstants.SIGN_INTO_FCC_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.SIGN_INTO_FCC_SUCCESS, data };
  }
}

function signInToDD(payload) {
  return (dispatch) => {
    dispatch(request());
    TenantService.signInToDD(payload).then((data) => {
      if (data.status) {
        dispatch(success(data));
      } else {
        dispatch(failure(data))
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    })
  };

  function request() {
    return {type: tenantConstants.SIGN_INTO_DD_REQUEST};
  }

  function success(data) {
    return {type: tenantConstants.SIGN_INTO_DD_SUCCESS, data};
  }

  function failure(error) {
    return {type: tenantConstants.SIGN_INTO_DD_FAILURE, error };
  }
}

function getFlowPackages() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getFlowPackages().then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_FLOW_PACKAGES_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_FLOW_PACKAGES_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_FLOW_PACKAGES_FAILURE, error };
  }
}

function deleteFlow(flowId, isArchived, pageNo) {
  return (dispatch) => {
    dispatch(request());
    TenantService.deleteFlow(flowId).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
        if (isArchived) {
          dispatch(getArchivedFlows(pageNo));
        } else {
          dispatch(getOnboardedTeanants(pageNo));
        }
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.DELETE_FLOW_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.DELETE_FLOW_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.DELETE_FLOW_FAILURE, error };
  }
}

function getFlowInfo(flowId) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getFlowInfo(flowId).then((data) => {
      if (data?.data) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_FLOWINFO_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_FLOWINFO_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_FLOWINFO_FAILURE, error };
  }
}

function getTeanants(flowId) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getTeanants(flowId).then((data) => {
      if (data?.data) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_TEANANTS_DETAILS_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_TEANANTS_DETAILS_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_TEANANTS_DETAILS_FAILURE, error };
  }
}

function getRentalOffers(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getRentalOffers(id).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_RENTAL_OFFERS_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_RENTAL_OFFERS_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_RENTAL_OFFERS_FAILURE, error };
  }
}
function addRentalOffer(payload, id, url, ismoveIn) {
  return (dispatch) => {
    dispatch(request());
    TenantService.addRentalOffer(payload, id, url).then((data) => {
      console.log("asdasdasdasdasd")
      if (data.status) {
          dispatch(success({tenantLoading: false}))
          showSuccesToast(
              "Offer Send Successfully to all associated tenants!"
            )
            dispatch(success(data));
          // history.push(`/dashboard/tenant-onboarding/${id}`);
       
      } else {
        dispatch(failure(data));
        if (data.message) {
         showErrorToast(data.message?.toString())
        } else {
          showErrorToast("Something went wrong")
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.ADD_RENTAL_OFFERS_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.ADD_RENTAL_OFFERS_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.ADD_RENTAL_OFFERS_FAILURE, error };
  }
}
function getMoveIn(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getMoveIn(id).then((data) => {
      if (data.status) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_MOVEIN_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_MOVEIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_MOVEIN_FAILURE, error };
  }
}
function getVerificationReferencing(data,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getVerificationReferencing(data).then((data) => {
      if (data) {
        if(cb){
          cb(data)
        }
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          showErrorToast(data.message?.toString());
        } else {
         showErrorToast("Something went wrong");
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SHOW_LOADER };
  }
  function success(data) {
    return { type: tenantConstants.HIDE_LOADER, data };
  }
  function failure(error) {
    return { type: tenantConstants.HIDE_LOADER, error };
  }
}

function fullReferencing(payload,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.fullReferencing(payload).then((data) => {
      if (data) {
        if(cb){
          cb(data)
        }
        dispatch(success(data));
      } else {

        if (data.message) {
          showErrorToast(data.message?.toString());
        } else {
         showErrorToast("Something went wrong");
        }
        dispatch(failure(data));
      }
    });
  };
  function request() {
    return { type: tenantConstants.SHOW_LOADER };
  }
  function success(data) {
    return { type: tenantConstants.HIDE_LOADER, data };
  }
  function failure(error) {
    return { type: tenantConstants.HIDE_LOADER, error };
  }
}

function deleteGau(data,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.deleteGau(data).then((data) => {
      if (data) {
        if(cb){
          cb(data)
        }
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          showErrorToast(data.message?.toString());
        } else {
         showErrorToast("Something went wrong");
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SHOW_LOADER };
  }
  function success(data) {
    return { type: tenantConstants.HIDE_LOADER, data };
  }
  function failure(error) {
    return { type: tenantConstants.HIDE_LOADER, error };
  }
}
function deleteRtrDoc(data,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.deleteRtrDoc(data).then((data) => {
      if (data) {
        if(cb){
          cb(data)
        }
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          showErrorToast(data.message?.toString());
        } else {
         showErrorToast("Something went wrong");
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SHOW_LOADER };
  }
  function success(data) {
    return { type: tenantConstants.HIDE_LOADER, data };
  }
  function failure(error) {
    return { type: tenantConstants.HIDE_LOADER, error };
  }
}

function saveGurantorInfo(data,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveGurantorInfo(data).then((data) => {
      if (data) {
        if(cb){
          cb(data)
        }
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          showErrorToast(data.message?.toString());
        } else {
         showErrorToast("Something went wrong");
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SHOW_LOADER };
  }
  function success(data) {
    return { type: tenantConstants.HIDE_LOADER, data };
  }
  function failure(error) {
    return { type: tenantConstants.HIDE_LOADER, error };
  }
}
function rtrReminder(data,id,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.rtrReminder(data,id).then((data) => {
      if (data) {
        if(cb){
          cb(data)
        }
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          showErrorToast(data.message?.toString());
        } else {
         showErrorToast("Something went wrong");
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SHOW_LOADER };
  }
  function success(data) {
    return { type: tenantConstants.HIDE_LOADER, data };
  }
  function failure(error) {
    return { type: tenantConstants.HIDE_LOADER, error };
  }
}

function addMoveIn(payload) {
  return (dispatch) => {
    dispatch(request());
    TenantService.addMoveIn(payload).then((data) => {
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.ADD_MOVEIN_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.ADD_MOVEIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.ADD_MOVEIN_FAILURE, error };
  }
}

function getTenancyInfo(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getTenancyInfo(id).then((data) => {
      if (data?.status) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_TENANCY_INFO_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_TENANCY_INFO_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_TENANCY_INFO_FAILURE, error };
  }
}

function getAST(data, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getAST(data, id).then((data) => {
      if (data.status) {
        dispatch(success(data));
        dispatch(getFlowInfo(id));
      } else {
        dispatch(failure(data));
        if (data.message) {
          showErrorToast(data?.message?.toString());
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_AST_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_AST_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_AST_FAILURE, error };
  }
}
function getNHA(data, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getNHA(data, id).then((data) => {
      if (data.status) {
        dispatch(success(data));
        dispatch(getFlowInfo(id));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_NHA_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_NHA_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_NHA_FAILURE, error };
  }
}
function saveMoveinData(payload, id,backNavigation,isRequest) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveMoveinData(payload, id).then((data) => {
      if (data?.status) {
        dispatch(getFlowInfo(id));
        dispatch(success(data?.data));
        if(backNavigation){
          // history.push(`/dashboard/tenant-onboarding/${id}`);
          // dispatch(alertActions.success("Movein request saved successfully"));
          showSuccesToast("Movein request saved successfully")
        }
        else if(isRequest){
          // dispatch(alertActions.success("Movein request sent successfully"));
          showSuccesToast("Movein request sent successfully")
        }
        else{
          // dispatch(alertActions.success("Account details updated successfully"));
          showSuccesToast("Account details updated successfully")
        }
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          // dispatch(alertActions.error("Something went wrong"));
          showErrorToast("Something went wrong")
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SAVE_MOVEIN_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.SAVE_MOVEIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.SAVE_MOVEIN_FAILURE, error };
  }
}

function uploadInventoryDocuments(payload) {
  return (dispatch) => {
    dispatch(request());
    TenantService.uploadInventoryDocuments(payload, payload?.flow_id).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
        dispatch(getFlowInfo(payload?.flow_id));
        showSuccesToast("Invertory documents updated successfully")
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          showErrorToast("Something went wrong")
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.UPLOAD_INVENTORY_DOCUMENTS_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.UPLOAD_INVENTORY_DOCUMENTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.UPLOAD_INVENTORY_DOCUMENTS_FAILURE, error };
  }
}

function arrangeCheckIn(payload, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.arrangeCheckIn(payload, id).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
        dispatch(alertActions.success("Submit Successfully"));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SAVE_CHECKIN_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.SAVE_CHECKIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.SAVE_CHECKIN_FAILURE, error };
  }
}

function downloadTenancyDocumentPack(flowId) {
  return (dispatch) => {
    dispatch(request());
    TenantService.downloadTenancyDocumentPack(flowId).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
        window.open(data?.data);
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.DOWNLOAD_TENANCY_DOCUMENT_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.DOWNLOAD_TENANCY_DOCUMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.DOWNLOAD_TENANCY_DOCUMENT_FAILURE, error };
  }
}

function getTdsData() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getTdsData().then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_TDS_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_TDS_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_TDS_FAILURE, error };
  }
}

function downloadSignedContract(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.downloadSignedContract(id).then((data) => {
      if (data.status) {
        dispatch(success());
        window.open(data?.data);
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.DOWNLOAD_SIGNED_CONTRACT_REQUEST };
  }
  function success() {
    return { type: tenantConstants.DOWNLOAD_SIGNED_CONTRACT_SUCCESS };
  }
  function failure() {
    return { type: tenantConstants.DOWNLOAD_SIGNED_CONTRACT_FAILURE };
  }
}

function emailContract(id,data) {
  return (dispatch) => {
    dispatch(request());
    TenantService.emailContract(id,data).then((data) => {
      if (data.status) {
        dispatch(success())
        dispatch(getFlowInfo(id));
        dispatch(alertActions.success("Contract emailed successfully"));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.EMAIL_CONTRACT_REQUEST };
  }
  function success() {
    return { type: tenantConstants.EMAIL_CONTRACT_SUCCESS };
  }
  function failure() {
    return { type: tenantConstants.EMAIL_CONTRACT_FAILURE };
  }
}


function addTenancyInfo(payload, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.addTenancyInfo(payload, id).then((data) => {
      if (data) {
        dispatch(success(data));
        // history.push(`/dashboard/tenant-onboarding/${id}`);
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.ADD_TENANCY_INFO_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.ADD_TENANCY_INFO_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.ADD_TENANCY_INFO_FAILURE, error };
  }
}
function saveHoldingDeposit(payload, id, isRequest, backNavigation, isUpdate) {

  return (dispatch) => {
    dispatch(request());
    TenantService.saveHoldingDeposit(payload, id, isRequest).then((data) => {
      if (data.status) {
        dispatch(getFlowInfo(id));
        dispatch(success(data));
        if (isRequest) {
          // dispatch(getHoldingDeposit(id));
          showSuccesToast("Deposit request sent successfully")
          if(backNavigation){
            // history.push(`/dashboard/tenant-onboarding/${id}`);
          }
        } else {
          if (backNavigation) {
            // history.push(`/dashboard/tenant-onboarding/${id}`);
          } else if(isUpdate) {
            showSuccesToast("Account details updated successfully")
          }
        }
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
         showErrorToast("Something went wrong")
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.SAVE_HOLDING_DEPOSIT_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.SAVE_HOLDING_DEPOSIT_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.SAVE_HOLDING_DEPOSIT_FAILURE, error };
  }
}
function requestHoldingDeposit(payload, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.requestHoldingDeposit(payload, id).then((data) => {
      if (data) {
        dispatch(success(data));
        // history.push(`/dashboard/tenant-onboarding/${id}`);
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.REQUEST_HOLDING_DEPOSIT_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.REQUEST_HOLDING_DEPOSIT_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.REQUEST_HOLDING_DEPOSIT_FAILURE, error };
  }
}
function confirmDeposit(payload, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.confirmDeposit(payload, id).then((data) => {
      if (data.status) {
        dispatch(getFlowInfo(id));
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data?.message));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.CONFIRM_HOLDING_DEPOSIT_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.CONFIRM_HOLDING_DEPOSIT_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.CONFIRM_HOLDING_DEPOSIT_FAILURE, error };
  }
}

function revokeConfirmationDeposit(payload, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.revokeConfirmationDeposit(payload, id).then((data) => {
      if (data.status) {
        dispatch(getFlowInfo(id));
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data?.message));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.REVOKE_CONFIRMATION_HOLDING_DEPOSIT_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.REVOKE_CONFIRMATION_HOLDING_DEPOSIT_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.REVOKE_CONFIRMATION_HOLDING_DEPOSIT_FAILURE, error };
  }
}

function confirmMovin(payload, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.confirmMovin(payload, id).then((data) => {
      if (data.status) {
        // dispatch(getMoveIn(id));
        dispatch(getFlowInfo(id));
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.CONFIRM_MOVIN_DEPOSIT_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.CONFIRM_MOVIN_DEPOSIT_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.CONFIRM_MOVIN_DEPOSIT_FAILURE, error };
  }
}

function addNRemovedLandlord(OBJ,id,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.addNRemovedLandlord(OBJ, id).then((data) => {
      dispatch(success(data));
      if (data) {
        cb(data)
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_ADD_OR_REMOVE_LANDLORD_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_ADD_OR_REMOVE_LANDLORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_ADD_OR_REMOVE_LANDLORD_FAILURE, error };
  }
}

function getFccBranchCode() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getFccBranchCode().then((data) => {
      if (data) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_FCC_BRANCH_CODE_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_FCC_BRANCH_CODE_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_FCC_BRANCH_CODE_FAILURE, error };
  }
} 

function updateFccBranchCode(branchCode) {
  return (dispatch) => {
    dispatch(request());
    TenantService.updateFccBranchCode(branchCode).then((data) => {
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.UPDATE_FCC_BRANCH_CODE_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.UPDATE_FCC_BRANCH_CODE_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.UPDATE_FCC_BRANCH_CODE_FAILURE, error };
  }
} 

function getFlowLogs(flowId) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getFlowLogs(flowId).then((data) => {
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_FLOW_LOGS_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_FLOW_LOGS_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_FLOW_LOGS_FAILURE, error };
  }
}

function downloadPurchageTable(payload) {
  return (dispatch) => {
    dispatch(request());
    TenantService.downloadPurchageTable(payload).then((data) => {
      if (data) {
        if(data?.data){
          window.open(data?.data, "_blank")
        }
        dispatch(success(data));

      } else {
        dispatch(failure(data));
        if (data.message) {
          dispatch(alertActions.error(data.message?.toString()));
        } else {
          dispatch(alertActions.error("Something went wrong"));
        }
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_PURCHASE_REQUEST };
  }
  function success(data) {
    return { type: tenantConstants.GET_PURCHASE_SUCCESS, data };
  }
  function failure(error) {
    return { type: tenantConstants.GET_PURCHASE_FAILURE, error };
  }
} 
function showPopup(value){
 return(dispatch)=>{
  localStorage.setItem("showPopup", value);
  dispatch({type:tenantConstants.SAVE_SHOWPOPUP,data:value})

 }
}
function showPropertyPopup(value){
  return(dispatch)=>{
   localStorage.setItem("showPropertyPopup", value);
   dispatch({type:tenantConstants.SAVE_PROPERTY_SHOWPOPUP,data:value})
 
  }
 }