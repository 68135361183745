import React from 'react';
import styled from 'styled-components';
import getPlatformType from '../../../../helpers/helpers';
import { CardBox } from '../atoms/card';
import variables from '../atoms/variables.json';
import { TextInput } from './../atoms/textinput';
let layoutType = getPlatformType();

// const Wraper = styled.div`
//   box-shadow: ${props => (props.hasShadow ? `0 0 6px 4px ${variables.lightGrey}` : "none")};
//   border-radius: ${props => props.hasBorderRadius ? `16px`: `0`};
//   padding: 18px;
//   background-color: ${variables.white}
// `;
let CardWrapper, TlyfeButtonWrapper, FormContainer;
const RowContainer = styled.div`
  display: flex;
  margin: 0% 0% 2% 0%;
  flex-wrap: wrap;
`;
const TouchableArea = styled.div`
  cursor: pointer;
`;
const TextBox = styled.div`
  border: 2px solid #ebebeb;
  border-radius: 6px;
  padding: 15px;
  margin: 15px;
  height: 120px;
  overflow-x: auto;
`;

const TlyfeSelector = styled.div`
  display: flex;
  margin-top: 5%;
  flex-direction: ${(props) => (props?.isColumn ? `column` : `row`)};
`;

const LineSeperator = styled.div`
  width: 90%;
  display: flex;
  margin: 5% 0px;
  border-bottom: 1px solid #bbc3d9;
`;

const Heading = styled.p`
  font-weight: 700;
  font-size: ${(props) => props.fontSize || 16}px;
  font-color: ${variables.black};
  line-height: 22px;
  font-family: 'lato';
`;

const ProfilesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
`;
const ProfilesButtonBox = styled.div`
  display: flex;
  flex: 1;
  font-weight: 400;
  margin-top: 15px;
`;
const ViewButton = styled.div`
  border: none;
  background: none;
  color: ${variables.purple1};
  font-size: 13px;
  letter-spacing: 0.08px;
  line-height: 17px;
  flex: 1;
  margin-left: 25px;
  margin-top: 10px;
  font-family: 'lato';
`;

const RemoveButton = styled.div`
  border: none;
  background: none;
  color: ${variables.orange};
  font-size: 13px;
  letter-spacing: 0.08px;
  line-height: 17px;
  flex: 1;
  font-family: 'lato';
  text-align: center;
`;

const PersonName = styled.span`
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 19px;
  margin-left: 5px;
  font-weight: 400;
  font-family: 'lato';
`;

const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const CardSpacedFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SubHeading = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.color ? `${props.color}` : `${variables.black}`)};
  line-height: 20px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 15)}px;
  font-family: 'lato';
`;

export const CheckBoxWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const ButtonRowContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
export const CardBoxWithScroll = styled(CardBox)`
  overflow-y: auto;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width || '100%'}px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;
export const MarginTop = styled.div`
  margin-top: ${(props) => props.marginTop || 5}px;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  right: 5%;
`;
export const CostTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  background: linear-gradient(to right, #365ff2 0%, #7002bc 40%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 2px solid #ebebeb;
  border-radius: 6px;
  padding: 5px;
  font-family: 'lato';
  height: 30px;
`;

export const InputNumber = styled(TextInput)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;
if (layoutType == 'web') {
  TlyfeButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
  `;
  CardWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-left: 4%;
    flex-direction: column;
    min-height: 65vh;
  `;
  FormContainer = styled.div`
    display: flex;
    flex: 0.5;
    flex-direction: column;
    margin-right: 2%;
  `;
} else if (layoutType == 'phone') {
  CardWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 65vh;
  `;
  TlyfeButtonWrapper = styled.div`
    justify-content: flex-end;
    margin-left: auto;
  `;
  FormContainer = styled.div`
    width: 100%;
  `;
} else {
  CardWrapper = styled.div`
    display: flex;
    width: 70%;
    margin-left: 4%;
    flex-direction: column;
    min-height: 65vh;
  `;
  TlyfeButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
  `;
  FormContainer = styled.div`
    display: flex;
    flex: 0.5;
    flex-direction: column;
    margin-right: 2%;
  `;
}
export const ButtonWrapper = styled(TlyfeButtonWrapper)`
  margin: 20px;
`;

export {
  CardWrapper,
  TlyfeSelector,
  LineSeperator,
  FormContainer,
  RowContainer,
  Heading,
  ProfilesList,
  ProfilesButtonBox,
  ViewButton,
  RemoveButton,
  PersonName,
  CardFooter,
  TextBox,
  TouchableArea,
  CardSpacedFooter,
  TlyfeButtonWrapper,
};
