import DoneIcon from '@material-ui/icons/Done';
import React, { useRef } from 'react';
import styled from 'styled-components';
import variables from '../variables.json';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../../components/loader';
import { tenantActions } from '../../../redux/tenant.actions';

const SolidButton = styled.div`
  display: flex;
  border-radius: 18px;
  width: 156px;
  height: 36px;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props?.color ? props.color : variables.purple1)};
  color: white;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  font-family: 'lato';
`;
let StripeButton = styled.div`
  display: flex;
  border-radius: 18px;
  width: 156px;
  height: 36px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: black;
  border: 2px solid ${variables.purple1};
  font-size: 12px;
  cursor: pointer;
  font-family: 'lato';
`;
let SolidButtonDisabled = styled.div`
  display: flex;
  border-radius: 18px;
  width: 156px;
  height: 36px;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props?.color ? props.color : variables.purple1)};
  color: white;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  font-family: 'lato';
`;
const CustomButton = styled.div`
  display: flex;
  border-radius: 18px;
  width: ${(props) => props.width || 156}px;
  height: 36px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props?.textColor ? props.textColor : props?.color)};
  border: ${(props) => `2px solid ${props.color}`};
  font-size: 12px;
  cursor: pointer;
  font-family: 'lato';
`;
const CustomSolidButton = styled.div`
  display: flex;
  border-radius: 18px;
  width: ${(props) => props.width || 156}px;
  height: 36px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: ${(props) => props?.color};
  color: black;
  border: ${(props) => `2px solid ${props.textColor}`};
  font-size: 12px;
  cursor: pointer;
  font-family: 'lato';
`;

export const Button = (props) => {
  const dispatch = useDispatch();
  const isImageLoading = useSelector((state) => state?.general?.isImageLoading);

  const inputFile = useRef();
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };
  if (props.type == 'solid') {
    if (props.noClick != 'noClick') return <SolidButton {...props}>{props.title}</SolidButton>;
    else return <SolidButtonDisabled {...props}>{props.title}</SolidButtonDisabled>;
  } else if (props.type == 'stripe') {
    return <StripeButton {...props}>{props.title}</StripeButton>;
  } else if (props.type == 'Upload') {
    return (
      <React.Fragment>
        {isImageLoading && <Loader />}
        <input
          onChange={(e) => {
            let data = e.target.files;
            if (data && data.length) {
              let arr = [];
              arr.push(data[0]);
              var newImages = arr.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));
              dispatch(
                tenantActions.uploadFiles(newImages, 'certificate-doc', (urlData) => {
                  props.onClick(urlData);
                  e = null;
                })
              );
            }
          }}
          style={{ display: 'none' }}
          id="select-file"
          type="file"
          name="fileUpload"
          ref={inputFile}
        />

        <StripeButton style={{ color: variables.purple1 }} {...props} onClick={onButtonClick}>
          {props.title}
        </StripeButton>
      </React.Fragment>
    );
  } else if (props.type == 'customsolid') {
    return (
      <CustomSolidButton color={props?.color} textColor={props?.textColor} {...props}>
        {props.title}
      </CustomSolidButton>
    );
  } else {
    return (
      <CustomButton color={props?.color} textColor={props?.textColor} {...props}>
        {props.title}
      </CustomButton>
    );
  }
};
