import React, { useEffect, useState } from 'react';
import { View, Text, Button, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import AntDesign from '@expo/vector-icons/AntDesign';
import moment from 'moment';
import SendAgg from './modals/sendAgg';
const users = [
  { name: 'Martin King', role: 'Tenant', status: 'Not Sent' },
  { name: 'Miguel Mastero', role: 'Tenant', status: 'Not Sent' },
  { name: 'Shahad Ahmed Choudhury', role: 'Tenant', status: 'UNSIGNED', sentOn: '06/03/2025' },
  { name: 'Robby Ramsdale', role: 'Tenant', status: 'Not Sent' },
  { name: 'Mikky Art', role: 'Landlord', status: 'Not Sent' },
  { name: 'asd asd', role: 'Guarantor', status: 'Not Sent' },
];

const AgreementComponent = (props) => {
  const [stakeData, setStakeData] = React.useState([]);
  const [signAstStatus, setSignAstStatus] = useState([]);
  const [envid, setEnvid] = useState(null);
  console.log("AgreementComponent", props)
  const [showModalRef, setShowModalRef] = React.useState({
    show: false,
    data: null
});
  const getSignAstStatus1 = (email) => {
    const user = signAstStatus.find((item) => item?.email == email)
    if (user) {
      return user?.sign_status;
    } else {
      return 'UNSIGNED';
    }
  };

  const getSignAstStatus2 = (email) => {
    const user = signAstStatus.find((item) => item?.email == email)
    if (user) {
      return formatDate(user?.signed_at);
    } else {
      return '';
    }
  };

  const isSignedByAllParties = () => {
    if (signAstStatus.length == 0) {

      return false
    } else {
      const totalStakeHolders = signAstStatus.length
      const signedStakedHolders = signAstStatus.filter((item) => item?.sign_status == 'SIGNED').length

      return signedStakedHolders == totalStakeHolders
    }
  }
  useEffect(() => {

    setStakeData(props?.stakeData);
    setSignAstStatus(props.signAstStatus)
    setEnvid(props?.envid)
  }, [props?.stakeData, props.signAstStatus, props.envid]);
  return (
    <ScrollView style={styles.container}>
      <View style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', marginBottom: 20 }}>
        <Text style={styles.header}>Send Agreement</Text>
        <TouchableOpacity 
        onPress={()=>{
          setShowModalRef({show:true})}}
        style={styles.reRequestButton}>
          <Text style={styles.reRequestButtonText}>RE-REQUEST ESIGNATURE</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.noticeBox}>
        <AntDesign name="infocirlce" size={24} color="red" />
        <Text style={styles.noticeText}>

          Please note: Tenants must have their ID verified to complete eSign process</Text>
      </View>
      <Text style={styles.subHeader}>All parties can digitally sign their contract via a legally binding eSignature.</Text>
      <View style={styles.tableHeader}>
        <View style={{ flex: 3, justifyContent: 'center', alignItems: 'flex-start' }}>
          <Text style={styles.columnHeader}>User</Text>
        </View>
        <View style={{ flex: 2, justifyContent: 'center', alignItems: 'flex-start' }}>
          <Text style={styles.columnHeader}>Sent On</Text>
        </View>
        <View style={{ flex: 2.5, justifyContent: 'center', alignItems: 'flex-start' }}>
          <Text style={styles.columnHeader}>Status</Text>
        </View>
        <View style={{ flex: 2.5, justifyContent: 'center', alignItems: 'flex-end' }}>
          <Text style={styles.columnHeader}>Action</Text>
        </View>

      </View>


      {stakeData?.tenants ? stakeData?.tenants.map((tenant, index) => (
        <View key={index} style={styles.tableRow}>
          <View style={{ flex: 3, justifyContent: 'center', alignItems: 'flex-start' }}>

            <Text style={styles.cell}>{tenant?.name} ({"tenant"})</Text>
          </View>
          <View style={{ flex: 2, justifyContent: 'center', alignItems: 'flex-start' }}>

            <Text style={styles.cell}>{tenant?.envtoken ? moment(tenant?.contract_sent_at).format('MM/DD/YYYY') : '-'}</Text>
          </View>
          <View style={{ flex: 2.5, justifyContent: 'center', alignItems: 'flex-start' }}>

            {tenant.envtoken ?
              <View style={[styles.status, getSignAstStatus1(tenant?.email) === 'UNSIGNED' ? styles.unsigned : styles.notSent]}>

                <Text style={getSignAstStatus1(tenant?.email) === 'UNSIGNED' ? styles.unsigned : styles.notSent}>{getSignAstStatus1(tenant?.email)}</Text>
              </View>
              :
              <View style={[styles.status, styles.notSent]}>
                <Text style={styles.notSent}>Not send</Text>
              </View>



            }
          </View>
          <View style={{ flex: 2.5, justifyContent: 'center', alignItems: 'flex-end' }}>

            {tenant.envtoken ? getSignAstStatus1(tenant?.email) == 'UNSIGNED' ?
              <TouchableOpacity style={styles.actionButton}>
                <AntDesign name="mail" size={24} color="black" />
              </TouchableOpacity> :
              null : null}
          </View>

        </View>
      )) : null}

      {stakeData?.guarantors ? stakeData?.guarantors.map((tenant, index) => (
        <View key={index} style={styles.tableRow}>
          <View style={{ flex: 3, justifyContent: 'center', alignItems: 'flex-start' }}>

            <Text style={styles.cell}>{tenant?.name} ({"guarantors"})</Text>
          </View>

          <View style={{ flex: 2, justifyContent: 'center', alignItems: 'flex-start' }}>

            <Text style={styles.cell}>{tenant?.envtoken ? moment(tenant?.contract_sent_at).format('MM/DD/YYYY') : '-'}</Text>
          </View>
          <View style={{ flex: 2.5, justifyContent: 'center', alignItems: 'flex-start' }}>

            {tenant.envtoken ?
              <View style={[styles.status, getSignAstStatus1(tenant?.email) === 'UNSIGNED' ? styles.unsigned : styles.notSent]}>

                <Text style={getSignAstStatus1(tenant?.email) === 'UNSIGNED' ? styles.unsigned : styles.notSent}>{getSignAstStatus1(tenant?.email)}</Text>
              </View>
              :
              <View style={[styles.status, styles.notSent]}>
                <Text style={styles.notSent}>Not send</Text>
              </View>



            }
          </View>
          <View style={{ flex: 2.5, justifyContent: 'center', alignItems: 'flex-end' }}>

            {tenant.envtoken ? getSignAstStatus1(tenant?.email) == 'UNSIGNED' ?
              <TouchableOpacity style={styles.actionButton}>
                <AntDesign name="mail" size={24} color="black" />
              </TouchableOpacity> :
              null : null}
          </View>

        </View>
      )) : null}

      {stakeData?.landlords ? stakeData?.landlords.map((tenant, index) => (
        <View key={index} style={styles.tableRow}>
          <View style={{ flex: 3, justifyContent: 'center', alignItems: 'flex-start' }}>

            <Text style={styles.cell}>{tenant?.name} ({"landlords"})</Text>
          </View>
          <View style={{ flex: 2, justifyContent: 'center', alignItems: 'flex-start' }}>

            <Text style={styles.cell}>{tenant?.envtoken ? moment(tenant?.contract_sent_at).format('MM/DD/YYYY') : '-'}</Text>
          </View>

          <View style={{ flex: 2.5, justifyContent: 'center', alignItems: 'flex-start' }}>

            {tenant.envtoken ?
              <View style={[styles.status, getSignAstStatus1(tenant?.email) === 'UNSIGNED' ? styles.unsigned : styles.notSent]}>

                <Text style={getSignAstStatus1(tenant?.email) === 'UNSIGNED' ? styles.unsigned : styles.notSent}>{getSignAstStatus1(tenant?.email)}</Text>
              </View>
              :
              <View style={[styles.status, styles.notSent]}>
                <Text style={styles.notSent}>Not send</Text>
              </View>
            }
          </View>

          <View style={{ flex: 2.5, justifyContent: 'center', alignItems: 'flex-end' }}>
            {tenant.envtoken ? getSignAstStatus1(tenant?.email) == 'UNSIGNED' ?
              <TouchableOpacity style={styles.actionButton}>
                <AntDesign name="mail" size={24} color="black" />
              </TouchableOpacity> :
              null : null}
          </View>
        </View>
      )) : null}
      <View style={styles.downloadBox}>
        <Text>You can download signed AST Agreement once all parties sign</Text>
      </View>
      <SendAgg
      stakeData={stakeData}
   showModal={showModalRef.show}
                onClose={() => { setShowModalRef({ show: false, data: null }) }}
                data={showModalRef.data}
                handleSubmit={(DATA) => {
                    // let payload =
                    // {
                    //    ...showModalRef.data,
                    //     "flow_id": flowId,
                    //     "open_flow_sub_tenant_id": showModalGua?.data?.open_flow_sub_tenant_id,
                    //    "price":DATA.cost
                    // }
                    // dispatch(tenantActions.fullReferencing(payload, (data) => {
                    //     if(data.status){
                    //         fetchFlows()
                    //         showSuccesToast('Gurantor added successfully!');
                    //         setShowModalRef({ show: false, data: null })
                    //     }else{
                    //         showErrorToast(data?.message?data.message: 'Please try again!');
                    //         setShowModalRef({ show: false, data: null })
                    //     }
                        

                    // }))
                }}
  />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: { padding: 20, backgroundColor: '#fff' },
  header: { fontSize: 20, fontWeight: '600', color: '#6c4eb1' },
  noticeBox: { backgroundColor: '#fde8e8', padding: 15, borderRadius: 5, marginBottom: 15, flexDirection: 'row', alignItems: 'center' },
  noticeText: { color: '#d9534f', fontWeight: '500', marginLeft: 10 },
  subHeader: { fontSize: 16, marginBottom: 15 },
  tableHeader: { flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10, borderBottomWidth: 1, borderBottomColor: '#ccc' },
  columnHeader: { fontWeight: '600', flex: 1 },
  tableRow: { flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10, borderBottomWidth: 1, borderBottomColor: '#eee' },
  cell: { flex: 1 },
  status: { paddingVertical: 5, paddingHorizontal: 10, borderRadius: 20, textAlign: 'center', justifyContent: 'center', alignItems: "center" },
  notSent: { backgroundColor: '#ffe5e5', color: '#d9534f' },
  unsigned: { backgroundColor: '#f3e8ff', color: '#6c4eb1' },
  actionButton: { padding: 5 },
  reRequestButton: { backgroundColor: '#49b3c8', padding: 15, borderRadius: 5, width: 300, alignSelf: 'flex-end', justifyContent: 'center', alignItems: 'center' },
  reRequestButtonText: { color: '#fff', fontWeight: '600' },
  downloadBox: { backgroundColor: '#f4f4f4', padding: 15, borderRadius: 5, marginTop: 20, },
});

export default AgreementComponent;
