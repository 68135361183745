
import { authHeader } from "./authHeaders";
import Constant from "./constant";
import { handleApiResponse } from "./handleApiResponse";

const handleResponse = handleApiResponse.handleResponse;

const services = { };
const getIntFlowId=(id)=>{
if(id){
  return parseInt(id)
}else{
  return(0)
}

}
services.getUserProfile = ()=> {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${Constant.API_URL}/profile/1`, requestOptions).then(
    handleResponse
  );
}
services.getSignedObj= (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${Constant.API_URL}/openflow/signAstStatus?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};
services.getTenantByTlyfeId= (text) => {
  console.log("text",text)
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${Constant.API_URL}/openflow/tenant/search?search=${text}`,
    requestOptions
  ).then(handleResponse);
};


services.downloadTenancyDocumentPack = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${Constant.API_URL}/openflow/downloadtenacypack/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.getDocuments = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${Constant.API_URL}/openflow/certificates/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.getHoldingDeposit = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${Constant.API_URL}/openflow/deposits/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.getBranchBankAccounts = () => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${Constant.API_URL}/branchBankAccounts`,
    requestOptions
  ).then(handleResponse);
};

services.getCompanyBranches = () => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${Constant.API_URL}/company/branch?withOutPagination=true`,
    requestOptions
  ).then(handleResponse);
};

services.saveBankAccount = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(
    `${Constant.API_URL}/openflow/accounts`,
    requestOptions
  ).then(handleResponse);
}

services.getCommonClauses = () => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${Constant.API_URL}/openflow/common_clauses`,
    requestOptions
  ).then(handleResponse);
};

services.getArchivedFlows  = (pageNO) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(`${Constant.API_URL}/openflow/flow/archived?pageNo=${pageNO}`, requestOptions).then(
    handleResponse
  );
};
services.getCompletedFlows  = (pageNO) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(`${Constant.API_URL}/openflow/flow/completed?pageNo=${pageNO}`, requestOptions).then(
    handleResponse
  );
};

services.getOnboardedTeanants = (pageNO) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(`${Constant.API_URL}/openflow/flows?pageNo=${pageNO}`, requestOptions).then(
    handleResponse
  );
};

services.getTeanants = (ID) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${Constant.API_URL}/openflow/tenant/?flow_id=${getIntFlowId(ID)}`,
    requestOptions
  ).then(handleResponse);
};

services.getFlowInfo = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${Constant.API_URL}/openflow/flow/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.getRentalOffers = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${Constant.API_URL}/openflow/rentaloffer/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.getIdentityData = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${Constant.API_URL}/openflow/verificationreferencing/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.saveIdentityData = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/verificationreferencing/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}
services.saveGurantorInfo = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/add-guarantor/?flow_id=${getIntFlowId(payload.flow_id)}`,
    requestOptions
  ).then(handleResponse);
}

services.addRentalOffer = (payload, id, url) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  let urlData = "/openflow/rental-offer";

  if (url) {
    urlData = url;
  }
  return fetch(
    `${Constant.API_URL}${urlData}?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.getMoveIn = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${Constant.API_URL}/openflow/movein/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.addMoveIn = (payload) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.MOCKUP_BASEURL}/openflow/movein`,
    requestOptions
  ).then(handleResponse);
}

services.getTenancyInfo = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() },
  };
  return fetch(
    `${Constant.API_URL}/openflow/tenancyinformation/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.getNHA = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/tenancyinformation/getNHA/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.archieveFlow = (id) => {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
  };

  return fetch(
    `${Constant.API_URL}/openflow/flow/archive/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.unArchieveFlow = (id) => {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
  };

  return fetch(
    `${Constant.API_URL}/openflow/flow/unarchive/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.addFlowToProperty = (id) => {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
  };

  return fetch(
    `${Constant.API_URL}/openflow/flow/add-to-property/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.deleteFlow  = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: {...authHeader(), "Content-Type": "application/json"},
  };

  return fetch(
    `${Constant.API_URL}/openflow/flow/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};
services.deleteRtrDoc  = (payload) => {
  const requestOptions = {
    method: "DELETE",
    headers: {...authHeader(), "Content-Type": "application/json"},
  };

  return fetch(
    `${Constant.API_URL}/openflow/deleteVerificationDocument/?flow_id=${payload.flow_id}&document_url=${payload.document_url}&type_of_document=${payload.type_of_document}&open_flow_sub_tenant_id=${payload.open_flow_sub_tenant_id}`,
    requestOptions
  ).then(handleResponse);
};
services.deleteGau  = (payload) => {
  const requestOptions = {
    method: "DELETE",
    headers: {...authHeader(), "Content-Type": "application/json"},
  };

  return fetch(
    `${Constant.API_URL}/openflow/remove-guarantor/?flow_id=${payload.flow_id}&openflow_guarantor_id=${payload.openflow_guarantor_id}`,
    requestOptions
  ).then(handleResponse);
};

services.rtrReminder  = (payload,id) => {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify(payload),
  };

  return fetch(
    `${Constant.API_URL}/openflow/right_to_rent/reminder?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.deleteAST = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: {...authHeader(), "Content-Type": "application/json"},
  };

  return fetch(
    `${Constant.API_URL}/openflow/tenancyinformation/AST/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};
services.deleteBespokeContract = (id,URL) => {
  const requestOptions = {
    method: "DELETE",
    headers: {...authHeader(), "Content-Type": "application/json"},
  };

  return fetch(
    `${Constant.API_URL}/openflow/manualAst?flow_id=${id}&bespoke_contract_url=${URL}`,
    requestOptions
  ).then(handleResponse);
};

services.uploadManualAST = (payload,id) => {
  const requestOptions = {
    method: "POST",
    headers: {...authHeader(), "Content-Type": "application/json"},
    body: JSON.stringify(payload),
  };

  return fetch(
    `${Constant.API_URL}/openflow/uploadManualAst/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.deleteNHA = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: {...authHeader(), "Content-Type": "application/json"},
  };
  return fetch(
    `${Constant.API_URL}/openflow/tenancyinformation/NHA/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.CheckForLisence = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${Constant.API_URL}/openflow/licencing/councils/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.downloadSignedContract = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${Constant.API_URL}/openflow/signedAgreement?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.emailContract = (id,data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(
    `${Constant.API_URL}/openflow/email-contract?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.getAST = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/tenancyinformation/generateContract?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.getOpenflowPurchases = (isCommission) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${Constant.API_URL}/openflow/product/purchases?isCommission=${isCommission}`,
    requestOptions
  ).then(handleResponse);
};

services.getOpenflowInvoices = (isCommission) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${Constant.API_URL}/agent/get_invoice_list`,
    requestOptions
  ).then(handleResponse);
};

services.getProtectData = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${Constant.API_URL}/openflow/protectandsetup/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.buyRentalWarranty = (flowId, isCommission,data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({isCommission,data}),
  };
  return fetch(
    `${Constant.API_URL}/buy-rental-warranty/?flow_id=${getIntFlowId(flowId)}`,
    requestOptions
  ).then(handleResponse);
};

services.getPropertyDetails = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${Constant.API_URL}/openflow/property/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
};

services.checkWarrantyAvailable = (flowId) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({}),
  };
  return fetch(
    `${Constant.API_URL}/check-warranty-availibility?flow_id=${getIntFlowId(flowId)}`,
    requestOptions
  ).then(handleResponse);
};

services.buyFlowPackage = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/flows/buy`,
    requestOptions
  ).then(handleResponse);
}

services.getOpenFlowDashboardData = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${Constant.API_URL}/openflow/flows/summary`,
    requestOptions
  ).then(handleResponse);
}

services.signUpOpenflow = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/flow/signup`,
    requestOptions
  ).then(handleResponse);

};

services.getFlowLogs = (flowId) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(`${Constant.API_URL}/openflow/logs?flow_id=${getIntFlowId(flowId)}`, requestOptions).then(
    handleResponse
  );  
}

services.signInToFcc = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/check-digital-ref-availability`,
    requestOptions
  ).then(handleResponse);
};

services.signInToDD = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/newDirectDebit`,
    requestOptions
  ).then(handleResponse);
};

services.getFlowPackages = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${Constant.API_URL}/openflow/flow/packages`,
    requestOptions
  ).then(handleResponse);
};

services.saveProtectData = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/protectandsetup/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.downloadPurchageTable = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/purchases/pdf`,
    requestOptions
  ).then(handleResponse);
}

services.getTdsData = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${Constant.API_URL}/openflow/tds`, requestOptions).then(
    handleResponse
  );
};

services.fullReferencing = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),  };
  return fetch(
    `${Constant.API_URL}/start-full-reference/?flow_id=${getIntFlowId(data.flow_id)}`,
    requestOptions
  ).then(handleResponse);
}

services.getRentalCollectionData = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${Constant.API_URL}/openflow/protectandsetup/rentcollect?flow_id=${getIntFlowId(id)}`, requestOptions).then(
    handleResponse
  );
};

services.saveRentalCollectionData = (id,data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(
    `${Constant.API_URL}/start-rent-collection?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}
services.postRtrCode = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(
    `${Constant.API_URL}/openflow/approve-rtr-code`,
    requestOptions
  ).then(handleResponse);
}
services.requestdigital = (data,id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({tlife:data}),
  };
  return fetch(
    `${Constant.API_URL}/openflow/verification/requestdigital/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.addTenancyInfo = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/tenancyinformation/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.saveMoveinData = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/editMoveInAccount?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.uploadInventoryDocuments = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/uploadInventoryDocuments?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.arrangeCheckIn = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/arrangeCheckIn/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.sendContractDigitally = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/signAstEmbedded/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}
services.sendBespokeContract = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/email-bespoke-contract/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}
services.saveSlectedProperty = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ property_id: payload }),
  };
  return fetch(`${Constant.API_URL}/openflow/flow`, requestOptions).then(
    handleResponse
  );
}
services.validateEmail = (EMAIL) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${Constant.API_URL}/openflow/tenant/is_available?email=${EMAIL}`,
    requestOptions
  ).then(handleResponse);
}
services.saveTeanantDetails = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/tenant?flow_id=${getIntFlowId(payload.flow_id)}`,
    requestOptions
  ).then(handleResponse);
}

services.requestrighttorent = (ID) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${Constant.API_URL}/openflow/request-right-to-rent?app_user_id=${getIntFlowId(ID)}`,
    requestOptions
  ).then(handleResponse);
}

services.toggleComplete = (URL) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({}),
  };
  return fetch(`${Constant.API_URL}${URL}`, requestOptions).then(
    handleResponse
  );
}

services.confirmDeposit = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/confirmHoldingDeposit?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.revokeConfirmationDeposit = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/requestHoldingDeposit?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.confirmMovin = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/confirmMoveInMonies?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.requestMoveInMonies = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/requestMoveInMonies?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.requestHoldingDeposit = (payload, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/deposit/requestdeposit/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.saveCer = (data, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(
    `${Constant.API_URL}/openflow/certificates/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.buyCer = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${Constant.API_URL}/openflow/certificates/buy_requests?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.delCer = (data, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(
    `${Constant.API_URL}/openflow/certificates/?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.saveHoldingDeposit = (payload, id, isRequest) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  let URL = `${Constant.API_URL}/openflow/deposits/?flow_id=${getIntFlowId(id)}`;
  if (isRequest) {
    URL = `${Constant.API_URL}/openflow/deposits/send-request/?flow_id=${getIntFlowId(id)}`;
  }
  return fetch(URL, requestOptions).then(handleResponse);
}

services.getVerificationReferencing = (payload,id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${Constant.API_URL}/openflow/uploadVerificationDocument/?flow_id=${getIntFlowId(payload.flow_id)}`,
    requestOptions
  ).then(handleResponse);
}

services.addNRemovedLandlord = (data, id) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ ...data ,flow_id:id}),
  };
  return fetch(
    `${Constant.API_URL}/openflow/landlords?flow_id=${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}

services.openflowBranchNotification = (id, data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data ),
  };
  return fetch(
    `${Constant.API_URL}/branch/${getIntFlowId(id)}`,
    requestOptions
  ).then(handleResponse);
}
services.getFccBranchCode = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${Constant.API_URL}/openflow/settings/getFccBranchCode`,
    requestOptions
  ).then(handleResponse);
}
function upload(files, type) {
  var formData = new FormData();
  formData.append("type", type);
  files.forEach((item) => {
    formData.append("files", item);
  });
  
  const requestOptions = {
    method: "POST",
    body: formData
  };
  return fetch(`https://assets.openbrix.co.uk/uploads`, requestOptions).then((response) => {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      let data_item = { data: null, failed: null };
      if (data.status) {
        data_item.data = data.data.filter((item) => item.status).map((item) => item.openbrixName);
      } else {
        if (data.message === "Not all files were uploaded") {
          data_item.failed = data.data.filter((item) => !item.status).map((item) => {
            return {
              name: item.name,
              message: item.message,
            };
          });
          data_item.data = data.data.filter((item) => item.status).map((item) => item.openbrixName);
        }
      }
      return data_item;
    });
  });  
}
services.uploadFiles = (files, type)=> {
  return upload(files, type)
}
services.updateFccBranchCode = (branchCode) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({fccBranchCode: branchCode}),
  };
  return fetch(
    `${Constant.API_URL}/openflow/settings/updateFccBranchCode`,
    requestOptions
  ).then(handleResponse);
}

export const TenantService = services;