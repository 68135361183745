const STRINGS = {
    OPEN_FLOW_INTRO: "OpenFlow Intro",
    OPEN_FLOW_T_AND_C: "OpenFlow Terms and Conditions",
    OPEN_FLOW_PRICING: "OpenFlow Pricing",
    SIGN_UP: "SignUp",
    AGREE_TERMS_AND_CONDITIONS: "Agree Terms and Conditions",
    WELCOME_TO_OPEN_FLO: "Welcome to OpenFlo",
    FLO_PARTNERS: "Flo Partners",
    EDIT:"EDIT",
    CHANGE_SUPPLIER: "Change Supplier", 
    OPENFLOW_TITLES: ["Mr", "Mrs", "Miss", "Ms", "Mx", "N/A"]
  };
  
  export default STRINGS;