import { Modal } from '@material-ui/core';
import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import UserCard from '../userCard';
import { ScrollView } from 'react-native-gesture-handler';

const { width } = Dimensions.get('window');

const  SendAgg = (props) => {
    const {
        onClose,
        showModal,
        data,
        stakeData
      } = props;
      console.log("datadata",data)
  return (
    <Modal
      open={showModal ? showModal : false}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
    <View style={styles.container}>
      <View style={styles.popup}>
        <Text style={styles.title}>Agreement Signitories</Text>
        <ScrollView style={{height:500}}>
        <UserCard
          stakeData={stakeData}
        />
        </ScrollView>
      </View>
    </View>
   
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  popup: {
    width:"80%",
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 16,
    color: '#666',
    marginBottom: 20,
  },
  infoBox: {
    backgroundColor: '#E0F7FA',
    padding: 15,
    borderRadius: 8,
    marginBottom: 20,
  },
  infoText: {
    fontSize: 14,
    color: '#00796B',
  },
  detail: {
    fontSize: 16,
    marginBottom: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  okButton: {
    backgroundColor: '#9C27B0',
    paddingVertical: 12,
    borderRadius: 8,
    alignItems: 'center',
    marginTop: 20,
  },
  okButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
   buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelButton: {
    backgroundColor: '#f8d7da',
    padding: 10,
    borderRadius: 5,
    flex:0.4,
     justifyContent:'center',
    alignItems:"center"
  },
  saveButton: {
    backgroundColor: '#6c63ff',
    padding: 10,
    borderRadius: 5,
    flex:0.4,
    justifyContent:'center',
    alignItems:"center"
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
});

export default SendAgg;
