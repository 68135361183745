import React, { useState } from 'react';
import { View } from 'react-native';
import { Container, InputWrapper, TextInputWrapper } from './TextInputComponent.style';
import PropTypes from 'prop-types';
import Text from '../text/TextComponent';
import DatePickerComponent from '../datepicker/DatePickerComponent';
import { Ionicons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
const TextInputComponent = (props) => {
  const {
    title,
    onChangeText,
    error,
    colored,
    fontSize,
    height,
    password,
    placeholder,
    value,
    disabled,
    isDate,
    date,
    onlyCalender,
    showMonths,
    multiline,
    renderRightComponent,
    allowDecimal,
    maxLength,
    isAmount,
    dateBackgroundColor,
    amountWidth
  } = props;

  const [state, setState] = useState({
    passwordVisible: false,
  });

  const onChangeTextCheck = (value) => {
    if (props?.isNumeric) {
      onChangeText(value.replace(/[^0-9]/g, ''));
    } else if(props?.isAmount || allowDecimal){
      const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/)
      if(validated)
      onChangeText(value);

    }
    
    // else if (props?.isAmount) {
    //   const removedDecimal = Math.trunc( value );
    //   onChangeText(removedDecimal.replace(/[^0-9]/g, ''));
    // }
     else {
      onChangeText(value);
    }
  };

  const setDecimal = (str) => {
    if (str && isAmount) {
      return `${str}.00`;
    }
    return str;
  };

  return (
    <Container style={amountWidth?amountWidth:{}}>
      <If condition={title}>
        <Text fontWeight={'600'} marginBottom={10} lineHeight={22} fontSize={fontSize ? fontSize : 16}>
          {title}
        </Text>
      </If>

      <Choose>
        <When condition={isDate}>
          <DatePickerComponent
            value={date}
            isDisable={disabled}
            showMonths={showMonths}
            onlyCalender={onlyCalender}
            backgroundColor={dateBackgroundColor}
            // placeholder={title}
            onUpdateInput={(date) => {
              onChangeText(date);
            }}
            //disabled={!getIsEditable()}
            minDate={props?.minDate}
          />
        </When>
        <Otherwise>
          <InputWrapper>
            <If condition={isAmount}>
              <View
                style={{
                  backgroundColor: '#B8B8B8',
                  justifyContent: 'center',
                  width: 50,
                  alignItems: 'center',
                  borderBottomLeftRadius: 5,
                  borderTopLeftRadius: 5,
                  marginBottom: 10,
                  height: props?.height ? props.height + 'px' : '50px',
                }}
              >
                <Text bold fontSize={16}>
                  £
                </Text>
              </View>
            </If>
            <TextInputWrapper
              isAmount={isAmount}
              placeholder={placeholder}
              secureTextEntry={password && !state?.passwordVisible}
              multiline={multiline ? true : false}
              height={height}
              value={value}
              onChangeText={onChangeTextCheck}
              colored={colored}
              error={error}
              editable={!disabled}
              selectTextOnFocus={!disabled}
              maxLength={maxLength ? maxLength : 50}
            />
          </InputWrapper>
        </Otherwise>
      </Choose>
      {renderRightComponent ? (
        <View
          style={{
            position: 'absolute',
            right: '3%',
            top: layoutType == 'phone' ? '50%' : '53%',
          }}
        >
          <Ionicons
            onPress={() =>
              setState({
                passwordVisible: !state.passwordVisible,
              })
            }
            name={state?.passwordVisible ? 'ios-eye' : 'ios-eye-off'}
            size={24}
            color="lightgrey"
          />
        </View>
      ) : null}
      <If condition={error}>
        <Text
          style={{ position: 'absolute', right: 0, width: layoutType === 'phone' ? 118 : 300 }}
          fontSize={12}
          color={'red'}
          align={'right'}
        >
          {error}
        </Text>
      </If>
    </Container>
  );
};

TextInputComponent.propTypes = {
  title: PropTypes.string,
  onChangeText: PropTypes.func,
  error: PropTypes.string,
  colored: PropTypes.bool,
  fontSize: PropTypes.string,
  placeholder: PropTypes.string,
  height: PropTypes.number,
  password: PropTypes.bool,
};

TextInputComponent.defaultProps = {
  disabled: false
};

export default TextInputComponent;
//Eg.
{
  /* <TextInputComponent title={"Email Address"} onChangeText={(e)=>console.log(e)} error={"This Field is required"}/> */
}
