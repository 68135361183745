import React, { useEffect, useState } from 'react';
import { BackBox } from './styles';
import { Button } from '../button';
import variables from '../variables.json';
import SaveandContinueModal from '../SaveandContinueModal';

const BottomButton = (props) => {
  const { rightBtnTiltle, showSendbutton = true, showContinueModal = true, onContinue, buttonText } = props;
  const [showModal, setModal] = useState(false);

  return (
    <>
      {showSendbutton && (
        <Button
          style={{ alignSelf: 'flex-end', marginTop: 20, marginBottom: 10, borderColor: variables.puregreen }}
          onClick={() => {
            if (props?.onRightClick) {
              props.onRightClick();
            } else {
            }
          }}
          title={buttonText}
          type="stripe"
        />
      )}
      <BackBox>
        <Button
          onClick={() => {
            if (props?.onBackClick) {
              props.onBackClick();
            } else {
              history.goBack();
            }
          }}
          title={'Back'}
          type="stripe"
        />

        <Button
          onClick={() => {
            showContinueModal ? setModal(true) : onContinue();
          }}
          title={rightBtnTiltle ? rightBtnTiltle : 'Save & Continue'}
          type="solid"
        />
      </BackBox>

      <SaveandContinueModal
        visible={showModal}
        modalState={setModal}
        heading={'Saved'}
        subHeading={[
          'All information on this section has been saved.',
          'However you have not submitted this information to your tenant(s).',
          'If you wish to do so, you can return to this section from the main menu and click:',
          `"Save and Send " /  "Update and send"`,
        ]}
        onCancelPress={() => setModal(false)}
        hideSwitch={true}
        // showMsg={'Once you click this, you cannot undo this change.'}
        onOkPress={() => {
          setModal(false) 
          onContinue();
        }}
      />
    </>
  );
};
export default BottomButton;
