import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import AntDesign from '@expo/vector-icons/AntDesign';
import ToolTip from '../atoms/ToolTip';
const data = {
    tenants: [
        { id: '1', name: 'Mr. Robby Ramsdale', tlyfeId: 'GSKJ27RR', email: 'robbyramsdale@yopmail.com', isLead: true },
        { id: '2', name: 'Martin King', tlyfeId: 'WJ7ZKGZTS', email: 'martinking8@yopmail.com' },
        { id: '3', name: 'Shahad Ahmed Choudhury', tlyfeId: 'DB872AF85', email: 'paddy4@yopmail.com', contact: '07592396976' },
        { id: '4', name: 'Miguel Mastero', tlyfeId: '2SCBJ4HJX', email: 'miguelmastero1@yopmail.com' },
    ],
    landlords: [
        { id: '1', name: 'Mr. Mikky Art', email: 'mikkyart@yopmail.com' },
    ],
    guarantors: [
        { id: '1', name: 'Asd Asd', email: 'shahad5@yopmail.com', postcode: 'PL23 1AB', contact: '07592396976' },
    ],
};

const getInitials = (name) => {
    const names = name.split(' ');
    return names.length >= 2
        ? `${names[0][0]}${names[names.length - 1][0]}`.toUpperCase()
        : names[0][0].toUpperCase();
};

const Section = ({ title, items }) => (
    <View style={styles.sectionContainer}>
        <Text style={styles.sectionTitle}>{title}</Text>
        <View style={styles.cardWrapper}>
            {items && items.length ? items.map((item) => (
                <View key={item.id} style={styles.card}>
                    <View style={[styles.avatar, item.isLead && { backgroundColor: '#17d76a' }]}>
                        <Text style={styles.avatarText}>{getInitials(item.name)}</Text>
                    </View>
                    <View style={styles.cardContent}>
                        <Text style={styles.cardTitle}>{item.name}</Text>
                        {item.tlyfeId && <Text>TLYFE ID: {item.tlyfeId}</Text>}
                        <Text>Email: {item.email}</Text>
                        {item.postcode && <Text>Postcode: {item.postcode}</Text>}
                        {item.contact && <Text>Contact Number: {item.contact}</Text>}
                    </View>
                </View>
            )) : null}
        </View>
    </View>
);

const UserCard = (props) => {
    const [stakeData, setStakeData] = React.useState([]);
    const [checkedValue, setCheckedValue] = useState("");

    useEffect(() => {

        setStakeData(props?.stakeData);

    }, [props?.stakeData]);
    console.log("props", props)
    return (
        <>
            <View style={styles.container}>
       
                    <View style={{ flexDirection: 'column', }}>

                        <Text style={styles.title}>Tenancy Information & Agreements
                        </Text>
                        <Text style={styles.email}>Digitally create, check or amend your Tenancy Agreement, and send it to all parties.</Text>

                    </View>

           
                <ToolTip
                    style={{ position: "relative", width: "100%", marginBottom: 16 }}
                    heading={'Tool Tips'}
                    ContentData={[
                        'Fill in the information below to pre-populate the standard contracts, so they are ready to send straight to all parties.',
                    ]}
                />
                  <View style={styles.container}>
                    <View style={styles.header}>
                    <View style={{ flexDirection: 'column', }}>
                        <Text style={[styles.title,{marginBottom:16}]}>Tenants, Landlords & Guarantors</Text>
                    </View>

                    <TouchableOpacity onPress={() => {
                        if (checkedValue)
                            setCheckedValue("")
                        else
                            setCheckedValue(!checkedValue)
                    }}>
                        <AntDesign name={checkedValue ? "minus" : "plus"} size={30} color="black" />
                    </TouchableOpacity>
                </View>
               {checkedValue? <ScrollView >
                    <Section title="Tenants" items={stakeData?.tenants} />
                    <View style={styles.separator} />
                    <Section title="Landlords" items={stakeData?.landlords} />
                    <View style={styles.separator} />
                    <Section title="Guarantors" items={stakeData?.guarantors} />
                </ScrollView>:null}
                </View>
           
            </View>
        </>

    );
};

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "space-between",
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    email: {
        color: '#718096',
        marginVertical: 10
    },
    container: {
        padding: 16,
        backgroundColor: '#fff',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 5,
        marginBottom: 30
    },
    sectionContainer: {
        marginBottom: 24,
    },
    sectionTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    cardWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 12,
    },
    card: {
        flexBasis: '48%',
        backgroundColor: 'white',
        padding: 16,
        borderRadius: 8,
        marginBottom: 12,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 2,
    },
    cardContent: {
        marginTop: 8,
    },
    avatar: {
        width: 50,
        height: 50,
        borderRadius: 25,
        backgroundColor: '#a070c4',
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatarText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
    cardTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 4,
    },
    separator: {
        height: 1,
        backgroundColor: '#ccc',
        marginVertical: 12,
    },
});

export default UserCard;





