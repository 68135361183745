import React, { useEffect, useState } from 'react';
// import Container from '../../../components/Container/Container';
import { ListItem } from '../atoms/listitem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import variables from '../atoms/variables.json';
import { TextInput } from './../atoms/textinput';
// import Alert from '@material-ui/lab/Alert';
import ToolTip from '../atoms/ToolTip';
import PropertyCard from '../atoms/PropertyCard';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import {
  CardWrapper,
  TlyfeSelector,
  LineSeperator,
  Heading,
  SubHeading,
  TlyfeButtonWrapper,
  FormContainer,
  RowContainer,
} from './style';
import { CardBox } from '../atoms/card';
import { Button } from '../atoms/button';
// import { alertActions, tenantActions } from '../../../_actions';
// import { generalService } from '../../../_services';
import ContactCard from '../atoms/contactCard';
import { useDispatch, useSelector } from 'react-redux';
import BackComponent from '../atoms/backComponent';
import BottomButton from '../atoms/bottomButton';
// import StringConstants from '../../../utils/constants';
// import TEANANT_ICON from './../../../assets/svgIcons/OpenFlo/Tenant.svg';
import OpenFlowModal from '../atoms/modal';
import moment from 'moment';
import SaveandContinueModal from '../atoms/SaveandContinueModal';
import { View } from 'react-native';
import drawerHoc from '../../../../components/drawerHOC/DrawerHOC';
import getPlatformType from '../../../../helpers/helpers';
import { tenantActions } from '../../redux/tenant.actions';
import STRINGS from '../../redux/strings';
import { TenantService } from '../../redux/tenant.service';
import { showErrorToast, showSuccesToast } from '../helpers';
import { navigate } from '../../../../navigation/RootNavigation';
import TenantListScreen from './components/tenantList';

const userModal = {
  first_name: '',
  last_name: '',
  email: '',
  contact_number: '',
  isLeadTeanant: null,
  opbrix_id: '',
  is_lead: false,
  tLyfeId: '',
  pincode: '',
  address: '',
};
const layoutType = getPlatformType();
const TeanantDetails = (props) => {
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state?.tenant.flowIdInfo);
  const tenantsArry = useSelector((state) => state?.tenant?.flowIdInfo?.tenants);

  const [value, setValue] = React.useState(null);
  const [teanentDetails, setTeanentDetails] = React.useState(userModal);

  const [notifyTeanant, setNotifyTeanants] = React.useState(false);

  const [addedUsers, setAddedUsers] = React.useState([]);
  const [showAgain, setShowAgain] = React.useState(false);
  const [flow, setFlow] = React.useState({});
  const [checked, setChecked] = React.useState(false);
  const [isValidate, setIsValidate] = React.useState(false);
  const [is_show_tenant_popup, setIs_show_tenant_popup] = React.useState(true);
  const [addUser, setAddUser] = React.useState(null);
  const [flowId, setFlowId] = React.useState(null);
  const [confirm, setConfirm] = React.useState(false);
  const [deleteCardconfirm, setDeleteCardconfirm] = React.useState(false);
  const [mailToUpdateAsLead, setMailToUpdateAsLead] = React.useState('');
  const [idToDeleteTenant, setIdToDeleteTenant] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [editTeanantID, setEditTeanantID] = React.useState('');
  const [showSuccessModal, setSuccessModal] = React.useState(false);
  const [closePopup, setClosePopup] = React.useState(false);

  const modalGlobalObject = useSelector((state) => state?.tenant?.tenantDetails);
  const [modalState, setModalState] = React.useState({
    send_to_tenant: true,
  });

  useEffect(() => {
    if (modalGlobalObject) {
      setModalState({
        send_to_tenant: modalGlobalObject.send_to_tenant,
      });
    }
  }, [modalGlobalObject]);

  useEffect(() => {
    console.log(props)
    if (props?.route?.params?.id) {
      setFlowId(props?.route?.params?.id);
      fetchFlows()
    }
  }, [props?.route?.params?.id]);
  const fetchFlows = () => {
    dispatch(tenantActions.getFlowInfo(props?.route?.params?.id));
  }
  useEffect(() => {
    if (tenantsArry && tenantsArry?.length) {
      setAddedUsers(tenantsArry);
      setFlow(globalState);
      setIs_show_tenant_popup(globalState?.is_send_tenant_details);
    } else {
      setAddedUsers([]);
    }
  }, [tenantsArry]);

  useEffect(() => {
    setTeanentDetails({
      ...teanentDetails,
      is_lead: addedUsers.length > 0 ? false : true,
    });
  }, [addedUsers]);
  const [fieldsValue, setFieldValues] = useState({
    tLyfeId: '',
    //8MDZ62U55
  });

  const handleChangeForm = (e, name) => {
    setFieldValues({ ...fieldsValue, [e.target.name]: e.target.value });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleChangeToggle = (event) => {
    setNotifyTeanants(!notifyTeanant);
  };
  const handleChangeLead = (event) => {
    setFieldValues({
      ...fieldsValue,
      is_lead: event.target.value == 'true' ? true : false,
    });
  };
  console.log("is_leadis_lead", fieldsValue)
  const searchTenant = async (searchObject) => {
    let filterString;
    let searchBytlifeId
    if (searchObject.hasOwnProperty('tlife')) {
      filterString = `tlife=${searchObject.tlife}`;
      searchBytlifeId = true;
    } else {
      filterString = `email=${searchObject.email}`;
      searchBytlifeId = false;
    }
    dispatch(tenantActions.getTenantByTlyfeId(fieldsValue.tLyfeId.trim(), (response) => {
      if (response.status) {
        let data = response?.data?.user;
        setFieldValues({
          ...fieldsValue,
          title: data?.title,
          first_name: data?.first_name,
          last_name: data?.last_name,
          email: data?.email,
          confirm_email: data?.email,
          contact_number: data?.contact_number,
          isLeadTeanant: null,
          opbrix_id: response?.opbrix_id,
          postcode: data?.postcode,
          address: data?.address,
        });
      }
    }))
  }


  const MakeLeadFunct = (arrayData, emailTomakeLead) => {
    const data = arrayData.map((elem) => ({
      ...elem,
      is_lead2: emailTomakeLead === elem?.email ? true : false,
    }));
    return data;
  };

  const renderModalBody = (data, index) => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      {data?.opbrix_id && (
        <TextInput
          title={
            <>
              <strong>tlyfe ID: </strong>
            </>
          }
          type={'row'}
          wrapperstyle={{
            width: '99%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data?.opbrix_id || ''}
        />
      )}
      <TextInput
        title={
          <>
            <strong>Title: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '10%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.title || ''}
      />
      <TextInput
        title={
          <>
            <strong>First Name: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '38%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.first_name || ''}
      />
      <TextInput
        title={
          <>
            <strong>Last Name: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.last_name || ''}
      />
      <TextInput
        title={
          <>
            <strong>Email: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.email || ''}
      />
      <TextInput
        title={
          <>
            <strong>Contact Number: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.contact_number || ''}
        onChange={(event) => {
          let teampArr = [...addedUsers];
          teampArr[index].contact_number = event.target.value.replace(/\D/, '');
          setAddUser(teampArr);
        }}
      />
      <TextInput
        title={
          <>
            <strong>Post Code: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.postcode || ''}
        onChange={(event) => {
          let teampArr = [...addedUsers];
          teampArr[index].postcode = event.target.value;
          setAddUser(teampArr);
          console.log('teampArrteampArr', teampArr);
        }}
      />
      <TextInput
        title={
          <>
            <strong>Address: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.address || ''}
        onChange={(event) => {
          let teampArr = [...addedUsers];
          teampArr[index].address = event.target.value;
          setAddUser(teampArr);
          console.log('teampArrteampArr', teampArr);
        }}
      />

      <Button
        style={{ marginTop: 20, marginBottom: 10, borderColor: '#90C418' }}
        onClick={() => {

          if (editTeanantID === data?.opbrix_id) {
            // dispatch(
            //   tenantActions.saveTeanantDetails(
            //     { tenants: addedUsers, is_show_tenant_popup: is_show_tenant_popup },
            //     flowId
            //   )
            // );
          } else {
            setEditTeanantID(data?.opbrix_id);
          }
        }}
        title={editTeanantID === data?.opbrix_id ? 'Save tenant' : 'Edit tenant'}
        type="stripe"
      />
    </div>
  );

  const renderMakeLeadModalBody = () => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <span>Do you wish to make this tenant as Lead Tenent</span>
      <br />
      <br />
      <TlyfeButtonWrapper style={{ justifyContent: 'space-between' }}>
        <Button
          onClick={() => {
            setClosePopup(false);
            setAddedUsers(MakeLeadFunct(addedUsers, mailToUpdateAsLead));
            // setConfirm(true)
          }}
          title={'Yes'}
          type="stripe"
          style={{ borderColor: '#90C418', color: '#90C418' }}
        />
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            setClosePopup(false);
          }}
          title={'No'}
          type="stripe"
          style={{ borderColor: '#FC4850', color: '#FC4850' }}
        />
      </TlyfeButtonWrapper>
      <br /> <br />
      <RowContainer>
        <span>Please Note</span>
        <span>
          If you make tenent the Lead Tenant any other tenant has been assigned as lead tenant would no longer be the
          Lead Tenant
        </span>
      </RowContainer>
    </div>
  );

  const renderSuccessBody = () => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <span>
        Congratulations.
        <br />
        <br />
        You have added your tenant(s) to this onboard process.
        <br />
        <br />
      </span>

      <span>Tenants will be emailed directly to sign up to tlyfe and continue the move in process.</span>

      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          marginTop: 20,
          marginBottom: 20,
          width: '100%',
        }}
      >
        <div
          style={{
            color: notifyTeanant ? '#dadada' : '#FC4850',
            fontWeight: '900',
            fontSize: 20,
            marginRight: 20,
          }}
        >
          Dont Send
        </div>

        <Switch
          checked={notifyTeanant}
          onChange={handleChangeToggle}
          name="notifyTeanant"
          color="primary"
          size="normal"
        />

        <div
          style={{
            color: notifyTeanant ? '#90C418' : '#dadada',
            fontWeight: '900',
            fontSize: 20,
            marginLeft: 20,
          }}
        >
          Send
        </div>
      </div>

      <div>PLEASE NOTE:</div>
      <span>
        The email may end up in your tenant(s) junk mail. Please communicate with them to ensure they locate the email
        and carry out the relevant actions.
      </span>
      <RowContainer style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
        <Checkbox
          name="showPopup"
          checked={!is_show_tenant_popup}
          onChange={() => setIs_show_tenant_popup(!is_show_tenant_popup)}
          value={1}
          color="primary"
        />
        <span style={{ color: 'black' }}>Do not show again</span>
      </RowContainer>

      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-evenly',
          alignItems: 'space-between',
        }}
      >
        <Button
          onClick={() => {
            setSuccessModal(false);
          }}
          style={{ borderColor: '#90C418', color: '#90C418' }}
          title={'Cancel'}
          type="stripe"
        />
        <Button
          onClick={() => {
            // dispatch(tenantActions.showPopup(showAgain));
            // dispatch(
            //   tenantActions.saveTeanantDetails(
            //     {
            //       tenants: addedUsers,
            //       is_show_tenant_popup: is_show_tenant_popup,
            //       notifyTeanant: notifyTeanant,
            //     },
            //     flowId
            //   )
            // );
            setSuccessModal(false);
            // setConfirm(true)
          }}
          style={{ borderColor: '#FC4850', color: '#FC4850' }}
          title={'OK'}
          type="stripe"
        />
      </div>
    </div>
  );

  const renderRemoveCardBody = (item, index) => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <span>Do you wish to remove this ?</span>
      <br />
      <br />
      <TlyfeButtonWrapper>
        <Button
          onClick={() => {
            let newArray = [...addedUsers];
            newArray.splice(index, 1);
            setAddedUsers(newArray);
            setClosePopup(false);
          }}
          title={'Yes'}
          type="stripe"
        />
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            setClosePopup(false);
          }}
          title={'No'}
          type="stripe"
        />
      </TlyfeButtonWrapper>
      <br /> <br />
      <RowContainer>
        <span>Please Note</span>
        <span> Once you remove this you cannot undo this change</span>
      </RowContainer>
    </div>
  );
  const toggleLead = (arr, index) => {
    return arr.map((item, i) => ({
      ...item,
      is_lead: i === index ? true : false
    }));
  };
  const deleteFlow = (arr, index) => {
    return arr.map((item, i) => ({
      ...item,
      remove_from_flow: i === index ? true : false
    }));
  };

  return (
    <View style={{ flex: 1 }}>
      <CardWrapper>
        <ListItem
          type={'complete'}
          title={'Tenant'}
          description={`Begin the tenant onboarding process by "pairing" your Applicant Tenant(s) to your property.
              <br/><br/>
              OpenFlo interacts with TLyfe and it is recommended that you request that the Applicant Tenant(s) registers with TLyfe before you start this process in order to maximise process efficiency.`}
          showPropertyCard
        // icon={TEANANT_ICON}
        />

        <BackComponent id={flowId} isComplete={flow?.tenancy_status === 1 ? true : false} flowtype={'tenancy_status'} />

          
        <div style={{ position: 'relative' }}>
          <ToolTip
            style={{ top: '0%', right: '-40%' }}
            heading={'Tool Tips'}
            ContentData={[
              'Ask the applicants to check if all the details are entered correctly via the tlyfe app.',
              'They are automatically sent a registration email if you include their email address in the tenant section.',
            ]}
          />
        </div>
        <TenantListScreen/>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SubHeading style={{ color: variables.purple }}> Your applicant tenant(s)</SubHeading>
          {globalState?.tenantDetails?.sub_tenants.length ? (
            <SubHeading style={{ color: variables.purple }}>
              {' '}
              Last sent on: {moment(flow?.updatedAt).format('DD/MM/YYYY')}
            </SubHeading>
          ) : null}
        </div>
        {addedUsers && addedUsers.length ? (
          <React.Fragment>
            <RowContainer style={{ marginBottom: 0, flexWrap: 'wrap' }}>
              {addedUsers.map((item, index) => {
                console.log("itemitem", item)
                return (

                  <ContactCard
                    data={{
                      opbrix_id: item?.app_user?.opbrix_id,
                      title: item?.app_user?.user?.title,
                      first_name: item?.app_user?.user?.first_name,
                      last_name: item?.app_user?.user?.last_name,
                      email: item?.app_user?.user?.email,
                      number: item?.app_user?.user?.contact_number,
                      post_code: item?.app_user?.user?.postcode,
                      address: item?.app_user?.user?.address,
                      tenant_id: item?.app_user?.opbrix_id,
                    }}
                    isLeadEditable={true}
                    selectedEmailForLead={(email) => setMailToUpdateAsLead(email)}
                    leadModalConfirm={confirm}
                    deleteCardConfirm={deleteCardconfirm}
                    selectIdToDelete={(id) => setIdToDeleteTenant(id)}
                    key={item?.app_user?.opbrix_id}
                    row={item?.app_user?.user?.email}
                    rowIndex={index}
                    is_lead={item?.is_lead ? true : false}
                    name={item?.app_user?.user?.first_name + ' ' + item?.app_user?.user?.last_name}
                    renderModal={renderModalBody(item, index)}
                    renderShowHeadModal={renderMakeLeadModalBody(item)}
                    renderRemoveCardBody={renderRemoveCardBody(item, index)}
                    onDelete={(data) => {
                      dispatch(tenantActions.saveTeanantDetails({ tenants: deleteFlow(addedUsers, index), flow_id: flowId }, (data) => {
                        showSuccesToast("Teanant deleted successfully!")
                        fetchFlows()
                      }))
                      setClosePopup(false);
                    }}
                    onMakeLead={() => {
                      dispatch(tenantActions.saveTeanantDetails({ tenants: toggleLead(addedUsers, index), flow_id: flowId }, (data) => {
                        showSuccesToast("Teanant maked as lead successfully!")
                        fetchFlows()
                      }))
                      setClosePopup(false);
                    }}
                    onEdit={(data) => {
                   addedUsers[index]={
                    ...addedUsers[index],
                    edit_this_tenant:true,
                     app_user:{
                      ...addedUsers[index].app_user,
                      user:{
                        ...addedUsers[index]["app_user"]["user"],
                        email: data?.email,
                        first_name: data?.first_name,
                        last_name: data?.last_name,
                        address: data?.address,
                        title: data?.title,
                        contact_number: data?.number,
                        postcode: data?.post_code
                      }
                      }
                     }      
                      dispatch(tenantActions.saveTeanantDetails({ tenants: addedUsers, flow_id: flowId }, (data) => {
                        showSuccesToast("Teanant information updated successfully!")
                        fetchFlows()
                      }))
                      setClosePopup(false);

                    }}


                    closePopup={closePopup}
                    setClosePopup={setClosePopup}
                  />
                )
              })}
            </RowContainer>
          </React.Fragment>
        ) : null}
        <TlyfeButtonWrapper>
          <Button
            onClick={() => {
              setAddUser(true);
            }}
            title={addedUsers && addedUsers.length ? 'Add another tenant' : 'Add tenant'}
            type="stripe"
            style={{ borderColor: '#90C418' }}
          />
        </TlyfeButtonWrapper>
        <LineSeperator />

        {addUser ? (
          <React.Fragment>
            <Heading>Tlyfe Details</Heading>
            <TlyfeSelector style={{ marginTop: 0 }}>
              <div
                style={{
                  flex: 0.6,
                  display: 'flex',
                  flexDirection: 'row',
                  paddingRight: 10,
                }}
              >
                <SubHeading>Does the Tenant have a Tlyfe App</SubHeading>
              </div>
              <div
                style={{
                  flex: 0.4,

                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={value}
                    onChange={(evernt) => {
                      setFieldValues(userModal);
                      handleChange(evernt);
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio style={{ color: variables.heliotrope }} />}
                        style={{ color: 'black' }}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio style={{ color: variables.heliotrope }} />}
                        style={{ color: 'black' }}
                        label="No"
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </TlyfeSelector>

            {value === 'Yes' && (
              <React.Fragment>
                <LineSeperator />
                <Heading>Tlyfe Details</Heading>
                <CardBox style={{ position: 'relative' }}>
                  <ToolTip
                    style={{ top: '0%', right: '-40%' }}
                    heading={'tlyfe Tip'}
                    ContentData={['tlyfe, pre-populates the tenants verified ID and details for you.']}
                  />
                  <SubHeading>Tlyfe Number</SubHeading>
                  <TextInput
                    value={fieldsValue?.tLyfeId}
                    onChange={handleChangeForm}
                    name="tLyfeId"
                  />
                  {isValidate && !fieldsValue.tLyfeId
                    ? // <Alert
                    //   style={{
                    //     marginTop: '2%',
                    //     width: '50%',
                    //     marginBottom: 20,
                    //   }}
                    //   variant="filled"
                    //   severity="error"
                    // >
                    //   Please enter Tylfe Number
                    // </Alert>
                    null
                    : null}
                  <TlyfeButtonWrapper style={{ marginTop: 20, justifyContent: 'space-between', width: '100%' }}>
                    <Button
                      onClick={() => {
                        setValue(null);
                      }}
                      title={'Cancel'}
                      style={{ borderColor: '#FC4850', color: '#FC4850' }}
                      type="stripe"
                    />
                    <Button
                      onClick={() => {
                        setIsValidate(true);
                        if (fieldsValue.tLyfeId) searchTenant({ tlife: fieldsValue.tLyfeId });
                      }}
                      style={{
                        borderColor: '#90C418',
                        color: '#90C418',
                        marginRight: 15,
                        marginTop: layoutType == 'phone' ? 10 : 0,
                      }}
                      title={'Submit'}
                      type="stripe"
                    />
                  </TlyfeButtonWrapper>
                </CardBox>
              </React.Fragment>
            )}
            {fieldsValue?.email || value === 'No' ? (
              <React.Fragment>
                <LineSeperator />
                <Heading>Tenant Details</Heading>
                <TlyfeSelector style={{ marginBottom: '5%' }}>
                  <div
                    style={{
                      flex: 0.3,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <SubHeading>Is this the lead tenant?</SubHeading>
                  </div>
                  <div
                    style={{
                      flex: 0.7,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        name="is_lead"
                        value={fieldsValue.is_lead}
                        onChange={handleChangeLead}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControlLabel
                            value={true}
                            control={<Radio style={{ color: variables.heliotrope }} />}
                            style={{ color: 'black' }}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio style={{ color: variables.heliotrope }} />}
                            style={{ color: 'black' }}
                            label="No"
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                </TlyfeSelector>

                <CardBox style={{ position: 'relative', width: '100%' }} hasBorderRadius hasShadow>
                  <ToolTip
                    style={{ top: '0%', right: '-40%' }}
                    heading={'Tenant Tip'}
                    ContentData={[
                      'If you dont have all the tenant details in hand, just complete the email and then ask the applicant to complete the remaining details in thier Tlyfe App',
                    ]}
                  />
                  <RowContainer>
                    <FormContainer>
                      <p style={{ fontSize: 14, width: '100%', fontFamily: 'lato' }}>Title</p>
                      <Select
                        value={fieldsValue?.title}
                        style={{
                          width: '100%',
                          height: '40px',
                          fontFamily: 'lato',
                        }}
                        onChange={handleChangeForm}
                        variant="outlined"
                        name="title"
                        placeholder="Title"
                      >
                        {STRINGS.OPENFLOW_TITLES.map((item) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                      </Select>
                    </FormContainer>
                    <FormContainer>
                      <TextInput
                        title={'First name'}
                        name={'first_name'}
                        error={nameError}
                        onChange={(e) => {
                          const re = /^[A-Za-z\-]+$/;
                          if (e.target.value === '' || re.test(e.target.value)) {
                            handleChangeForm(e);
                            setNameError(false);
                          }
                        }}
                        containerstyles={{ width: '100%' }}
                        value={fieldsValue?.first_name}
                        disabled={fieldsValue.opbrix_id ? true : false}
                      />
                    </FormContainer>
                  </RowContainer>
                  <RowContainer>
                    <FormContainer>
                      <TextInput
                        title={'Last name'}
                        name={'last_name'}
                        onChange={(e) => {
                          const re = /^[A-Za-z\-]+$/;
                          if (e.target.value === '' || re.test(e.target.value)) {
                            handleChangeForm(e);
                          }
                        }}
                        containerstyles={{ width: '100%' }}
                        value={fieldsValue?.last_name}
                        disabled={fieldsValue.opbrix_id ? true : false}
                      />
                    </FormContainer>
                    <FormContainer>
                      <TextInput
                        title={'Contact Number'}
                        name={'contact_number'}
                        type={'number'}
                        onChange={(e) => {
                          const re = /^[+\d](?:\d)*$/;
                          if (e.target.value === '' || re.test(e.target.value)) {
                            handleChangeForm(e);
                          }
                        }}
                        containerstyles={{ width: '100%' }}
                        value={fieldsValue?.contact_number}
                      />
                    </FormContainer>
                  </RowContainer>
                  <RowContainer>
                    <FormContainer>
                      <TextInput
                        title={'Email Address'}
                        inputProps={{
                          readOnly: fieldsValue.tLyfeId ? true : false,
                        }}
                        name={'email'}
                        onChange={handleChangeForm}
                        containerstyles={{ width: '100%' }}
                        disabled={fieldsValue.opbrix_id ? true : false}
                        value={fieldsValue?.email?.toLowerCase()}
                      />
                    </FormContainer>
                    <FormContainer>
                      <TextInput
                        title={'Confirm Email'}
                        name={'confirm_email'}
                        onChange={handleChangeForm}
                        containerstyles={{ width: '100%' }}
                        value={fieldsValue?.confirm_email?.toLowerCase()}
                      />
                      {fieldsValue?.email && fieldsValue?.confirm_email ? (
                        fieldsValue?.email === fieldsValue?.confirm_email ? null : (
                          <p
                            style={{
                              marginTop: '5px',
                              color: 'red',
                              textAlign: 'right',
                              marginTop: 10,
                            }}
                          >
                            Email Should be same
                          </p>
                        )
                      ) : null}
                    </FormContainer>
                  </RowContainer>
                  <RowContainer>
                    <FormContainer>
                      <TextInput
                        title={'Current Address'}
                        name={'address'}
                        onChange={handleChangeForm}
                        containerstyles={{ width: '100%' }}
                        value={fieldsValue?.address}
                      />
                    </FormContainer>
                    <FormContainer>
                      <TextInput
                        title={'Post Code'}
                        name={'postcode'}
                        onChange={handleChangeForm}
                        containerstyles={{ width: '100%' }}
                        value={fieldsValue?.postcode}
                      />
                    </FormContainer>
                  </RowContainer>
                  <TlyfeButtonWrapper style={{ marginTop: '5%', justifyContent: 'space-between' }}>
                    <Button
                      style={{
                        marginTop: 0,
                        marginBottom: 10,
                        borderColor: '#FC4850',
                        color: '#FC4850',
                      }}
                      onClick={async () => {
                        setAddUser(false);
                        setValue(null);
                        setFieldValues(userModal);
                        window.scrollTo(0, 0);
                      }}
                      title={'Cancel'}
                      type="stripe"
                    />
                    <Button
                      style={{
                        marginTop: 0,
                        marginBottom: 10,

                        borderColor: '#90C418',
                        color: '#90C418',
                      }}
                      onClick={async () => {
                        if (!fieldsValue.first_name) {
                          showErrorToast('Please enter first Name!');
                        } else if (!fieldsValue.last_name) {
                          showErrorToast('Please enter last Name!');
                        } else {
                          if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(fieldsValue.email)) {
                            if (fieldsValue?.email === fieldsValue?.confirm_email) {
                              dispatch(tenantActions.validateEmail(fieldsValue.email, (res) => {
                                console.log("res", res)
                                console.log("addeduser", addedUsers)
                                if (res.status) {
                                  let finalArr = [...addedUsers, {
                                    app_user: { user: { ...fieldsValue } },
                                    is_lead: fieldsValue.is_lead
                                  }]
                                  console.log("finalArrfinalArrfinalArr", finalArr)
                                  dispatch(tenantActions.saveTeanantDetails({ tenants: finalArr, flow_id: flowId }, (data) => {
                                    setFieldValues({})
                                    setValue(null)
                                    showSuccesToast("Teanant added successfully!")
                                    fetchFlows()
                                  }))

                                }
                              }))
                            } else {
                              showErrorToast('email dosent match!');
                            }
                          } else {
                            showErrorToast('Invalid Email')
                          }
                        }
                      }
                      }
                      title={'Add Tenant'}
                      type="stripe"
                    />
                  </TlyfeButtonWrapper>
                </CardBox>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
        {addedUsers && addedUsers.length ? (
          <BottomButton
            buttonText={'Save and Send'}
            onRightClick={() => {
              dispatch(tenantActions.saveTeanantDetails({ 
                tenants: addedUsers, 
                flow_id: flowId,
                send_to_tenant : true
              }, (data) => {
                showSuccesToast("Request send successfully!")
              }))
            }}
            onContinue={
              () => { 
                dispatch(tenantActions.saveTeanantDetails({ 
                  tenants: addedUsers, 
                  flow_id: flowId,
                  send_to_tenant : false
                }, (data) => {
                  showSuccesToast("Request saved successfully!")
                  navigate('TeanantOnboarding',{id:flowId})
                }))
              }
            }
          />
        ) : null}
      </CardWrapper>

      <SaveandContinueModal
        visible={showSuccessModal}
        modalState={modalState}
        heading={'Tenant(s)'}
        subHeading={[
          'Congratulations.',
          'You have added your tenant(s) to this onboard process.',
          'Tenant(s) will be emailed directly to sign up to tlyfe and continue the move in process.',
          `If you toggle "send" your tenants will be notified  via email and app,`,
        ]}
        onCancelPress={() => setSuccessModal(false)}
        showNote
        onOkPress={(data) => {
          // dispatch(tenantActions.saveTeanantDetails({ tenants: addedUsers, ...data }, flowId));
        }}
      />
    </View>
  );
};
export default drawerHoc({
  Component: TeanantDetails,
  showBackButton: true,
});
