import React, { useEffect, useState } from 'react';
import { ListItem } from '../atoms/listitem';
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';

import { useDispatch, useSelector } from 'react-redux';

import variables from '../atoms/variables.json';

import BackComponent from './../atoms/backComponent';



import {
  CardWrapper,
  SubHeading,

} from './style';
import BottomButton from '../atoms/bottomButton';

import RentalWarrenty from './RentalWarrenty';

import Container from '../atoms/Container/Container';

import DrawerHoc from '../../../../components/drawerHOC';
import getPlatformType from '../../../../helpers/helpers';
import { CardBox } from '../atoms/card';
import IdBox from './idBox';

const agreementsCol = [{ title: 'Court' }, { title: 'Amount' }, { title: 'Order Date' }];

const judgmentsCol = [{ title: 'Court' }, { title: 'Amount' }, { title: 'Date' }];
const layoutType = getPlatformType();
const TenantIdentification = (props) => {
  const dispatch = useDispatch();
  const [tenants, setTenants] = React.useState([]);
  const [flowId, setFlowId] = React.useState(null);

  const [warranty, setWarranty] = React.useState(null);

  const idendityData = useSelector((state) => state?.tenant?.idendityData);



  const modalGlobalObject = useSelector((state) => state?.tenant?.idendityData);




  useEffect(() => {
    // dispatch(tenantActions.getFccBranchCode());
  }, []);

  useEffect(() => {
    if (props?.match?.params?.id) {
      setFlowId(props?.match?.params?.id);
 
    }
  }, [props?.match?.params?.id]);



 


  const _onSave = (data) => {
    let payload = {
      tenants: tenants,
      ...data,
    };
    // dispatch(tenantActions.saveIdentityData(payload, flowId));
  };

  return (
    <Container>
      <CardWrapper>
        <ListItem
          type={'complete'}
          title={'Tenant Identification'}
          listIcon={<ListAltTwoToneIcon style={{ fontSize: '25px', color: variables.purple1 }} />}
          // icon={ID_ICON}
          showPropertyCard
          description={`All Applicant Tenant(s) Identification and Verification is received from TLyfe which interacts with OpenFlo to maximise the process efficiency and referral fees.`}
        />

        <BackComponent
          id={flowId}
          isComplete={idendityData?.flow?.verification_status == 1 ? true : false}
          flowtype={'verification_status'}
        />
        <IdBox props={props}/>

        <RentalWarrenty warranty={warranty} flowId={flowId}>
          {' '}
        </RentalWarrenty>

        <BottomButton
          showSendbutton={false}
          showContinueModal={false}
          onContinue={() => {
            _onSave();
          }}
        />
      </CardWrapper>

  
    </Container>
  );
};

export default DrawerHoc({
  Component: TenantIdentification,
  showBackButton: true,
    customWidth: "70%"
});
