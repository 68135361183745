import React, { useState, useEffect } from 'react';

import {
  CardWrapper,

} from './style';

import { useDispatch, useSelector } from 'react-redux';

import Container from '../atoms/Container/Container';
import drawerHoc from '../../../../components/drawerHOC/DrawerHOC';
import getPlatformType from '../../../../helpers/helpers';
import { tenantActions } from '../../redux/tenant.actions';
import PropertiesTable from './components/propertyTable';
const layoutType = getPlatformType();

const AddProperty = (props) => {
  const dispatch = useDispatch();


  const [selectedTds, setSelectedTds] = React.useState();

  

  useEffect(() => {
    if (props?.route?.params?.id) {
      setFlowId(props?.route?.params?.id);
      dispatch(tenantActions.getFlowInfo(props?.route?.params?.id));
    }
  }, [props?.route?.params?.id]);

  



  return (
    <Container style={{ fontWeight: 400 }}>
   <PropertiesTable/>
    </Container>
  );
};
export default drawerHoc({
  Component: AddProperty,
  showBackButton: true,
});


