import React, { useState, useEffect } from 'react';
import { ListItem } from '../atoms/listitem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import variables from '../atoms/variables.json';
import { TextInput } from '../atoms/textinput';
import moment from 'moment';
// import { tenantActions, alertActions } from '_actions';
import ToolTip from '../atoms/ToolTip';
import { tenantActions, alertActions } from '../../redux/tenant.actions';

// import HOLDING_ICON from './../../../assets/svgIcons/OpenFlo/HoldingDeposit.svg';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import {
  CardWrapper,
  TlyfeSelector,
  LineSeperator,
  Heading,
  SubHeading,
  TlyfeButtonWrapper,
  FormContainer,
  RowContainer,
  TextBox,
} from './style';
import { CardBox } from '../atoms/card';
import { Button } from '../atoms/button';
import BackComponent from '../atoms/backComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getHoldingDeposite, getHoldingDepositStatusData1, showErrorToast } from '../helpers';
import BottomButton from '../atoms/bottomButton';
import { Otp } from '../atoms/otp';
import OpenFlowModal from '../atoms/modal';
import SaveandContinueModal from '../atoms/SaveandContinueModal';
import Container from '../atoms/Container/Container';
import drawerHoc from '../../../../components/drawerHOC/DrawerHOC';
import getPlatformType from '../../../../helpers/helpers';
const layoutType = getPlatformType();

const HoldingDeposit = (props) => {
  const [holding_terms, setHoldingTerms] = React.useState("A holding deposit or “holding fee” is a financial sum that tenant(s) pay as confirmation of their intent to proceed with an application to rent a property. The deposit secures the property for tenant(s).");
  const [holding_terms_added, setHoldingTermsAdded] = React.useState(null);
  const [Accounts, setAccounts] = React.useState([]);
  const [openAccountModal, setAccountModal] = React.useState(false);
  const [isValidated, setIsValidated] = React.useState(false);
  const [collectDeposit, setcollectDeposit] = React.useState(false);
  const [showSuccessModal, setSuccessModal] = React.useState(false);

  const [account_id, setAccount_id] = React.useState('');
  const [sortCode, setsortCode] = React.useState('');
  const [accountName, setAccountName] = React.useState('');
  const [payeeRef, setPayeeRef] = React.useState('');
  const [teanats, setTeanats] = React.useState([]);
  const [rental_amount, setRentalAmount] = React.useState(null);
  const [rental_frequency, setRentalFrequency] = React.useState('monthly');
  const [newAccountName, setnewAccountName] = React.useState('');
  const [newAccountSortCode, setnewAccountSortCode] = React.useState('');
  const [newAccountNumber, setnewAccountNumber] = React.useState('');


  const [tenancy_start_date, setTenanctStartDate] = React.useState(null);
  const [holding_amount, setHolding_deposit_amount] = React.useState(null);

  const [isHoldingDepositSelected, setHoldingDepositSelected] = React.useState(true);

  const [selectedAccount, setselectedAccount] = React.useState('');
  const [selectedBranch, setselectedBranch] = React.useState('');

  const [newAccAdded, setNewAccAdded] = React.useState('');
  const [accountButtonText, setAccountButtonText] = React.useState('Add Account');
  const [flowId, setFlowId] = React.useState(null);
  const [editAccounts, setEditAccounts] = React.useState(false);
  const [dateDepositReceived, setdateDepositReceived] = React.useState(null);
  const dispatch = useDispatch();
  const modalGlobalObject = useSelector((state) => state?.tenant?.flowIdInfo);

  const bankAccounts = useSelector((state) => state?.tenant?.branchBankAccounts);

  const branches = useSelector((state) => state?.tenant?.companyBranches);

  const [modalState, setModalState] = React.useState({
    send_to_tenant: true,
  });

  const [holdingDepositCollection, setHoldingDepositCollection] = useState(null);

  useEffect(() => {
    if (modalGlobalObject) {
      setModalState({
        send_to_tenant: modalGlobalObject.send_to_tenant,
      });
    }
  }, [modalGlobalObject]);


  useEffect(() => {
    if (props?.route?.params?.id) {
      setFlowId(props?.route?.params?.id);
      dispatch(tenantActions.getFlowInfo(props?.route?.params?.id));
      dispatch(tenantActions.getBranchBankAccounts());
      dispatch(tenantActions.getCompanyBranches());
    }

  }, [props?.route?.params?.id]);

  const holdingDepositeData = useSelector((state) => state?.tenant?.flowIdInfo?.open_flow_holding_deposit);

  const userData = useSelector((state) => state.users);

  useEffect(() => {
    setHoldingTermsAdded(holdingDepositeData?.holding_deposit_text)
    setHoldingDepositSelected(holdingDepositeData?.is_holding_deposit_selected);

    setHoldingDepositCollection(modalGlobalObject?.holding_deposit_collection_from_lead_tenant)

    setcollectDeposit(holdingDepositeData?.collectDeposit);

    if (holdingDepositeData?.holding_terms_added) {
      setHoldingTermsAdded(holdingDepositeData.holding_terms_added);
    }

    if (holdingDepositeData?.tenants && holdingDepositeData?.tenants.length) {
      setTeanats(modalGlobalObject?.tenants);
    }

    if (modalGlobalObject?.open_flow_rental_offer?.rental_frequency) {
      setRentalFrequency(modalGlobalObject?.open_flow_rental_offer?.rental_frequency);
    }


    if (modalGlobalObject?.open_flow_rental_offer?.tenancy_start_date) {
      setTenanctStartDate(moment(modalGlobalObject?.open_flow_rental_offer?.tenancy_start_date).format('YYYY-MM-DD'));
    }
    if (modalGlobalObject?.open_flow_rental_offer?.rental_amount) {
      setRentalAmount(modalGlobalObject?.open_flow_rental_offer?.rental_amount);
    }
    if (modalGlobalObject?.payee_ref) {
      setPayeeRef(modalGlobalObject?.payee_ref);
    }

    if (holdingDepositeData?.openflow_bank_account_id) {
      const selectedAccount = bankAccounts?.find((val) => {
        if (val?.openflow_bank_account_id === holdingDepositeData?.openflow_bank_account_id
        ) {
          return val;
        }
      });
      setselectedAccount(selectedAccount);
      setAccountButtonText('Update Account');
      setAccountName(selectedAccount?.account_name);
      setAccount_id(selectedAccount?.account_id);
      setsortCode(selectedAccount?.sort_code);
    }
    if (holdingDepositeData?.holding_terms) {
      setHoldingTerms(holdingDepositeData?.holding_terms);
    }
    if (holdingDepositeData?.holding_desposit_amount) {
      setHolding_deposit_amount(holdingDepositeData?.holding_desposit_amount);
    }


  }, [
    // holdingDepositeData?.accounts,
    // holdingDepositeData?.holding_deposit_text,
    // holdingDepositeData?.movein_date,
    // holdingDepositeData?.account,
    holdingDepositeData?.openflow_bank_account_id,
    holdingDepositeData?.holding_terms_added,
    holdingDepositeData?.holding_terms,
    holdingDepositeData?.isHoldingDepositSelected,
    holdingDepositeData?.holding_desposit_amount,
    modalGlobalObject?.tenants,
    modalGlobalObject?.holding_deposit_collection_from_lead_tenant,
    modalGlobalObject?.open_flow_rental_offer && modalGlobalObject?.open_flow_rental_offer?.rental_amount,
    modalGlobalObject?.open_flow_rental_offer && modalGlobalObject?.open_flow_rental_offer?.tenancy_start_date,
    modalGlobalObject?.open_flow_rental_offer && modalGlobalObject?.open_flow_rental_offer?.rental_frequency,
  ]);


  const accountDetails = () => (
    <div>
      <>
        <SubHeading>Choose a branch</SubHeading>
        <Select
          value={selectedBranch}
          style={{ width: layoutType == 'phone' ? '100%' : '50%', height: '40px', borderWidth: 0 }}
          onChange={(e) => {
            setselectedBranch(e.target.value);
            // setnewAccountName(selectedAc.account_name);
            // setnewAccountSortCode(selectedAc.account_id);
            // setnewAccountNumber(selectedAc.sort_code);
            // setAccountButtonText('Update Account');
          }}
          variant="outlined"
          name="selectedBranch"
          placeholder="duration"
        >
          {branches?.map((data, i) => {
            return (
              <MenuItem value={data} key={'mi' + i}>
                {data?.name}
              </MenuItem>
            );
          })}
        </Select>
        <RowContainer>
          <FormContainer>
            <TextInput
              title={'Account Name'}
              value={newAccountName}
              containerStyles={{ width: '100%' }}
              onChange={(e) => setnewAccountName(e.target.value)}
            />
          </FormContainer>
        </RowContainer>
        <RowContainer>
          <FormContainer>
            <Otp
              title={'Sort Code'}
              no={6}
              value={newAccountSortCode}
              containerStyles={{ width: '100%' }}
              onChange={(e) => setnewAccountSortCode(e.target.value)}
            />
          </FormContainer>
          <FormContainer>
            <Otp
              value={newAccountNumber}
              title={'Account Number'}
              no={8}
              containerStyles={{ width: '100%' }}
              onChange={(e) => setnewAccountNumber(e.target.value)}
            />
          </FormContainer>
        </RowContainer>
      </>
      <RowContainer
        style={{
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginTop: 10,
        }}
      >
        <Button
          onClick={() => {
            // _onSave(false, false);
            _onAddNewBankAccount()
            setAccountButtonText('Update Account');
          }}
          style={{
            marginTop: '10px',
            borderColor: variables.purple1,
          }}
          title={accountButtonText}
          type="stripe"
        />
      </RowContainer>
    </div>
  );

  const handleRadioChange = (event) => {
    setHoldingDepositCollection(event.target.value === 'true');
  };

  const _onSave = (backNavigation, isRequest, data) => {
    setIsValidated(true);

    let payload = {
      holding_terms_added: holding_terms_added,
      openflow_bank_account_id: selectedAccount?.openflow_bank_account_id,
      tenancy_start_date,
      rental_amount,
      rental_frequency,
      is_holding_deposit_selected: isHoldingDepositSelected,
      payee_ref: payeeRef,
      holding_desposit_amount: holding_amount,
      account: {
        account_id: account_id,
        sort_code: sortCode,
        account_name: accountName,
      },
      holding_deposit_text: holding_terms_added ? holding_terms_added.toString() : "",
      holding_deposit_collection_from_lead_tenant: holdingDepositCollection,
      flow_id: modalGlobalObject?.openflow_id,
      collectDeposit: collectDeposit,
      ...data,
    };

    if (modalGlobalObject?.open_flow_rental_offer?.tenancy_start_date) {
      dispatch(
        tenantActions.saveHoldingDeposit(
          payload,
          modalGlobalObject?.openflow_id,
          // isRequest,
          backNavigation,
          selectedAccount?.openflow_bank_account_id
        )
      );
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      showErrorToast('Please select all mandatory fields')
    }
    setAccountModal(false);
  };

  const _onAddNewBankAccount = (data) => {

    let payload = {
      "sort_code": newAccountSortCode,
      "account_id": newAccountNumber,
      "account_name": newAccountName,
      "branch_id": selectedBranch?.branch_id,
      "branch": selectedBranch,
      "swift_code": "",
      "iban": "",
    }

    if (newAccountSortCode && newAccountNumber && newAccountName && selectedBranch?.branch_id) {
      dispatch(tenantActions.saveBankAccount(payload));
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      showErrorToast('Please select all mandatory fields')
    }
    setAccountModal(false);
  };

  const _confirmReceiptAll = () => {
    let arr = [];
    modalGlobalObject?.tenants?.forEach((data) => {
      arr.push(data?.open_flow_sub_tenant_id);
    });

    _confirmReceipt({flow_id: flowId, open_flow_sub_tenant_id: arr});
  };

  const _confirmReceipt = (data) => {
    dispatch(tenantActions.confirmDeposit(data, flowId));
  };

  const _revokeConfirmReceipt = (data) => {
    dispatch(tenantActions.revokeConfirmationDeposit(data, flowId));
  };

  const getCheckedStatus = () => {
    if (modalGlobalObject?.tenants && modalGlobalObject?.tenants.length) {
      let isChecked = true;
      for (let i = 0; i < modalGlobalObject?.tenants.length; i++) {
        if (modalGlobalObject?.tenants[i]?.deposit_payment_status != 3) {
          isChecked = false;
          break;
        }
      }
      return isChecked;
    }
    return false;
  };

  useEffect(() => {
    if (modalGlobalObject?.open_flow_rental_offer?.rental_frequency && modalGlobalObject?.open_flow_rental_offer?.rental_amount && !holding_amount) {
      setHolding_deposit_amount(getHoldingDeposite(modalGlobalObject?.open_flow_rental_offer?.rental_amount, modalGlobalObject?.open_flow_rental_offer?.rental_frequency));
    }
  }, [modalGlobalObject?.open_flow_rental_offer?.rental_frequency, modalGlobalObject?.open_flow_rental_offer?.rental_amount, holding_amount]);

  return (
    <Container>
      <CardWrapper>
        <ListItem
          type={'complete'}
          title={'Holding Deposit'}
          description={`Paying a holding deposit indicates the tenant’s intention to accept the terms and move in on a specified date.`}
          showPropertyCard
        // icon={HOLDING_ICON}
        />

        <BackComponent
          id={flowId}
          isComplete={modalGlobalObject?.holding_deposit_status === 1 ? true : false}
          flowtype={'holding_deposit_status'}
        />

        <React.Fragment>
          <SubHeading>Holding Deposit Collection</SubHeading>
          <CardBox hasShadow hasBorderRadius style={{ position: 'relative' }} style={{ width: '100%' }}>
            {/* <ToolTip
              style={{ top: '0%', right: '-40%' }}
              heading={'Holding Deposit Collection'}
              ContentData={[
                `TIP 1`,
                `Holding depsoit is usually collected from only the Lead tenant`,
                'TIP 2',
                'For AST tenancies, the Tenant Fee Act stipulates that you may take a MAXIMUM of one weeks rent for holding deposit',
              ]}
            /> */}
            <SubHeading>
              There are two methods to collect the holding deposit. <br />
              <br />
              You can either collect it:
              <br />
              1) ONE single amount from the lead tenant <br />
              2) Equal amounts, split between all tenants
              <br /> <br />
              How would you like to collect the deposit?
            </SubHeading>

            <FormControl component="fieldset">
              <RadioGroup
                aria-label="isHoldingDepositSelected"
                name="isHoldingDepositSelected"
                value={collectDeposit}
                onChange={(e) => {
                  if (e?.target?.value === 'true') {
                    setcollectDeposit(true);
                  } else {
                    setcollectDeposit(false);
                  }
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    name='holding_deposit_collection_from_lead_tenant'
                    value={true}
                    control={<Radio style={{ color: variables.heliotrope }} />}
                    style={{ color: 'black' }}
                    label="All from lead tenant"
                    checked={holdingDepositCollection === true}
                    onChange={handleRadioChange}
                  />
                  <FormControlLabel
                    name='holding_deposit_collection_from_lead_tenant'
                    value={false}
                    control={<Radio style={{ color: variables.heliotrope }} />}
                    style={{ color: 'black' }}
                    label="Equally between all tenants"
                    checked={holdingDepositCollection === false}
                    onChange={handleRadioChange}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </CardBox>

          <SubHeading>Holding Deposit Amount</SubHeading>

          <TlyfeSelector style={{ width: '100%', marginTop: 10 }}>
            <div style={{ flex: 0.7, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <SubHeading>Will you be requesting holding deposit?</SubHeading>
            </div>
            <div
              style={{
                flex: 0.3,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="isHoldingDepositSelected"
                  name="isHoldingDepositSelected"
                  value={isHoldingDepositSelected}
                  onChange={(e) => {
                    if (e?.target?.value === 'true') {
                      setHoldingDepositSelected(true);
                    } else {
                      setHoldingDepositSelected(false);
                    }
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FormControlLabel
                      value={true}
                      control={<Radio style={{ color: variables.heliotrope }} />}
                      style={{ color: 'black' }}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio style={{ color: variables.heliotrope }} />}
                      style={{ color: 'black' }}
                      label="No"
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          </TlyfeSelector>

          {isHoldingDepositSelected ? (
            <>
              <CardBox hasShadow hasBorderRadius style={{ position: 'relative' }} style={{ width: '100%' }}>
                {/* <ToolTip
                  style={{ top: '0%', right: '-40%' }}
                  heading={'Holding Deposit Amount'}
                  ContentData={[
                    'Tip 1',
                    'The Holding Deposit should be held for up to 15 days or until the agreed start date, whichever is later. ',
                    'TIP 2',
                    'Accepting a Holding Deposit means you must cease the marketing of the property with immediate effect.',
                  ]}
                /> */}
                <FormContainer>
                  <RowContainer>
                    <TextInput
                      title={'Rental amount (£)'}
                      isfullwidth={true}
                      type="number"
                      disabled={modalGlobalObject?.open_flow_rental_offer?.rental_amount || isHoldingDepositSelected ? false : true}
                      value={rental_amount}
                      wrapperstyle={{ width: layoutType == 'phone' ? '100%' : '50%', marginBottom: 15 }}
                      onChange={(e) => {
                        setHolding_deposit_amount(getHoldingDeposite(e?.target?.value, rental_frequency));
                        setRentalAmount(e?.target?.value);
                      }}
                      name="rental_amount"
                    />
                    <div
                      style={{
                        display: 'flex',
                        width: layoutType == 'phone' ? '100%' : '40%',
                        marginLeft: '2%',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        alignContent: 'flex-start',
                      }}
                    >
                      <SubHeading style={{ fontSize: 14, width: '100%' }}>Frequency</SubHeading>
                      <Select
                        disabled={modalGlobalObject?.open_flow_rental_offer?.rental_frequency || isHoldingDepositSelected ? false : true}
                        value={rental_frequency}
                        className="form-control"
                        onChange={(e) => {
                          setHolding_deposit_amount(getHoldingDeposite(rental_amount, e?.target?.value));
                          setRentalFrequency(e?.target?.value);
                        }}
                        variant="outlined"
                        style={{ width: '100%', height: '42px', marginTop: 8 }}
                        name="rental_frequency"
                        placeholder="duration"
                      >
                        <MenuItem value={'week'}>Weekly</MenuItem>
                        <MenuItem value={'monthly'}>Per Calender Month</MenuItem>
                        <MenuItem value={'quarterly'}>Quarterly</MenuItem>
                        <MenuItem value={'6 monthly'}>6 Monthly</MenuItem>
                        <MenuItem value={'annually'}>Annually</MenuItem>
                      </Select>
                    </div>
                  </RowContainer>
                </FormContainer>
                <RowContainer>
                  <TextInput
                    value={holding_amount}
                    name="holding_amount"
                    onChange={(e) => setHolding_deposit_amount(e.target.value)}
                    title={'Holding Deposit amount (£)'}
                    wrapperstyle={{ width: layoutType == 'phone' ? '100%' : '50%' }}
                  />
                  <TextInput
                    title={'Tenancy start date'}
                    isfullwidth={true}
                    type={'date'}
                    disabled={modalGlobalObject?.open_flow_rental_offer?.tenancy_start_date || isHoldingDepositSelected ? false : true}
                    value={tenancy_start_date}
                    inputProps={{ min: moment(new Date()).format('YYYY-MM-DD') }}
                    wrapperstyle={{ width: layoutType == 'phone' ? '100%' : '38%', marginLeft: '2%' }}
                    onChange={(e) => setTenanctStartDate(e.target.value)}
                    name="tenancy_start_date"
                    error={tenancy_start_date ? false : isValidated ? true : false}
                  />
                </RowContainer>
              </CardBox>

              <SubHeading style={{ marginTop: 50 }}>Holding Deposit Terms</SubHeading>

              <CardBox hasShadow hasBorderRadius style={{ position: 'relative', width: '100%' }}>
                {/* <ToolTip
              style={{ top: "0%", right: "-40%" }}
              heading={"Top Tip"}
              ContentData={[
                "Tip 1 - The Holding Deposit will be held for 15 days or until the agreed start date, whichever is later.",
                "Tip 2 - Accepting a Holding Deposit means you must cease the marketing of the property with immediate effect.",
              ]}
            /> */}
                {/* <ToolTip
                  style={{ right: '-40%' }}
                  heading={'Holding Deposit Terms'}
                  ContentData={[
                    'You can add your own terms to the holding deposit terms such as instances where deposit would not be refunded.',
                  ]}
                /> */}
                <TextBox>{holding_terms}</TextBox>
                <div style={{ marginRight: '15px', padding: '15px' }}>
                  <SubHeading>Additional Deposit Terms</SubHeading>
                  <TextInput
                    multiline
                    rows={3}
                    value={holding_terms_added || ''}
                    containerstyles={{ width: '100%', height: '90px', padding: 5 }}
                    onChange={(e) => setHoldingTermsAdded(e.target.value)}
                  />
                </div>
              </CardBox>
              <SubHeading>Account Details</SubHeading>
              <CardBox hasShadow hasBorderRadius style={{ position: 'relative', width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '5%',
                  }}
                >
                  {/* <ToolTip
                    style={{ right: '-40%' }}
                    heading={'Account Tip'}
                    ContentData={[
                      "Tip 1 – Click [+] to add bank new account details (if you've been given permission)",
                    ]}
                  /> */}
                  <Select
                    value={selectedAccount}
                    style={{ width: layoutType == 'phone' ? '100%' : '50%', height: '40px', borderWidth: 0 }}
                    onChange={(e) => {
                      const selectedAc = e.target.value;
                      setselectedAccount(selectedAc);
                      setAccountName(selectedAc.account_name);
                      setAccount_id(selectedAc.account_id);
                      setsortCode(selectedAc.sort_code);
                      setAccountButtonText('Update Account');
                    }}
                    variant="outlined"
                    name="selectedAccount"
                    placeholder="duration"
                  >
                    {bankAccounts?.map((data, i) => {
                      return (
                        <MenuItem value={data} key={'mi' + i}>
                          {data?.account_name}
                        </MenuItem>
                      );
                    })}
                  </Select>


                  <AddCircleOutlineOutlinedIcon
                    onClick={() => {
                      setselectedAccount(null);
                      setAccountButtonText('Add Account');
                      setNewAccAdded(true);
                      setAccount_id('');
                      setPayeeRef('');
                      setAccountName('');
                      setsortCode('');
                      setAccountModal(true);
                    }}
                    style={{
                      marginLeft: '10px',
                      fontSize: '30px',
                      color: variables.purple1,
                    }}
                  />

                </div>

                <>
                  <RowContainer>

                    <FormContainer>
                      <TextInput
                        title={'Account Name'}
                        value={accountName}
                        containerstyles={{ width: '100%' }}
                        onChange={(e) => setAccountName(e.target.value)}
                      />
                    </FormContainer>
                  </RowContainer>
                  <RowContainer>
                    <FormContainer>
                      <Otp
                        title={'Sort Code'}
                        no={6}
                        value={sortCode}
                        containerstyles={{ width: '100%' }}
                        onChange={(e) => setsortCode(e.target.value)}
                        disable={
                          userData?.user_profile?.agent?.can_add_bank_accounts ||
                            userData?.user_profile?.agent?.is_company_admin ||
                            userData?.user_profile?.agent?.is_branch_admin
                            ? false
                            : true
                        }
                      />
                    </FormContainer>
                    <FormContainer>
                      <Otp
                        value={account_id}
                        title={'Account Number'}
                        no={8}
                        containerstyles={{ width: '100%' }}
                        onChange={(e) => setAccount_id(e.target.value)}
                        disable={
                          userData?.user_profile?.agent?.can_add_bank_accounts ||
                            userData?.user_profile?.agent?.is_company_admin ||
                            userData?.user_profile?.agent?.is_branch_admin
                            ? false
                            : true
                        }
                      />
                    </FormContainer>
                  </RowContainer>
                </>
                {accountButtonText == 'Update Account' && (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      onClick={() => _onSave(false, false)}
                      style={{
                        marginTop: '10px',
                        borderColor: variables.purple1,
                        // color: "#FC4850",
                      }}
                      title={accountButtonText}
                      type="stripe"
                    />
                  </div>
                )}
              </CardBox>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '20px',
                }}
              >
                <CardBox hasShadow hasBorderRadius style={{ width: '100%' }}>
                  <FormContainer>
                    <TextInput
                      value={payeeRef}
                      title={'Payee Ref'}
                      isfullwidth
                      containerstyles={{ width: '100%' }}
                      onChange={(e) => setPayeeRef(e.target.value)}
                    // error={!payeeRef}
                    />
                  </FormContainer>
                </CardBox>
              </div>
              <LineSeperator />

              {modalGlobalObject?.tenants && modalGlobalObject?.tenants?.length ? (
                <>
                  <TlyfeSelector style={{ marginTop: 0, justifyContent: 'space-between' }}>
                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        position: 'relative',
                      }}
                    >
                      <Heading>Holding Deposit Received</Heading>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <FormControl component="fieldset">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={getCheckedStatus()}
                              style={{ color: variables.heliotrope }}
                              onChange={() => _confirmReceiptAll()}
                              name="isReferencingComplete"
                            />
                          }
                          label="Confirm All"
                          style={{ color: '#000', fontSize: 10 }}
                        />
                      </FormControl>
                    </div>
                  </TlyfeSelector>

                  {modalGlobalObject?.tenants?.map((data, index) => {
                    return (
                      <CardBox
                        style={{ marginBottom: '5%', position: 'relative', width: '100%' }}
                        hasShadow
                        hasBorderRadius
                        key={'cb' + index}
                      >
                        {/* {index === 0 ? (
                          <ToolTip
                            style={{ top: '0%', right: '-40%' }}
                            heading={'Holding Deposit - Receipt'}
                            ContentData={[
                              'Following receipt of the holding deposit, the property must be taken off the market.',
                            ]}
                          />
                        ) : null} */}
                        <RowContainer>
                          <div
                            style={{
                              flex: 0.7,
                              paddingRight: '10%',
                              paddingLeft: '4%',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <CardBox
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                border:
                                  '1px solid ' +
                                  getHoldingDepositStatusData1(data?.deposit_payment_status)?.borderColor,
                              }}
                              hasBorderRadius
                              backColor={getHoldingDepositStatusData1(data?.deposit_payment_status).backgroundColor}
                            >
                              <SubHeading style={{ margin: 0 }}>
                                {data?.app_user?.user?.first_name + ' ' + data?.app_user?.user?.last_name}
                              </SubHeading>

                              <SubHeading
                                style={{
                                  margin: 0,
                                  color: getHoldingDepositStatusData1(data?.deposit_payment_status).color,
                                }}
                              >
                                {getHoldingDepositStatusData1(data?.deposit_payment_status).DateText}{' '}
                                {getHoldingDepositStatusData1(data?.deposit_payment_status, data).Date}
                              </SubHeading>

                              <SubHeading
                                style={{
                                  margin: 0,
                                  color: getHoldingDepositStatusData1(data?.deposit_payment_status).color,
                                }}
                              >
                                {getHoldingDepositStatusData1(data?.deposit_payment_status).Text}
                              </SubHeading>
                            </CardBox>
                          </div>
                        </RowContainer>
                        <RowContainer>
                          <div
                            style={{
                              flex: 0.3,
                              paddingRight: '10%',
                              paddingLeft: '4%',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <TextInput
                              disabled={true}
                              value={
                                holding_amount
                                  ? collectDeposit
                                    ? data?.is_lead
                                      ? holding_amount
                                      : 0
                                    : (holding_amount / modalGlobalObject?.tenants?.length).toFixed(2)
                                  : 'N/A'
                              }
                              title={'Holding Deposit amount (£)'}
                              containerstyles={{ width: '100%' }}
                            />
                          </div>
                          <div style={{ flex: 0.3 }}></div>
                          <div
                            style={{
                              flex: 0.4,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <TlyfeButtonWrapper>
                              <Button
                                onClick={() =>
                                  data?.deposit_payment_status != 3 ?
                                    _confirmReceipt({
                                      flow_id: data?.openflow_id,
                                      open_flow_sub_tenant_id: [data?.open_flow_sub_tenant_id],
                                    })
                                    :
                                    _revokeConfirmReceipt({
                                      flow_id: data?.openflow_id,
                                      open_flow_sub_tenant_id: [data?.open_flow_sub_tenant_id],
                                    })
                                }
                                style={{
                                  marginTop: '15px',
                                  borderColor: '#90C418',
                                  backgroundColor:
                                    data.deposit_payment_status != 3 ? 'tranparent' : variables?.puregreen,
                                  color: data.deposit_payment_status != 3 ? 'black' : 'white',
                                }}
                                title={data.deposit_payment_status != 3 ? 'Confirm Receipt' : 'Revoke Confirmation'}
                                type={data.deposit_payment_status != 3 ? 'stripe' : 'custom'}
                              />
                            </TlyfeButtonWrapper>
                          </div>
                          {/*
                           */}
                        </RowContainer>
                      </CardBox>
                    );
                  })}
                </>
              ) : null}
            </>
          ) : null}
          {/* <BottomButton
            buttonText={modalState?.send_to_tenant ? 'Update and Send' : 'Save and Send'}
            onRightClick={() =>
              modalState?.send_to_tenant
                ? _onSave(true, false, { send_to_tenant: true, requestAgain: true })
                : setSuccessModal(true)
            }
            onContinue={() => _onSave(true, false, { send_to_tenant: false, requestAgain: false })}
          /> */}

          <BottomButton
            buttonText={modalState?.send_to_tenant ? 'Update and Send' : 'Save and Send'}
            onRightClick={() =>
              _onSave(null, { send_to_tenant: true, requestAgain: true })
            }
            onContinue={() => _onSave(null, { send_to_tenant: false, requestAgain: false })}
          />
        </React.Fragment>
      </CardWrapper>
      {openAccountModal && (
        <OpenFlowModal
          showModal={openAccountModal}
          setShowModal={setAccountModal}
          modalBody={accountDetails()}
          showFooter={false}
          modalHeading=""
          modalWidth={600}
        />
      )}
      <SaveandContinueModal
        modalState
        heading={'Holding Deposit'}
        subHeading={[
          `Do you wish to send this holding deposit request to  your tenant(s)?`,
          `Remember,  if you don't toggle to "send", your tenant(s) will not receive the holding  deposit request.`,
        ]}
        onCancelPress={() => setSuccessModal(false)}
        onOkPress={(data) => {
          data.requestAgain = true;
          _onSave(true, false, data);
        }}
        visible={showSuccessModal}
      />
    </Container>
  );
};

export default drawerHoc({
  Component: HoldingDeposit,
  showBackButton: true,
});
