import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { MaterialIcons, FontAwesome } from '@expo/vector-icons';

const CertList = (props) => {
let {certificate,certificatesItem,handleDelete,key} =props
  return (
    <View 
    key={key}
    style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={styles.title}>{certificate.name}</Text>
        <View style={ certificatesItem?.is_seen? styles.badge:styles.badgeNotseen} >
          <Text style={[styles.badgeText,{color:certificatesItem?.is_seen? "green":"red" }]}>{certificatesItem?.is_seen? "Seen":'Not Seen'}</Text>
        </View>
      </View>
      <View style={styles.iconsContainer}>
        <TouchableOpacity onPress={()=> {window.open(certificatesItem.certificate_url, "_blank") }} >
          <FontAwesome name="eye" size={24} color="blue" />
        </TouchableOpacity>
        <TouchableOpacity onPress={()=> handleDelete()}>
          <MaterialIcons name="delete" size={24} color="red" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 16,
    marginBottom: 8,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    elevation: 3,
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex:0.8
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
    marginRight: 8,
  },
  badge: {
    backgroundColor: '#d4f8d4',
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 12,
  },
  badgeNotseen: {
    backgroundColor: '#rgba(255, 86, 72, 0.12)',
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 12,
  },
  badgeText: {
    color: 'green',
    fontWeight: 'bold',
  },
  iconsContainer: {
    flexDirection: 'row',
    gap: 16,
    flex:0.2,justifyContent:"space-between",alignItems:"flex-end"
  },
});

export default CertList;
