import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import AntDesign from '@expo/vector-icons/AntDesign';
import { navigate } from '../../../../navigation/RootNavigation';

const Welcome = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.statsContainer}>
        <StatCard label="Available Flo's" value="11" color="#FEE2E2" icon="creditcard" />
        <StatCard label="Used Flo's" value="19" color="#DBEAFE" icon="checksquareo" />
        <StatCard label="Current Flo's" value="17" color="#FEF3C7" icon="clockcircleo" />
        <StatCard label="Completed Flo's" value="1" color="#D1FAE5" icon="checkcircleo" />
      </View>

      <NotificationCard 
        title="FCC Account" 
        message="Currently you are using Openbrix FCC Account. If you are already an FCC paragon customer, Please edit settings and add your customer information." 
      />
      <NotificationCard 
        title="Email Notifications" 
        message="If you wish to receive a copy of emails sent to tenants, Please edit settings and add your email information." 
      />

      <View style={styles.welcomeContainer}>
        <Text style={styles.welcomeTitle}>Welcome to the tlyfePro !</Text>
        <Text style={styles.welcomeText}>
          tlyfePro allows you to onboard tenants and interact in real-time with tlyfe, the UK's first and most popular tenant lifecycle app.
        </Text>
        <Text style={styles.welcomeText}>
          The interaction with tlyfe means you can verify ID, confirm Right to Rent, and receive a comprehensive reference, including AML and fraud checks, supported by a Rental Warranty.
        </Text>
        <Text style={styles.welcomeText}>
          You can prove compliance by sending key documents through tlyfePro such as the How to Rent Guide, EPC, Gas Cert, Prescribed Information, and retain a log of when the tenant received it!
        </Text>
        <View style={styles.buttonGroup}>
          <Button label="BUY MORE" color="#A78BFA" />
          <Button label="SETTINGS" color="#3B82F6" />
          <Button showIcon label="START NEW FLO" color="#10B981" />
          <Button label="TLYFEPRO TERMS & CONDITIONS" color="#E5E7EB" textColor="#6B7280" />
        </View>
      </View>
    </ScrollView>
  );
};

const StatCard = ({ label, value, color, icon }) => (
  <View style={[styles.statCard, { backgroundColor: color }]}> 
    <AntDesign name={icon} size={24} color="#111827" style={styles.statIcon} />
    <Text style={styles.statValue}>{value}</Text>
    <Text style={styles.statLabel}>{label}</Text>
  </View>
);

const NotificationCard = ({ title, message }) => (
  <View style={styles.notificationCard}>
    <Text style={styles.notificationTitle}>{title}</Text>
    <Text style={styles.notificationMessage}>{message}</Text>
  </View>
);

const Button = ({ label, color, textColor = '#FFFFFF', showIcon }) => (
  <TouchableOpacity 
    onPress={() => navigate("addproperty")}
    style={[styles.button, { backgroundColor: color }]}>
    {showIcon && <AntDesign name="plus" size={20} color="white" style={{ marginRight: 6 }} />}
    <Text style={[styles.buttonText, { color: textColor }]}>{label}</Text>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: '#F9FAFB',
    marginVertical:30,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 3,
    marginBottom: 20,
  },
  statsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  statCard: {
    width: '48%',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginBottom:10
  },
  statIcon: {
    marginBottom: 8,
  },
  statValue: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#374151',
  },
  statLabel: {
    fontSize: 14,
    color: '#1F2937',
  },
  notificationCard: {
    backgroundColor: '#FEF3C7',
    padding: 15,
    borderRadius: 10,
    marginBottom: 20,
  },
  notificationTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#92400E',
    marginBottom: 10,
  },
  notificationMessage: {
    fontSize: 14,
    color: '#374151',
  },
  welcomeContainer: {
    backgroundColor: '#FFFFFF',
    padding: 20,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 3,
    marginBottom: 20,
    marginTop:20
  },
  welcomeTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#7C3AED',
    marginBottom: 10,
  },
  welcomeText: {
    fontSize: 15,
    marginBottom: 10,
    color: '#374151',
  },
  buttonGroup: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginTop: 15,
  },
  button: {
    paddingVertical: 12,
    paddingHorizontal: 18,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default Welcome;
