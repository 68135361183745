import { combineReducers } from 'redux';
import { AppReducer } from './AppReducer';
import { Login } from './Login';
import cookiesReducer from './cookiesReducer'
import { tenant } from '../../screens/tenants/redux/tenant.reducer';

export default combineReducers({
  Login,
  AppReducer,
  cookiesReducer,
  tenant
});
