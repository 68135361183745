import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Entypo from '@expo/vector-icons/Entypo';


import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { LineSaperator } from '../../../../components/calendarList/CalendarList.style';
import AntDesign from '@expo/vector-icons/AntDesign';
import { useDispatch, useSelector } from 'react-redux';
import { tenantActions } from '../../redux/tenant.actions';
import AffPopups from './modals/affPopups';
import moment from 'moment';
import { Button } from '../atoms/button';
import { showErrorToast, showSuccesToast } from '../helpers';
import GuarantorForm from './modals/gurantorPopu';
import ReferencePopUp from './modals/refrecPopup';

const IdBox = ({ props }) => {


    const renderAff = (FccRentAffordibilityChecks) => {
        let tenantAffordibility1 = null
        if (FccRentAffordibilityChecks) {
            if (FccRentAffordibilityChecks && FccRentAffordibilityChecks?.length > 0) {
                tenantAffordibility1 = FccRentAffordibilityChecks.find((fccRentAffordibilityCheck) => fccRentAffordibilityCheck?.guarantor == false)
            }
        }
        return (
            <View style={styles.section}>
                <View style={styles.sectionHeader}>
                    <Text style={styles.sectionTitle}>Affordability</Text>
                    <View style={styles.buttonGroup}>
                        {tenantAffordibility1 ? <Text style={styles.green}>Completed</Text> : <Text style={styles.notcompleted}>Not Started</Text>}
                    </View>
                </View>
                <View style={styles.documentBox}>
                    <Text style={styles.sectionTitle2}>Tenant Affordability</Text>
                    {tenantAffordibility1 ? <TouchableOpacity onPress={() => {
                        setShowModalAff({ show: true, data: tenantAffordibility1 })
                    }}>
                        <Text style={styles.green}>VIEW</Text>
                    </TouchableOpacity> : null}
                </View>

            </View>
        )
    }
    const renderGAff = (FccRentAffordibilityChecks) => {
        let tenantAffordibility1 = null
        if (FccRentAffordibilityChecks) {
            if (FccRentAffordibilityChecks && FccRentAffordibilityChecks?.length > 0) {
                tenantAffordibility1 = FccRentAffordibilityChecks.find((fccRentAffordibilityCheck) => fccRentAffordibilityCheck?.guarantor == true)
            }
        }
        return (
            <View style={styles.section}>
                <View style={styles.sectionHeader}>
                    <Text style={styles.sectionTitle}>Guarantor Affordability</Text>
                    <View style={styles.buttonGroup}>
                        {tenantAffordibility1 ? <Text style={styles.green}>Completed</Text> : <Text style={styles.notcompleted}>Not Started</Text>}
                    </View>
                </View>
                <View style={styles.documentBox}>
                    <Text style={styles.sectionTitle2}>Guarantor  Affordability</Text>
                    {tenantAffordibility1 ? <TouchableOpacity onPress={() => {
                        setShowModalAff({ show: true, data: tenantAffordibility1 })
                    }}>
                        <Text style={styles.green}>VIEW</Text>
                    </TouchableOpacity> : null}
                </View>

            </View>
        )
    }
    const renderIDVerification = (data) => {
        console.log("renderRtRc", data)
        return (
            <View style={styles.section}>
                <View style={styles.sectionHeader}>
                    <Text style={styles.sectionTitle}>Right To Rent</Text>
                    <View style={styles.buttonGroup}>

                        {
                            data?.right_to_rent_approved ?
                                <Text style={styles.green}>Approved</Text> :
                                data?.right_to_rent && data?.right_to_rent_dob ?
                                    <Text style={styles.requested}>Submitted By Tenant</Text>
                                    :
                                    data?.right_to_rent_requested ?
                                        <Text style={styles.requested}>Requested</Text>
                                        :
                                        <Text style={styles.notcompleted}>Not Started</Text>
                        }


                        <Button
                            type="Upload"
                            title="Upload"
                            style={{ width: 70, height: 20 }}
                            onClick={(IMAGEDATA) => {
                                if (IMAGEDATA && IMAGEDATA?.data?.length) {

                                    if (IMAGEDATA && IMAGEDATA.data.length) {
                                        dispatch(tenantActions.getVerificationReferencing({
                                            document_url: IMAGEDATA?.data[0],
                                            flow_id: flowId,
                                            open_flow_sub_tenant_id: data?.open_flow_sub_tenant_id,
                                            type_of_document: "right_to_rent"
                                        }, () => {
                                            fetchFlows()
                                            showSuccesToast('Documnet uploaded successfully!')
                                        }))
                                    }

                                }
                            }}
                        />

                        {data?.right_to_rent_approved == false && (!data?.right_to_rent && !data?.right_to_rent_dob) ?
                            <TouchableOpacity
                                onPress={() => {
                                    dispatch(tenantActions.rtrReminder({ "app_user_id": data?.app_user_id }, flowId, () => {
                                        showSuccesToast('Right to rent reminder sent successfully!')
                                    }))
                                }}
                                style={styles.nudgeButton}><Text style={styles.buttonText}>NUDGE</Text></TouchableOpacity>
                            : null}
                    </View>
                </View>
                <View style={styles.section}>
                    <Text><Text style={styles.bold}>Code:</Text> {data?.right_to_rent ? data?.right_to_rent : "N/A"}</Text>
                    <Text style={{ marginTop: 8 }}><Text style={styles.bold}>Birth Date:</Text>{data?.right_to_rent_dob ? formatDate(data?.right_to_rent_dob) : "N/A"}</Text>
                </View>
                {data?.right_to_rent_document && data?.right_to_rent_document?.length ?
                    data.right_to_rent_document.map((item, index) => {
                        return (
                            <View key={data?.document_url} style={styles.documentItem}>
                                <Text style={styles.sectionTitle3}>Document {index + 1}</Text>
                                <View style={styles.documentActions}>
                                    <Text style={styles.date}>{formatDate(item.updatedAt)}</Text>
                                    <TouchableOpacity onPress={() => {
                                        if (item?.document_url)
                                            window.open(item.document_url, '_blank');
                                    }}>
                                        <AntDesign name={"eye"} size={30} color="#09b2c2" />

                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            dispatch(tenantActions.deleteRtrDoc({
                                                document_url: item?.document_url,
                                                flow_id: flowId,
                                                open_flow_sub_tenant_id: data?.open_flow_sub_tenant_id,
                                                type_of_document: "right_to_rent"
                                            }, () => {
                                                fetchFlows()
                                                showSuccesToast('Documnet deleted successfully!')
                                            }))
                                        }}
                                    >
                                        <MaterialIcons name="delete" size={24} color="#E53E3E" />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        )
                    }) : null
                }
            </View>
        )
    }
    const formatDate = (date) => moment(date).format('DD/MM/YYYY')
    const renderRtRc = (data) => {
        console.log("renderRtRc", data)
        return (
            <View style={styles.section}>
                <View style={styles.sectionHeader}>
                    <Text style={styles.sectionTitle}>Right To Rent</Text>
                    <View style={styles.buttonGroup}>

                        {
                            data.right_to_rent_approved ?
                                <Text style={styles.green}>Approved</Text> :
                                data.right_to_rent && data.right_to_rent_dob ?
                                    <Text style={styles.requested}>Submitted By Tenant</Text>
                                    :
                                    data.right_to_rent_requested ?
                                        <Text style={styles.requested}>Requested</Text>
                                        :
                                        <Text style={styles.notcompleted}>Not Started</Text>
                        }


                        <Button
                            type="Upload"
                            title="Upload"
                            style={{ width: 70, height: 20 }}
                            onClick={(IMAGEDATA) => {
                                if (IMAGEDATA && IMAGEDATA?.data?.length) {

                                    if (IMAGEDATA && IMAGEDATA.data.length) {
                                        dispatch(tenantActions.getVerificationReferencing({
                                            document_url: IMAGEDATA?.data[0],
                                            flow_id: flowId,
                                            open_flow_sub_tenant_id: data?.open_flow_sub_tenant_id,
                                            type_of_document: "right_to_rent"
                                        }, () => {
                                            fetchFlows()
                                            showSuccesToast('Documnet uploaded successfully!')
                                        }))
                                    }

                                }
                            }}
                        />

                        {data.right_to_rent_approved == false && (!data.right_to_rent && !data.right_to_rent_dob) ?
                            <TouchableOpacity
                                onPress={() => {
                                    dispatch(tenantActions.rtrReminder({ "app_user_id": data.app_user_id }, flowId, () => {
                                        showSuccesToast('Right to rent reminder sent successfully!')
                                    }))
                                }}
                                style={styles.nudgeButton}><Text style={styles.buttonText}>NUDGE</Text></TouchableOpacity>
                            : null}
                    </View>
                </View>
                <View style={styles.section}>
                    <Text><Text style={styles.bold}>Code:</Text> {data?.right_to_rent ? data?.right_to_rent : "N/A"}</Text>
                    <Text style={{ marginTop: 8 }}><Text style={styles.bold}>Birth Date:</Text>{data?.right_to_rent_dob ? formatDate(data?.right_to_rent_dob) : "N/A"}</Text>
                </View>
                {data?.right_to_rent_document && data?.right_to_rent_document?.length ?
                    data.right_to_rent_document.map((item, index) => {
                        return (
                            <View key={data?.document_url} style={styles.documentItem}>
                                <Text style={styles.sectionTitle3}>Document {index + 1}</Text>
                                <View style={styles.documentActions}>
                                    <Text style={styles.date}>{formatDate(item.updatedAt)}</Text>
                                    <TouchableOpacity onPress={() => {
                                        if (item?.document_url)
                                            window.open(item.document_url, '_blank');
                                    }}>
                                        <AntDesign name={"eye"} size={30} color="#09b2c2" />

                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            dispatch(tenantActions.deleteRtrDoc({
                                                document_url: item?.document_url,
                                                flow_id: flowId,
                                                open_flow_sub_tenant_id: data?.open_flow_sub_tenant_id,
                                                type_of_document: "right_to_rent"
                                            }, () => {
                                                fetchFlows()
                                                showSuccesToast('Documnet deleted successfully!')
                                            }))
                                        }}
                                    >
                                        <MaterialIcons name="delete" size={24} color="#E53E3E" />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        )
                    }) : null
                }
            </View>
        )
    }

    const renderRef = () => {
        return (
            <View style={styles.section}>
                <View style={styles.sectionHeader}>
                    <Text style={styles.sectionTitle}> Referencing</Text>
                    <View style={styles.buttonGroup}>
                        <Text style={styles.requested}>Requested</Text>
                        <TouchableOpacity style={styles.uploadButton}><Text style={styles.buttonText}>UPLOAD</Text></TouchableOpacity>
                        <TouchableOpacity style={styles.nudgeButton}><Text style={styles.buttonText}>NUDGE</Text></TouchableOpacity>
                    </View>
                </View>
                <View style={styles.documentBox}>
                    <Text style={styles.sectionTitle2}> Rent Ready Referencing  </Text>
                    <Text style={styles.requested}>Requested</Text>
                </View>
                <View style={styles.documentBox}>
                    <Text style={styles.sectionTitle3}>Tenant Referencing </Text>
                    <Text style={styles.requested}>Requested</Text>
                </View>
                <View style={styles.documentBox}>
                    <Text style={styles.sectionTitle3}>Reference Application </Text>
                    <Text style={styles.notcompleted}>Not Completed</Text>
                </View>
                <View style={styles.documentBox}>
                    <Text style={styles.sectionTitle3}>Application Link </Text>
                    <Text style={styles.green}>copy link</Text>
                </View>
                <View style={styles.documentItem}>
                    <Text style={styles.sectionTitle3}>Document 1</Text>
                    <View style={styles.documentActions}>
                        <Text style={styles.date}>25/02/2025</Text>
                        <TouchableOpacity>
                            <Entypo name="eye" size={24} color="#09b2c2" />
                        </TouchableOpacity>
                        <TouchableOpacity>
                            <MaterialIcons name="delete" size={24} color="#E53E3E" />
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.section}>
                    <Text><Text style={styles.bold}>Reference Handler:</Text> N/A</Text>
                    <Text><Text style={styles.bold}>Tel:</Text> N/A</Text>
                    <Text><Text style={styles.bold}>Ref:</Text>THREEEAHDAC22998</Text>
                </View>
            </View>
        )
    }
    const renderGRef = (data) => {
        return (
            <View style={styles.section}>
                <View style={styles.sectionHeader}>
                    <Text style={styles.sectionTitle}>Guarantor</Text>
                    <View style={styles.buttonGroup}>
                        <Button
                            type="Upload"
                            title="Upload"
                            style={{ width: 70, height: 20 }}
                            onClick={(IMAGEDATA) => {
                                if (IMAGEDATA && IMAGEDATA?.data?.length) {

                                    if (IMAGEDATA && IMAGEDATA.data.length) {
                                        dispatch(tenantActions.getVerificationReferencing({
                                            document_url: IMAGEDATA?.data[0],
                                            flow_id: flowId,
                                            open_flow_sub_tenant_id: data?.open_flow_sub_tenant_id,
                                            type_of_document: "guarantor_docs"
                                        }, () => {
                                            fetchFlows()
                                            showSuccesToast('Documnet uploaded successfully!')
                                        }))
                                    }

                                }
                            }}
                        />
                    </View>
                </View>
                {data?.open_flow_guarantor?.email ?
                    <>
                        <View style={styles.section}>
                            <View style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', marginBottom: 20 }}>
                                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: 'center' }}>
                                    <View style={styles.avatar}>
                                        <Text style={styles.avatarText}>{getInitials(data?.open_flow_guarantor?.first_name, data?.open_flow_guarantor?.last_name)}</Text>
                                    </View>
                                    <Text style={styles.title}>{data?.open_flow_guarantor?.first_name} {data?.open_flow_guarantor?.last_name} </Text>

                                </View>
                                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: 'center' }}>
                                    <TouchableOpacity onPress={() => {
                                        if (item?.document_url)
                                            window.open(item.document_url, '_blank');
                                    }}>
                                        <AntDesign name={"eye"} size={30} color="#09b2c2" />

                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            dispatch(tenantActions.deleteGau({
                                                flow_id: flowId,
                                                openflow_guarantor_id: data.open_flow_guarantor.open_flow_guarantor_id,
                                            }, () => {
                                                fetchFlows()
                                                showSuccesToast('Guarantor removed form flow successfully!')
                                            }))
                                        }}
                                    >
                                        <MaterialIcons name="delete" size={24} color="#E53E3E" />
                                    </TouchableOpacity>
                                </View>
                            </View>
                            <Text><Text style={styles.bold}>Email:</Text> {data?.open_flow_guarantor?.email ? data?.open_flow_guarantor?.email : "N/A"}</Text>
                            <Text style={{ marginTop: 8 }}><Text style={styles.bold}>Postcode:</Text>{data?.open_flow_guarantor?.post_code ? data?.open_flow_guarantor?.post_code : "N/A"}</Text>
                            <Text style={{ marginTop: 8 }}><Text style={styles.bold}>Contact Number :</Text>{data?.open_flow_guarantor?.number ? data?.open_flow_guarantor?.number : "N/A"}</Text>

                        </View>
                        <View style={styles.documentBox}>
                            <Text style={styles.sectionTitle2}> Guarantor Referencing  </Text>
                            <TouchableOpacity onPress={()=>{
                                setShowModalRef({show:true,
                                    data:{
                                guarantors:   [data?.open_flow_guarantor],
                                    digital_reference: false,
                                    tenants:[]
                                }})
                            }}>
                                  <Text style={styles.green}>Start Referencing</Text>
                            </TouchableOpacity>
                          
                        </View>
                    </>
                    :
                    <View style={styles.documentBox}>
                        <Text style={styles.sectionTitle2}>Guarantor  Information</Text>
                        <TouchableOpacity onPress={() => {
                            setShowModalGua({ show: true, data: { open_flow_sub_tenant_id: data.open_flow_sub_tenant_id } })
                        }}>
                            <Text style={styles.requested}>Add</Text>
                        </TouchableOpacity>
                    </View>}
                {data?.gua_document && data?.gua_document?.length ?
                    data.gua_document.map((item, index) => {
                        return (
                            <View key={data?.document_url} style={styles.documentItem}>
                                <Text style={styles.sectionTitle3}>Document {index + 1}</Text>
                                <View style={styles.documentActions}>
                                    <Text style={styles.date}>{formatDate(item.updatedAt)}</Text>
                                    <TouchableOpacity onPress={() => {
                                        if (item?.document_url)
                                            window.open(item.document_url, '_blank');
                                    }}>
                                        <AntDesign name={"eye"} size={30} color="#09b2c2" />

                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            dispatch(tenantActions.deleteRtrDoc({
                                                document_url: item?.document_url,
                                                flow_id: flowId,
                                                open_flow_sub_tenant_id: data?.open_flow_sub_tenant_id,
                                                type_of_document: "guarantor_docs"
                                            }, () => {
                                                fetchFlows()
                                                showSuccesToast('Documnet deleted successfully!')
                                            }))
                                        }}
                                    >
                                        <MaterialIcons name="delete" size={24} color="#E53E3E" />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        )
                    }) : null
                }
            </View>
        )
    }
    const [flowId, setFlowId] = React.useState(null);
    const [checkedValue, setCheckedValue] = useState("");
    const [flow, setFlow] = React.useState({});
    const [addedUsers, setAddedUsers] = React.useState([]);
    const [showModalAff, setShowModalAff] = React.useState({
        show: false,
        data: null
    });
    const [showModalGua, setShowModalGua] = React.useState({
        show: false,
        data: null
    });
    const [showModalRef, setShowModalRef] = React.useState({
        show: false,
        data: null
    });
    

    const dispatch = useDispatch()
    const globalState = useSelector((state) => state?.tenant.flowIdInfo);
    const tenantsArry = useSelector((state) => state?.tenant?.flowIdInfo?.tenants);
    useEffect(() => {
        console.log("proooooo", props)
        if (props?.route?.params?.id) {
            setFlowId(props?.route?.params?.id);
            fetchFlows()
        }
    }, [props?.route?.params?.id]);
    const fetchFlows = () => {
        dispatch(tenantActions.getFlowInfo(props?.route?.params?.id));
    }
    useEffect(() => {
        if (tenantsArry && tenantsArry?.length) {
            setAddedUsers(tenantsArry);
            setFlow(globalState);
        } else {
            setAddedUsers([]);
        }
    }, [tenantsArry]);

    function getInitials(firstName, lastName) {
        if (firstName && lastName) {
            return `${firstName[0]}${lastName[0]}`.toUpperCase();
        } else if (lastName) {
            return lastName.slice(0, 2).toUpperCase();
        }
        return ''; // Fallback for no names provided
    }
    const getRightToRentDocuments = (open_flow_verifications) => {
        const documentType = 'right_to_rent';
        if (open_flow_verifications && open_flow_verifications.length) {
            const rightToRentDocuments = open_flow_verifications.filter((document) => {
                return document?.type_of_document == documentType
            });

            return rightToRentDocuments
        } else {
            return []
        }
    }
    const getGuaToRentDocuments = (open_flow_verifications) => {
        const documentType = 'guarantor_docs';
        if (open_flow_verifications && open_flow_verifications.length) {
            const rightToRentDocuments = open_flow_verifications.filter((document) => {
                return document?.type_of_document == documentType
            });

            return rightToRentDocuments
        } else {
            return []
        }
    }
    return (
        <>
            {tenantsArry && tenantsArry.length ?
                tenantsArry.map((item, index) => {
                    console.log("itemitem", item)
                    return (

                        <View key={index} style={styles.container}>

                            <View style={styles.header}>
                                <View style={{ flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                                    <View style={styles.avatar}>
                                        <Text style={styles.avatarText}>{getInitials(item?.app_user?.user?.first_name, item?.app_user?.user?.last_name)}</Text>
                                    </View>
                                    <Text style={styles.title}>{item?.app_user?.user?.first_name} {item?.app_user?.user?.last_name} <Text style={styles.email}>({item?.app_user?.user?.email})</Text></Text>
                                </View>
                                <TouchableOpacity onPress={() => {
                                    if (checkedValue === item?.app_user_id)
                                        setCheckedValue("")
                                    else
                                        setCheckedValue(item?.app_user_id)
                                }}>
                                    <AntDesign name={checkedValue === item?.app_user_id ? "minus" : "plus"} size={30} color="black" />
                                </TouchableOpacity>
                            </View>

                            {checkedValue == item?.app_user_id ?
                                <>
                                    <LineSaperator style={{ marginTop: 20 }} />
                                    <View style={styles.sectionContainer}>
                                        {/* ID Verification Section */}
                                        {renderIDVerification()}

                                        {/* Right To Rent Section */}
                                        {renderRtRc({
                                            right_to_rent: item?.app_user?.right_to_rent,
                                            right_to_rent_approved: item?.app_user?.right_to_rent_approved,
                                            right_to_rent_dob: item?.app_user?.right_to_rent_dob,
                                            right_to_rent_document: getRightToRentDocuments(item?.open_flow_verifications),
                                            right_to_rent_expiry: item?.app_user?.right_to_rent_expiry,
                                            right_to_rent_requested: item?.app_user?.right_to_rent_requested,
                                            open_flow_sub_tenant_id: item?.open_flow_sub_tenant_id,
                                            app_user_id: item?.app_user_id
                                        })}
                                    </View>
                                    {/* Affordability Section */}
                                    <View style={styles.sectionContainer}>
                                        {renderAff(item?.app_user?.FccRentAffordibilityChecks)}
                                        {/* Guarantor Affordability Section */}
                                        {renderGAff(item?.app_user?.FccRentAffordibilityChecks)}
                                    </View>
                                    <View style={styles.sectionContainer}>
                                        {/* Referencing Section */}

                                        {renderRef()}
                                        {renderGRef({
                                            open_flow_guarantor: item?.open_flow_guarantor,
                                            open_flow_sub_tenant_id: item?.open_flow_sub_tenant_id,
                                            app_user_id: item?.app_user_id,
                                            gua_document: getGuaToRentDocuments(item?.open_flow_verifications),
                                        })}
                                    </View>
                                </> : null}
                        </View>
                    )
                }) : null
            }
            <AffPopups
                showModal={showModalAff.show}
                onClose={() => { setShowModalAff({ show: false, data: null }) }}
                data={showModalAff.data}
            />

            <GuarantorForm
                showModal={showModalGua.show}
                onClose={() => { setShowModalGua({ show: false, data: null }) }}
                data={showModalGua.data}
                onSave={(DATA) => {
                    let payload =
                    {
                        "email": DATA.email,
                        "title": DATA.title,
                        "number": DATA.contactNumber,
                        "last_name": DATA.lastName,
                        "post_code": DATA.postcode,
                        "first_name": DATA.firstName,
                        "correspondece_address": DATA.address,
                        "flow_id": flowId,
                        "open_flow_sub_tenant_id": showModalGua?.data?.open_flow_sub_tenant_id
                    }
                    dispatch(tenantActions.saveGurantorInfo(payload, () => {
                        fetchFlows()
                        showSuccesToast('Gurantor added successfully!');
                        setShowModalGua({ show: false, data: null })

                    }))
                }}


            />
            <ReferencePopUp
                showModal={showModalRef.show}
                onClose={() => { setShowModalRef({ show: false, data: null }) }}
                data={showModalRef.data}
                onSave={(DATA) => {
                    let payload =
                    {
                       ...showModalRef.data,
                        "flow_id": flowId,
                        "open_flow_sub_tenant_id": showModalGua?.data?.open_flow_sub_tenant_id,
                       "price":DATA.cost
                    }
                    dispatch(tenantActions.fullReferencing(payload, (data) => {
                        if(data.status){
                            fetchFlows()
                            showSuccesToast('Gurantor added successfully!');
                            setShowModalRef({ show: false, data: null })
                        }else{
                            showErrorToast(data?.message?data.message: 'Please try again!');
                            setShowModalRef({ show: false, data: null })
                        }
                        

                    }))
                }}


            />

        </>
    );

};

const styles = StyleSheet.create({
    container: {
        padding: 16,
        backgroundColor: '#fff',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 5,
        marginBottom: 30
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "space-between"
    },
    avatar: {
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: '#C6F6D5',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 12,
    },
    avatarText: {
        color: '#2F855A',
        fontWeight: 'bold',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    email: {
        color: '#718096',
    },
    sectionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    section: {
        flex: 1,
        padding: 16,
        borderRadius: 8,
        borderWidth: 2,
        borderColor: '#E2E8F0',
        marginRight: 8,
        marginTop: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 5,
    },
    sectionHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 12,
    },
    sectionTitle: {
        color: '#6B46C1',
        fontWeight: '600',
        fontSize: 20
    },
    sectionTitle2: {

        fontWeight: '600',
        fontSize: 18
    },
    sectionTitle3: {

        fontWeight: '600',
        fontSize: 16
    },
    buttonGroup: {
        flexDirection: 'row',
        gap: 8,
    },
    requested: {
        borderWidth: 1,
        borderColor: '#9F7AEA',
        color: '#9F7AEA',
        borderRadius: 16,
        paddingHorizontal: 12,
        paddingVertical: 4,
        fontSize: 12,
        justifyContent: 'center',
        alignItems: 'center'
    },
    notcompleted: {
        borderWidth: 1,
        borderColor: '#ef5d9b',
        color: '#ef5d9b',
        borderRadius: 16,
        paddingHorizontal: 12,
        paddingVertical: 4,
        fontSize: 12,
        justifyContent: 'center',
        alignItems: 'center'
    },
    green: {
        borderWidth: 1,
        borderColor: '#09b2c2',
        color: '#09b2c2',
        borderRadius: 16,
        paddingHorizontal: 12,
        paddingVertical: 4,
        fontSize: 12,
        justifyContent: 'center',
        alignItems: 'center'

    },
    uploadButton: {
        backgroundColor: '#38B2AC',
        borderRadius: 8,
        paddingHorizontal: 12,
        paddingVertical: 4,
        justifyContent: 'center',
        alignItems: 'center'
    },
    nudgeButton: {
        backgroundColor: '#9F7AEA',
        borderRadius: 8,
        paddingHorizontal: 12,
        paddingVertical: 4,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        color: '#fff',
        fontSize: 12,
    },
    documentBox: {
        padding: 12,
        borderWidth: 2,
        borderColor: '#E2E8F0',
        borderRadius: 8,
        marginTop: 20,
        flexDirection: "row",
        justifyContent: "space-between",
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 5,
        
    },

    documentItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 12,
        borderWidth: 2,
        borderColor: '#E2E8F0',
        borderRadius: 8,
        marginTop: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 5,
    },
    documentActions: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    date: {
        color: '#718096',
    },
    viewIcon: {
        color: '#38B2AC',
    },
    deleteIcon: {
        color: '#E53E3E',
    },
    bold: {
        fontWeight: 'bold',
    },
});

export default IdBox;
