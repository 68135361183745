import store from "../../../redux/store";

export function authHeader() {
    // return authorization header with jwt token
    const storeToken= store.getState().AppReducer.token
console.log("storeToken",storeToken)
    if (storeToken) {
        return { 'Authorization': 'Bearer ' + storeToken };
    } else {
        return {};
    }
}