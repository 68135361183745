import { Modal } from '@material-ui/core';
import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';

const { width } = Dimensions.get('window');

const AffPopups = (props) => {
    const {
        onClose,
        showModal,
        data
      } = props;
      console.log("datadata",data)
  return (
    <Modal
      open={showModal ? showModal : false}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
    <View style={styles.container}>
      <View style={styles.popup}>
        <Text style={styles.title}>Pre Qualification</Text>
        <Text style={styles.subtitle}>
          Below are the "SELF CERTIFIED" details of this tenant.
        </Text>

        <View style={styles.infoBox}>
          <Text style={styles.infoText}>
            1 Affordability criteria is determined by each individual agent. From the information provided by the tenant and the calculations from FCC Paragon, we believe this amount would be the tenant's rental affordability.
          </Text>
        </View>

        <Text style={styles.detail}><Text style={styles.bold}>Self Certified Yearly Gross Income:</Text> £ {data?.salary ? data?.salary.toFixed(2) :"-"}</Text>
        <Text style={styles.detail}><Text style={styles.bold}>Affordability:</Text> £ {data?.affordbility_amount ? data?.affordbility_amount.toFixed(2) :"-"}</Text>

        <TouchableOpacity onPress={onClose} style={styles.okButton}>
          <Text style={styles.okButtonText}>OK</Text>
        </TouchableOpacity>
      </View>
    </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  popup: {
    width:"40%",
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    elevation: 5,
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 16,
    color: '#666',
    marginBottom: 20,
  },
  infoBox: {
    backgroundColor: '#E0F7FA',
    padding: 15,
    borderRadius: 8,
    marginBottom: 20,
  },
  infoText: {
    fontSize: 14,
    color: '#00796B',
  },
  detail: {
    fontSize: 16,
    marginBottom: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  okButton: {
    backgroundColor: '#9C27B0',
    paddingVertical: 12,
    borderRadius: 8,
    alignItems: 'center',
    marginTop: 20,
  },
  okButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export default AffPopups;
