import { Alert } from "react-native";

export const handleApiResponse = {
    handleResponse: (response) => {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 403) {
                    Alert.alert('Unauthrised')
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            return data;
        });
    },
    handleLogout: () => {
        Alert.alert('Replace auth token')
    }
}