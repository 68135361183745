import React, { useEffect, useState } from 'react';
import { View, Text, Image, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import CertList from './certlist';
import DeletePopUp from '../modals/deletePopUp';
import { tenantActions } from '../../../redux/tenant.actions';
import { showSuccesToast } from '../../helpers';
import { navigate } from '../../../../../navigation/RootNavigation';

import getPlatformType from '../../../../../helpers/helpers';
import { Button } from '../../atoms/button';

const layoutType = getPlatformType();

const CertificatesScreen = (props) => {
    const certificateRequest = useSelector((state) => state?.tenant?.certificateRequest);
    const globalState = useSelector((state) => state?.tenant?.flowIdInfo);
    const [selectedCertificate, setSelectedCertificate] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [gasCertificates, setGasCertificates] = useState([])
    const [epcCertificates, setEpcCertificates] = useState([])
    const [patCertificates, setPatCertificates] = useState([])
    const [eicrCertificates, setEicrCertificates] = useState([])
    const [gasCertificatesRequests, setGasCertificatesRequests] = useState([])
    const [epcCertificatesRequests, setEpcCertificatesRequests] = useState([])
    const [patCertificatesRequests, setPatCertificatesRequests] = useState([])
    const [licenseCertificates, setLicenseCertificates] = useState([])
    const [eicrCertificatesRequests, setEicrCertificatesRequests] = useState([])
    const [certificates, setCertificates] = useState([])
    useEffect(() => {
        if (certificateRequest) {
            const gasCertificates1 = certificateRequest.filter((certificate) => certificate.certificate_type == 'gas')
            const epcCertificates1 = certificateRequest.filter((certificate) => certificate.certificate_type == 'epc')
            const patCertificates1 = certificateRequest.filter((certificate) => certificate.certificate_type == 'pat')
            const eicrCertificates1 = certificateRequest.filter((certificate) => certificate.certificate_type == 'eicr')

            setGasCertificatesRequests(gasCertificates1)
            setEpcCertificatesRequests(epcCertificates1)
            setPatCertificatesRequests(patCertificates1)
            setEicrCertificatesRequests(eicrCertificates1)
        }
    }, [certificateRequest])


    const getLicenceSubHeading = () => {
        return (
            <>
                <Text >
                    Does your property require a licence? The main types of licence are:
                </Text>
                <Text >
                    - Mandatory Licence - 5 or more people forming two or more households.
                </Text>{' '}
                <br />
                <Text >
                    - Additional Licence - Requirement conditions will be dictated by the local authority.
                </Text>{' '}
                <br />
                <Text >
                    - Selective Licence - A geographic area agreed by the local authority.
                </Text>
            </>
        )
    }

    useEffect(() => {
        if (globalState) {
            if (globalState?.open_flow_certificates) {
                const gasCertificates1 = globalState?.open_flow_certificates.filter(
                    (certificate) => certificate.certificate_type == 'certificates_gas'
                )
                const epcCertificates1 = globalState?.open_flow_certificates.filter(
                    (certificate) => certificate.certificate_type == 'certificates_epc'
                )
                const patCertificates1 = globalState?.open_flow_certificates.filter(
                    (certificate) => certificate.certificate_type == 'certificates_pat'
                )
                const eicrCertificates1 = globalState?.open_flow_certificates.filter(
                    (certificate) => certificate.certificate_type == 'certificates_eicr'
                )
                const licenseCertificates1 = globalState?.open_flow_certificates.filter(
                    (certificate) => certificate.certificate_type == 'licensing'
                )

                setGasCertificates(gasCertificates1)
                setEpcCertificates(epcCertificates1)
                setPatCertificates(patCertificates1)
                setEicrCertificates(eicrCertificates1)
                setLicenseCertificates(licenseCertificates1)
                setCertificates(globalState.open_flow_certificates)
            }
        }
    }, [globalState?.open_flow_certificates])
    const certificatesTypes = [
        {
            image: require('./assets/blue-gas-flames.png'),
            name: 'Gas Safety Certificate (GSC)',
            subHeading: 'If the property has a Gas supply, it is a legal requirement to ensure that all gas appliances are checked for safety.',
            toolTip: 'Failure to provide a valid certificate could result in fines or imprisonment.',
            status: 'Completed',
            documents: ['Document 1', 'Document 2'],
            requests: gasCertificatesRequests,
            certificates: gasCertificates,
            certificate_type: 'certificates_gas',
            pageType: "gas_certificate",


        },
        {
            certificate_type: 'certificates_epc',
            certificates: epcCertificates,
            requests: epcCertificatesRequests,
            image: require('./assets/epc.png'),
            name: 'Energy Performance Certificate (EPC)',
            subHeading: 'EPC must be rated E or better or you must hold an exemption.',
            toolTip: 'Failure to provide a valid certificate could result in failure to gain possession.',
            status: 'Completed',
            documents: ['Document A'],
            pageType: "epc_certificate",
        },
        {
            certificates: eicrCertificates,
            requests: eicrCertificatesRequests,
            certificate_type: 'certificates_eicr',

            image: require('./assets/eicr.png'),
            name: 'Electrical Installation Condition Report (EICR)',
            subHeading: 'Ensure the property complies with electrical safety regulations.',
            toolTip: 'Check your EICR for Code 1, Code 2, and FI, all of which must be addressed within 28 days.',
            status: 'Completed',
            documents: [],
            pageType: "",
        },
        {
            certificates: patCertificates,
            requests: patCertificatesRequests,
            certificate_type: 'certificates_pat',
            image: require('./assets/pat.png'),
            name: 'Portable Appliance Testing (PAT)',
            subHeading: 'Examines electrical appliances to ensure they are safe to use.',
            toolTip: 'PAT testing is not required for landlords but is recommended for safety.',
            status: 'Pending',
            documents: ['Report X', 'Report Y', 'Report Z'],
            pageType: "pat",
        },
        {
            certificates: licenseCertificates,
            certificate_type: 'licensing',
            requests: [],
            image: './assets/licence.png',
            name: 'Licence',
            hideBuy: true,
            subHeading: getLicenceSubHeading(),
            toolTip: 'Why not visit the website of the local authority and check if you need a licence.',
            toolTip1: 'Failing to licence your property can incur significant fines.'
        }
    ];

    const CertificateCard = ({ certificate }) => {
        const handleBuy = (certificateType) => {

        }
        const handleDelete = (certificatesItem) => {
            setSelectedCertificate(certificatesItem)
            setShowDeleteModal(true)
        }
        console.log("certificate", certificate)
        const getLabel = (data22) => {
            if (data22?.length > 0) {
                return certificate.status
            } else if (data22.length == 0) {
                return 'Not Completed'
            } else {
                return 'Completed'
            }
        }

        const handleUpload = (certificate_type, certificate_url) => {
            // This loop is to make sure that we only have one certificate per type
            const certificatesToSave = certificates.map((certificate) => {
                if (certificate.certificate_type == certificate_type) {
                    return { ...certificate, remove_from_flow: true }
                } else {
                    return certificate
                }
            })
            console.log("certificate_type", certificate)
            certificatesToSave.push({ certificate_type, certificate_url })
            saveCertificates1(certificatesToSave)
        }
        return (
            <View style={styles.card}>
                <Image source={certificate.image} style={styles.image} />
                <Text style={styles.name}>{certificate.name}</Text>
                <Text style={styles.subHeading}>{certificate.subHeading}</Text>
                <Text style={styles.toolTip}>{certificate.toolTip}</Text>

                <View style={styles.statusContainer}>
                    <Text style={getLabel(certificate.certificates) == 'Completed' ? styles.completed : styles.pending}>

                        {getLabel(certificate.certificates)}
                    </Text>
                </View>
                <div
                    style={{
                        display: layoutType == 'phone' ? 'block' : 'flex',
                        marginTop: '15px',
                    }}
                >
                    <Button
                        type="Upload"
                        title="Upload"
                        style={{ marginBottom: 10 ,marginRight:20}}
                        onClick={(data) => {
                            console.log("datadata", data)
                            if (data && data?.data?.length) {
                                handleUpload(certificate?.certificate_type, data?.data[0])
                            }
                        }}
                    />
                     {certificate?.hideBuy ? null :
                    certificate?.certificates?.length > 0 ? null :
                        <Button
                            type="solid"
                            onClick={() => {
                                navigate('CommonBuyCertificate', {
                                    typee: { title: certificate?.subHeading },
                                    status: true,
                                    pageType: certificate?.pageType,
                                    property_id: "122"
                                });

                            }}
                            title="BUY"
                            color={"#006fcf"}
                        />}
                </div>

               
                {certificate?.certificates?.length > 0 &&
                    certificate.certificates &&
                    certificate.certificates.map((certificatesItem, index) => {
                        return (
                            <CertList
                                key={index}
                                handleBuy={handleBuy}
                                handleDelete={() => handleDelete(certificatesItem)}
                                // handleUpload={handleUpload}
                                certificate={certificate}
                                certificatesItem={certificatesItem}
                            />
                        )
                    })}


{/* 
                <View style={styles.actionsContainer}>
                    <TouchableOpacity style={styles.editButton}><Text style={styles.buttonText}>Edit</Text></TouchableOpacity>
                    <TouchableOpacity style={styles.seenButton}><Text style={styles.buttonText}>Seen</Text></TouchableOpacity>
                    <TouchableOpacity style={styles.deleteButton}><Text style={styles.buttonText}>Delete</Text></TouchableOpacity>
                </View> */}
            </View>
        );
    };
    const deleteCertificate = () => {
        console.log("selectedCertificate", selectedCertificate)
        const certificatesToSave = certificates.map((certificate) => {
            if (certificate?.certificate_id == selectedCertificate.certificate_id) {
                return { ...certificate, remove_from_flow: true }
            } else {
                return certificate
            }
        })
        saveCertificates1(certificatesToSave)
    }
    const dispatch = useDispatch()
    const fetchFlows = () => {
        dispatch(tenantActions.getFlowInfo(props?.flowId));
    }
    const saveCertificates1 = (certificates1) => {
        let obj = {
            certificates: certificates1,
            send_to_tenant: false,
            flow_id: props.flowId,
        };
        dispatch(tenantActions.saveCer(obj, props.flowId, (data) => {
            showSuccesToast('Cert deleted Successfully!')
            fetchFlows()
        }), true);
        setShowDeleteModal(false)
    }
    return (
        <ScrollView contentContainerStyle={styles.container}>
            {certificatesTypes.map((certificate, index) => (
                <CertificateCard key={index} certificate={certificate} />
            ))}
            <DeletePopUp
                showModal={showDeleteModal}
                onClose={() => {
                    setShowDeleteModal(false)
                }}
                onSubmit={() => {
                    deleteCertificate()
                }}
            />
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    card: {
        width: '48%',
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: 15,
        marginBottom: 20,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 4,
        elevation: 5
    },
    image: {
        width: '100%',
        height: 100,
        borderRadius: 10,
        marginBottom: 10
    },
    name: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 8
    },
    subHeading: {
        fontSize: 14,
        color: '#555',
        marginBottom: 5
    },
    toolTip: {
        fontSize: 12,
        color: '#888',
        marginBottom: 10
    },
    statusContainer: {
        marginVertical: 10
    },
    completed: {
        color: '#4CAF50',
        fontWeight: 'bold'
    },
    pending: {
        color: '#FF9800',
        fontWeight: 'bold'
    },
    documentsContainer: {
        marginTop: 10,
        paddingBottom: 10
    },
    documentsTitle: {
        fontWeight: 'bold',
        fontSize: 14,
        marginBottom: 5
    },
    document: {
        fontSize: 12,
        color: '#333',
        marginBottom: 3
    },
    actionsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 15
    },
    editButton: {
        backgroundColor: '#2196F3',
        padding: 10,
        borderRadius: 5,
        width: '30%',
        alignItems: 'center'
    },
    seenButton: {
        backgroundColor: '#4CAF50',
        padding: 10,
        borderRadius: 5,
        width: '30%',
        alignItems: 'center'
    },
    deleteButton: {
        backgroundColor: '#F44336',
        padding: 10,
        borderRadius: 5,
        width: '30%',
        alignItems: 'center'
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold'
    }
});

export default CertificatesScreen;
