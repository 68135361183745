import React, { useEffect, useState } from "react";
import { View, Text, TextInput, FlatList, TouchableOpacity, StyleSheet } from "react-native";
import Feather from '@expo/vector-icons/Feather';
import ajax from "../../../../../helpers/ajaxHelper";
import Urls from "../../../../../services/Urls";
import { useIsFocused } from "@react-navigation/native";
import moment from 'moment';
import { navigate } from "../../../../../navigation/RootNavigation";
import { showSuccesToast } from "../../helpers";
const PropertiesTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);


  // const properties = [
  //   { id: "1", address: "15, CRICK COURT, SPRING PLACE", postcode: "IG11 7GN", uploaded: "17/10/2024" },
  //   { id: "2", address: "FLAT 45, LEYLAND HOUSE, HALE S...", postcode: "E14 0BT", uploaded: "11/09/2024" },
  //   { id: "3", address: "FLAT 40, LEYLAND HOUSE, HALE S...", postcode: "E14 0BT", uploaded: "05/09/2024" },
  //   { id: "4", address: "268A, LEWISHAM HIGH STREET, HI...", postcode: "SE13 6AD", uploaded: "18/07/2024" },
  //   { id: "3", address: "FLAT 40, LEYLAND HOUSE, HALE S...", postcode: "E14 0BT", uploaded: "05/09/2024" },
  //   { id: "4", address: "268A, LEWISHAM HIGH STREET, HI...", postcode: "SE13 6AD", uploaded: "18/07/2024" },    { id: "3", address: "FLAT 40, LEYLAND HOUSE, HALE S...", postcode: "E14 0BT", uploaded: "05/09/2024" },
  //   { id: "4", address: "268A, LEWISHAM HIGH STREET, HI...", postcode: "SE13 6AD", uploaded: "18/07/2024" },    { id: "3", address: "FLAT 40, LEYLAND HOUSE, HALE S...", postcode: "E14 0BT", uploaded: "05/09/2024" },
  //   { id: "4", address: "268A, LEWISHAM HIGH STREET, HI...", postcode: "SE13 6AD", uploaded: "18/07/2024" },    { id: "3", address: "FLAT 40, LEYLAND HOUSE, HALE S...", postcode: "E14 0BT", uploaded: "05/09/2024" },
  //   { id: "4", address: "268A, LEWISHAM HIGH STREET, HI...", postcode: "SE13 6AD", uploaded: "18/07/2024" },    { id: "3", address: "FLAT 40, LEYLAND HOUSE, HALE S...", postcode: "E14 0BT", uploaded: "05/09/2024" },
  //   { id: "4", address: "268A, LEWISHAM HIGH STREET, HI...", postcode: "SE13 6AD", uploaded: "18/07/2024" },    { id: "3", address: "FLAT 40, LEYLAND HOUSE, HALE S...", postcode: "E14 0BT", uploaded: "05/09/2024" },
  //   { id: "4", address: "268A, LEWISHAM HIGH STREET, HI...", postcode: "SE13 6AD", uploaded: "18/07/2024" },    { id: "3", address: "FLAT 40, LEYLAND HOUSE, HALE S...", postcode: "E14 0BT", uploaded: "05/09/2024" },
  //   { id: "4", address: "268A, LEWISHAM HIGH STREET, HI...", postcode: "SE13 6AD", uploaded: "18/07/2024" },
  // ];
  const [properties, setProperties] = useState([]);
  const focused = useIsFocused();

  useEffect(() => {
    ajax
      .get(Urls.Properties)
      .then(({ data }) => {
        console.log("propertiesproperties",data)
        if (data.status) {
          setProperties(data?.payload);
        } else {
        
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [focused]);

 const startFlow =(text)=>{
  ajax
  .post(Urls.START_FLOW, { property_id: selectedItem })
  .then(({ data }) => {
    console.log('Data',data)
    if (data.status) {
      showSuccesToast("New flow Created successfully!")
      if(data?.data?.openflow_id){
        navigate('TeanantOnboarding', { id: data?.data?.openflow_id});

      }
console.log("startFlow",data)
    } else {
      // alert(data.message);
    }
  })
  .catch((error) => {
    console.log('error', error);
  });
}
  const filteredProperties = properties?.filter((item) =>
    item?.name?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

   console.log("selectedItem",selectedItem)
  const toggleSelection = (id) => {
 


    setSelectedItem(id);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Properties</Text>
      <Text style={styles.subtitle}>Please search and select the property the tenant(s) will move into.</Text>
     
     <View style={{flexDirection:'row',marginBottom:10,justifyContent:'space-between'}}> <TextInput
        style={styles.searchInput}
        placeholder="Search"
        value={searchQuery}
        onChangeText={setSearchQuery}
      />
      <TouchableOpacity 
        style={[styles.button, { opacity: selectedItem ? 1 : 0.5 }]} 
        onPress={() => startFlow()}
        disabled={!selectedItem}
      >
        <Text style={styles.buttonText}>+ START NEW FLO</Text>
      </TouchableOpacity>
      </View>

     {filteredProperties&&filteredProperties.length?
      <FlatList
        data={filteredProperties}
        keyExtractor={(item) => item?.hubita_id}
        renderItem={({ item }) => {
          console.log("item",item)
          return(
          <TouchableOpacity 
            style={[styles.row, selectedItem == item.id && styles.selectedRow]} 
            onPress={() => toggleSelection(item.id)}
          >
            <View style={[styles.checkbox, selectedItem === item.id && styles.selectedCheckbox]}>
              {selectedItem ==item.id && <Feather name="check" size={14} color="black" />}
            </View>
            <Text style={styles.cell}>{item.name}</Text>
            <Text style={styles.cell}>{item.postcode}</Text>
            <Text style={styles.cell}>{
              moment(item.updatedAt).format('YYYY-MM-DD')
              }</Text>
          </TouchableOpacity>
        )}}
      />:null}
 {/* <View style={styles.paginationContainer}>
        <Text style={styles.paginationText}>Rows per page:</Text>
        <Text style={styles.paginationDropdown}>{itemsPerPage}</Text>
        <Text style={styles.paginationText}>{`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage, filteredProperties.length)} of ${filteredProperties.length}`}</Text>
        <TouchableOpacity disabled={currentPage === 1} onPress={() => setCurrentPage(currentPage - 1)}>
          <Text style={[styles.pageButton, currentPage === 1 && styles.disabledButton]}>&lt;</Text>
        </TouchableOpacity>
        <TouchableOpacity disabled={currentPage * itemsPerPage >= filteredProperties.length} onPress={() => setCurrentPage(currentPage + 1)}>
          <Text style={[styles.pageButton, currentPage * itemsPerPage >= filteredProperties.length && styles.disabledButton]}>&gt;</Text>
        </TouchableOpacity>
      </View> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: { padding: 16, backgroundColor: "#fff", borderRadius: 10, elevation: 5, shadowColor: "#000", shadowOpacity: 0.1, shadowRadius: 4, shadowOffset: { width: 0, height: 2 },margin:20 },
  title: { fontSize: 20, fontWeight: "bold", color: "#7D57C1" , marginBottom: 10 },
  subtitle: { fontSize: 14, color: "gray", marginBottom: 10 },
  searchInput: { borderWidth: 1, padding: 8, borderRadius: 5,  borderColor: "#ccc" ,flex:0.5},
  button: { backgroundColor: "#7D57C1", padding: 10, borderRadius: 5, alignItems: "center",flex:0.4 },
  buttonText: { color: "white", fontWeight: "bold" },
  row: { flexDirection: "row", alignItems: "center", padding: 10, borderBottomWidth: 1, borderColor: "#ddd", borderRadius: 5, backgroundColor: "#fff" },
  selectedRow: { backgroundColor: "#EEE4FF" },
  checkbox: { width: 20, height: 20, borderWidth: 1, alignItems: "center", justifyContent: "center", marginRight: 10, borderColor: "#7D57C1", borderRadius: 4 },
  selectedCheckbox: { backgroundColor: "#7D57C1" },
  cell: { flex: 1, paddingLeft: 10 },
  paginationContainer: { flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 10 },
  paginationText: { fontSize: 14, color: "gray" },
  paginationDropdown: { fontSize: 14, fontWeight: "bold", color: "#7D57C1", marginHorizontal: 5 },
  pageButton: { padding: 10, fontSize: 16, color: "#7D57C1" },
  disabledButton: { color: "gray" },
});

export default PropertiesTable;