import React, { useEffect, useState } from 'react';
import { BackBox } from './styles';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch } from 'react-redux';
// import { tenantActions } from '../../../../_actions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import variables from '../variables.json';

const ToggleComplete = (props) => {
  const dispatch = useDispatch();
  const { isComplete, flowtype, flowID } = props;
  const [complete, setComplete] = useState(isComplete);

  useEffect(() => {
    setComplete(isComplete);
  }, [isComplete]);

  const handleChange = (event) => {
    setComplete(!complete);
    if (!complete) {
      dispatch(tenantActions.toggleComplete('/openflow/' + flowtype + '/mark-as-complete?flow_id=' + flowID));
    } else {
      dispatch(tenantActions.toggleComplete('/openflow/' + flowtype + '/mark-as-incomplete?flow_id=' + flowID));
    }
  };

  return (
    <FormControl component="fieldset">
      <FormControlLabel
        control={
          <Checkbox
            checked={complete}
            style={{ color: variables.heliotrope }}
            onChange={handleChange}
            name="isComplete"
            size={'small'}
          />
        }
        label="Mark as complete"
        style={{ color: '#000', fontSize: 8, justifyContent: 'flex-end' }}
      />
    </FormControl>
  );
};
export default ToggleComplete;
