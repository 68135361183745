import { tenantConstants } from "./tenant.constants";
export function tenant(
  state = {
    tenantLoading: false,
    rentalOffersData: [],
    addRentalData: null,
    moveInData: [],
    flow: [],
    archivedFlows: [],
    tenancyInfo: [],
    verificationRefData: [],
    selectedProperty: null,
    holdingDepositeData: null,
    branchBankAccounts: [],
    companyBranches: [],
    flowIdInfo:[],
    ccData:[],
    fccBranchCodeSuccess: false,
    showPropertyPopup:null,
    certificateRequest:[]
  },
  action
) {
  switch (action.type) {
    case tenantConstants.GET_TEANANTS_DOC_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
      case tenantConstants.SHOW_LOADER:
        return {
          ...state,
          tenantLoading: true,
        };
        case tenantConstants.HIDE_LOADER:
          return {
            ...state,
            tenantLoading: false,
          };
    case tenantConstants.GET_TEANANTS_DOC_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        tenantDocs: action.data,
      };
    case tenantConstants.GET_TEANANTS_DOC_FAIL:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.GET_TEANANTS_DETAILS_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_TEANANTS_DETAILS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        tenantDetails: action.data,
      };
    case tenantConstants.GET_TEANANTS_DETAILS_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
      case tenantConstants.UPLOAD_AST_REQUEST:
        return {
          ...state,
          tenantLoading: true,
        };
      case tenantConstants.UPLOAD_AST_SUCCESS:
        return {
          ...state,
          tenantLoading: false,
          uploadManualAST: action.data,
        };
      case tenantConstants.UPLOAD_AST_FAILURE:
        return {
          ...state,
          tenantLoading: false,
        };

        case tenantConstants.REMOVE_AST_REQUEST:
          return {
            ...state,
            tenantLoading: true,
          };
        case tenantConstants.REMOVE_AST_SUCCESS:
          return {
            ...state,
            tenantLoading: false,
            uploadManualAST: action.data,
          };
        case tenantConstants.REMOVE_AST_FAILURE:
          return {
            ...state,
            tenantLoading: false,
          };

    case tenantConstants.COMMON_CLAUSES:
      return {
        ...state,
        ccLoading: true,
      };
    case tenantConstants.COMMON_CLAUSES_SUCCESS:
      return {
        ...state,
        ccLoading: false,
        ccData: action.data,
      };
    case tenantConstants.COMMON_CLAUSES_FAILURE:
      return {
        ...state,
        ccLoading: false,
      };
    case tenantConstants.GET_TEANANTS_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_TEANANTS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        flow: action.data,
      };
    case tenantConstants.GET_ARCHIVED_FLOWS_REQUEST:
      return {
        ...state,
        archivedFlows: [],
        tenantLoading: true,
      };
    case tenantConstants.DELETE_FLOW_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.DELETE_FLOW_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.ARCHIEVE_FLOW_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.ADD_FLOW_TO_PROPERTY_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.ADD_FLOW_TO_PROPERTY_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.UNARCHIEVE_FLOW_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_ARCHIVED_FLOWS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        archivedFlows: action.data,
      };
    case tenantConstants.GET_TEANANTS_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_FLOWINFO_REQUEST:
      return {
        ...state,
        tenantLoading: true,

      };
    case tenantConstants.GET_FLOWINFO_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        flowIdInfo: action.data,
      };
    case tenantConstants.GET_FLOWINFO_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_FLOWINFO_RESET:
      return {
        ...state,
        flowIdInfo: [],
      };
    case tenantConstants.SAVE_TEANANTS_PROPERTY_REQUEST:
      return {
        state: null,
        selectedProperty: action.data,
        tenantLoading: true,
      };

    case tenantConstants.SAVE_TEANANTS_PROPERTY_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.SAVE_TEANANTS_PROPERTY_SUCCESS:
      return {
        ...state,
        selectedProperty: action.data,
        tenantLoading: false,
      };

    case tenantConstants.GET_RENTAL_OFFERS_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_RENTAL_OFFERS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        rentalOffersData: action.data,
      };
    case tenantConstants.GET_RENTAL_OFFERS_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.ADD_RENTAL_OFFERS_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.ADD_RENTAL_OFFERS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        addRentalData: action.data,
      };
    case tenantConstants.ADD_RENTAL_OFFERS_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_MOVEIN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_MOVEIN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        moveInData: action.data,
      };
    case tenantConstants.GET_MOVEIN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.ADD_MOVEIN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.ADD_MOVEIN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        addMoveInData: action.data,
      };
    case tenantConstants.ADD_MOVEIN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.ARRANGE_CHECKIN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.ARRANGE_CHECKIN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        arrangeCheckInData: action.data,
      };
    case tenantConstants.ARRANGE_CHECKIN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_TENANCY_INFO_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_TENANCY_INFO_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        tenancyInfo: action.data,
      };
    case tenantConstants.GET_TENANCY_INFO_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_NHA_REQUEST:
      return {
        ...state,
        tenantAstLoading: true,
      };
    case tenantConstants.GET_NHA_SUCCESS:
      return {
        ...state,
        tenantAstLoading: false,
        nhaData: action.data,
      };
    case tenantConstants.GET_NHA_FAILURE:
      return {
        ...state,
        tenantAstLoading: false,
      };
    case tenantConstants.GET_AST_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_AST_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        astData: action.data,
      };
    case tenantConstants.GET_AST_FAILURE:
      return {
        ...state,
        tenantLoading:false
      };
    case tenantConstants.ADD_TENANCY_INFO_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.ADD_TENANCY_INFO_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        addTenancyInfoData: action.data,
      };
    case tenantConstants.ADD_TENANCY_INFO_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_VERIFICATION_REF_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_VERIFICATION_REF_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        verificationRefData: action.data,
      };
    case tenantConstants.GET_VERIFICATION_REF_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.ADD_TEANANTS_DETAILS_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.ADD_TEANANTS_DETAILS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.REQUEST_RIGHT_TO_RENT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.ADD_TEANANTS_DETAILS_FAIL:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_TOGGLE_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_TOGGLE_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_TOGGLE_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_HOLDING_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SIGN_INTO_FCC_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SIGN_INTO_FCC_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        fccSignInResponse: action.data,
      };
    case tenantConstants.SIGN_INTO_DD_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SIGN_INTO_DD_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        ddSignInResponse: action.data,
      };
    case tenantConstants.SIGN_INTO_DD_FAILURE:
      return {
        ...state,
        tenantLoading: false,
        ddSignInResponse: action.error,
      };
    case tenantConstants.BUY_RENTAL_WARRANTY_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.BUY_RENTAL_WARRANTY_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        buyRentalWarrantySuccess: true
      };
    case tenantConstants.BUY_RENTAL_WARRANTY_REQUEST:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.CHECK_RENTAL_WARRANTY_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.CHECK_RENTAL_WARRANTY_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.CHECK_RENTAL_WARRANTY_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.BUY_OPENFLOW_PACKAGE_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.BUY_OPENFLOW_PACKAGE_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        openflowBuyPackageResponse: action.data,
      };
    case tenantConstants.BUY_OPENFLOW_PACKAGE_FAILURE:
      return {
        ...state,
        tenantLoading: false,
        openflowBuyPackageResponse: action.message,
      };
    case tenantConstants.GET_OPENFLOW_SUMMARY_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_OPENFLOW_SUMMARY_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        openflowDashboardData: action.data,
      };
    case tenantConstants.SIGN_INTO_FCC_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_FLOW_PACKAGES_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_FLOW_PACKAGES_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        packages: action.data
      };
    case tenantConstants.SIGN_INTO_OPENFLOW_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SIGN_INTO_OPENFLOW_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        openflow_signup_response: action.data,
      };
    case tenantConstants.GET_HOLDING_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        holdingDepositeData: action.data,
      };
    case tenantConstants.GET_HOLDING_FAILURE:
      return {
        ...state,
        tenantLoading: false,
        holdingDepositeData: null,
      };
    case tenantConstants.GET_BRANCH_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        branchBankAccounts: action.data,
      };
    case tenantConstants.GET_BRANCH_BANK_ACCOUNTS_FAILURE:
      return {
        ...state,
        tenantLoading: false,
        branchBankAccounts: [],
      };
    case tenantConstants.GET_COMPANY_BRANCHES_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        companyBranches: action.data.data,
      };
    case tenantConstants.GET_COMPANY_BRANCHES_FAILURE:
      return {
        ...state,
        tenantLoading: false,
        companyBranches: [],
      };
    case tenantConstants.SAVE_BRANCH_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SAVE_BRANCH_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.REVOKE_CONFIRMATION_HOLDING_DEPOSIT_SUCCESS:
        return {
          ...state,
          tenantLoading: false,
        };
    case tenantConstants.REVOKE_CONFIRMATION_HOLDING_DEPOSIT_FAILURE:
        return {
          ...state,
          tenantLoading: false,
        };
    case tenantConstants.UPLOAD_INVENTORY_DOCUMENTS_SUCCESS:
        return {
          ...state,
          tenantLoading: false,
        };
    case tenantConstants.UPLOAD_INVENTORY_DOCUMENTS_FAILURE:
        return {
          ...state,
          tenantLoading: false,
        };
    case tenantConstants.GET_TOGGLE_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.SAVE_HOLDING_DEPOSIT_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SAVE_HOLDING_DEPOSIT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SAVE_HOLDING_DEPOSIT_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.REQUEST_HOLDING_DEPOSIT_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.REQUEST_HOLDING_DEPOSIT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.REQUEST_HOLDING_DEPOSIT_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.CONFIRM_HOLDING_DEPOSIT_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.CONFIRM_HOLDING_DEPOSIT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.CONFIRM_HOLDING_DEPOSIT_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.DEL_CER_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.DEL_CER_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.DEL_CER_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.SAVE_CER_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SAVE_CER_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SAVE_CER_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.BUY_CER_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.BUY_CER_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        certificateRequest:action.data
      };
    case tenantConstants.DOWNLOAD_TENANCY_DOCUMENT_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.DOWNLOAD_TENANCY_DOCUMENT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.BUY_CER_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.EMAIL_CONTRACT_SUCCESS:
      return {
        ...state,
        sendContractLoading: false,
      };
    case tenantConstants.EMAIL_CONTRACT_REQUEST:
      return {
        ...state,
        sendContractLoading: true,
      };
    case tenantConstants.EMAIL_CONTRACT_FAILURE:
        return {
          ...state,
          sendContractLoading: false,
        };
    case tenantConstants.GET_IDEN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.DOWNLOAD_SIGNED_CONTRACT_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.DOWNLOAD_SIGNED_CONTRACT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.DOWNLOAD_SIGNED_CONTRACT_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_IDEN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
        idendityData: action.data,
      };
    case tenantConstants.GET_IDEN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.SAVE_IDEN_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SAVE_IDEN_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SAVE_IDEN_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

      case tenantConstants.GET_TDS_REQUEST:
        return {
          ...state,
          tenantLoading: true,
        };
      case tenantConstants.GET_TDS_SUCCESS:
        return {
          ...state,
          tenantLoading: false,
          tdsData: action.data
        };
      case tenantConstants.GET_TDS_FAILURE:
        return {
          ...state,
          tenantLoading: false,
        };

      case tenantConstants.SAVE_MOVEIN_REQUEST:
        return {
          ...state,
          tenantLoading: true,
        };
      case tenantConstants.SAVE_MOVEIN_SUCCESS:
        return {
          ...state,
          tenantLoading: false,
        };
      case tenantConstants.SAVE_MOVEIN_FAILURE:
        return {
          ...state,
          tenantLoading: false,
        };

        case tenantConstants.SAVE_CHECKIN_REQUEST:
          return {
            ...state,
            tenantLoading: true,
          };
        case tenantConstants.SAVE_CHECKIN_SUCCESS:
          return {
            ...state,
            tenantLoading: false,
          };
        case tenantConstants.SAVE_CHECKIN_FAILURE:
          return {
            ...state,
            tenantLoading: false,
          };

case tenantConstants.SAVE_PROTECT_REQUEST:
return {
...state,
tenantLoading: true,
};
case tenantConstants.SAVE_PROTECT_SUCCESS:
return {
...state,
tenantLoading: false,
};
case tenantConstants.SAVE_PROTECT_FAILURE:
return {
...state,
tenantLoading: false,
};

case tenantConstants.GET_PROTECT_REQUEST:
return {
...state,
tenantLoading: true,
};
      case tenantConstants.GET_PROTECT_SUCCESS:
          let can_upload_cert = true
          try {


          if (action.data.flow.open_flow_holding_deposit.holding_deposit_collection_from_lead_tenant == false) {
              for (let i = 0; i < action.data.flow.tenants.length; i++)
                  if (action.data.flow.tenants[i].deposit_payment_status != 3) {
                      can_upload_cert = false
                  }
          } else {
              let tenArr = action.data.flow.tenants
              let max = action.data.flow.tenants.length
              for (let j = 0; j < max; j++) {
                  if (tenArr[j].is_lead == true)
                      if (tenArr[j].deposit_payment_status != 3){
                          can_upload_cert = false
                      }

              }
          }
          }catch (e) {
              can_upload_cert = false
          }
          return {
...state,
tenantLoading: false,
depositProtectData: {...action.data,can_upload_cert}
};
case tenantConstants.GET_PROTECT_FAILURE:
return {
...state,
tenantLoading: false,
};

case tenantConstants.CONFIRM_MOVIN_DEPOSIT_REQUEST:
return {
...state,
tenantLoading: true,
};
case tenantConstants.CONFIRM_MOVIN_DEPOSIT_SUCCESS:
return {
...state,
tenantLoading: false,
depositProtectData: action.data
};
case tenantConstants.CONFIRM_MOVIN_DEPOSIT_FAILURE:
return {
...state,
tenantLoading: false,
};

case tenantConstants.REQUEST_MOVIN_MONIES_SUCCESS:
return {
...state,
tenantLoading: false,
depositProtectData: action.data
};
case tenantConstants.REQUEST_MOVIN_MONIES_FAILURE:
return {
...state,
tenantLoading: false,
};

case tenantConstants.CHECK_LISENCE_REQUEST:
return {
...state,
tenantLoading: true,
};

case tenantConstants.GET_FLOW_LOGS_SUCCESS:
  return {
    ...state,
    tenantLoading: false,
    logs_file: action.data.data,
  };

case tenantConstants.GET_FLOW_LOGS_FAILURE:
  return {
    ...state,
    tenantLoading: false,
  };

case tenantConstants.GET_FLOW_LOGS_REQUEST:
  return {
    ...state,
    tenantLoading: true,
  };

case tenantConstants.GET_OPENFLOW_PURCHASES_SUCCESS:
  return {
    ...state,
    tenantLoading: false,
    purchases: action.data.data,
  };

case tenantConstants.GET_OPENFLOW_INVOICES_SUCCESS:
  return {
    ...state,
    tenantLoading: false,
    invoices: action.data.data,
  };

case tenantConstants.GET_OPENFLOW_PURCHASES_FAILURE:
  return {
    ...state,
    tenantLoading: false,
  };

case tenantConstants.GET_OPENFLOW_PURCHASES_REQUEST:
  return {
    ...state,
    tenantLoading: true,
  };

case tenantConstants.UPDATE_FCC_BRANCH_CODE_REQUEST:
return {
...state,
tenantLoading: true,
fccBranchCodeSuccess: false
};

case tenantConstants.GET_FCC_BRANCH_CODE_SUCCESS:
return {
...state,
fccBranchData: action.data,
};

case tenantConstants.UPDATE_FCC_BRANCH_CODE_SUCCESS:
return {
...state,
tenantLoading: false,
fccBranchCodeSuccess: true
};

case tenantConstants.CHECK_LISENCE_SUCCESS:
return {
...state,
tenantLoading: false,
lisenceData: action.data
};
case tenantConstants.CHECK_LISENCE_RESET:
return {
...state,
tenantLoading: false,
lisenceData: null
};
case tenantConstants.CHECK_LISENCE_FAILURE:
return {
...state,
tenantLoading: false,
};

case tenantConstants.REQEST_DIGITAL_VARIFICATION_REQUEST:
return {
...state,
tenantLoading: true,
fullRefError:null
};
case tenantConstants.REQEST_DIGITAL_VARIFICATION_SUCCESS:
return {
...state,
tenantLoading: false,
};
case tenantConstants.REQEST_DIGITAL_VARIFICATION_FAILURE:
return {
...state,
tenantLoading: false,
fullRefError: action?.error
};
case tenantConstants.GET_ADD_OR_REMOVE_LANDLORD_REQUEST:
return {
...state,
tenantLoading: true,
};
case tenantConstants.GET_ADD_OR_REMOVE_LANDLORD_SUCCESS:
return {
...state,
tenantLoading: false,
landlordAddressList: action.data,
};
case tenantConstants.GET_ADD_OR_REMOVE_LANDLORD_FAILURE:
return {
...state,
tenantLoading: false,
};


case tenantConstants.GET_PROPERTYDATA_REQUEST:
return {
  ...state,
  tenantLoading: true,
};
case tenantConstants.GET_PROPERTYDATA_SUCCESS:
return {
  ...state,
  tenantLoading: false,
  propertyData: action.data,
};
case tenantConstants.GET_PROPERTYDATA_FAILURE:
return {
  ...state,
  tenantLoading: false,
  propertyData:null
};

case tenantConstants.GET_RENTALCOLL_REQUEST:
  return {
    ...state,
    tenantLoading: true,
  };
case tenantConstants.GET_RENTALCOLL_SUCCESS:
  return {
    ...state,
    tenantLoading: false,
    rentCollData: action.data,
  };
case tenantConstants.GET_RENTALCOLL_FAILURE:
  return {
    ...state,
    tenantLoading: false,
    rentCollData: null,
  };
  case tenantConstants.SAVE_RENTALCOLL_REQUEST:
    return {
      ...state,
      tenantLoading: true,
    };
  case tenantConstants.SAVE_RENTALCOLL_SUCCESS:
    return {
      ...state,
      tenantLoading: false,
      propertyData: action.data,
    };
  case tenantConstants.SAVE_RENTALCOLL_FAILURE:
    return {
      ...state,
      tenantLoading: false,
      propertyData:null
    };

    case tenantConstants.GET_OBRANCH_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.GET_OBRANCH_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.GET_OBRANCH_FAILURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.SEND_DIGITAL_CONTRACT_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SEND_DIGITAL_CONTRACT_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SEND_DIGITAL_CONTRACT_FALIURE:
      return {
        ...state,
        tenantLoading: false,
      };

    case tenantConstants.SEND_BESPOKE_REQUEST:
      return {
        ...state,
        tenantLoading: true,
      };
    case tenantConstants.SEND_BESPOKE_SUCCESS:
      return {
        ...state,
        tenantLoading: false,
      };
    case tenantConstants.SEND_BESPOKE_FALIURE:
      return {
        ...state,
        tenantLoading: false,
      };
      case tenantConstants.SAVE_SHOWPOPUP:
        return {
          ...state,
          showPopup: action.data,
        };
        case tenantConstants.SAVE_PROPERTY_SHOWPOPUP:
          return {
            ...state,
            showPropertyPopup: action.data,
          };
          case tenantConstants.LOGOUT:
            return {
            state:null,
            };
            case tenantConstants.GET_PURCHASE_REQUEST:
              return {
                ...state,
                tenantLoading: true,
              };
            case tenantConstants.GET_PURCHASE_SUCCESS:
              return {
                ...state,
                tenantLoading: false,
              };
            case tenantConstants.GET_PURCHASE_SUCCESS:
              return {
                ...state,
                tenantLoading: false,
              };
    default:
      return state;
  }
}
